import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
const token =
  Cookies.get('token') !== undefined || Cookies.get('token') !== null
    ? Cookies.get('token')
    : '';

// Backend Api
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // Add the Authorization header with the JWT token from the cookie
      if (token) {
        headers.set('Authorization', token);
      }

      return headers;
    },
  }), // base url
  reducerPath: 'adminApi',
  // tags
  tagTypes: [
    'User',
    'Producs',
    'Customers',
    'HoldGames',
    'AllGames',
    'Overview',
    'Sales',
    'Admins',
    'Performance',
    'Dashboard',
    'superDashboard',
    'DFilter',
    'transaction',
    'Allgames',
    'Cancelled',
    'Commision',
    'Referals',
    'Settings',
    'pendingWithDraw',
    'bankWR',
  ],
  // endpoints
  endpoints: (build) => ({
    getUser: build.query({
      // UPDATED
      query: (id) => `stats/user/${id}`,
      providesTags: ['User'],
    }),
    login: build.mutation({
      query: ({ username, password }) => ({
        url: 'admin/login',
        method: 'POST',
        body: { username, password },
      }),
    }),
    GetWithdrawRequests: build.query({
      // UPDATED
      query: (page) => ({
        url: `wallet/withDrawRequests/${page}`,
        method: 'GET',
      }),
      providesTags: ['pendingWithDraw'],
    }),
    GetSearchWithdrawRequests: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `wallet/searchwithDrawRequests/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['pendingWithDraw'],
    }),
    GetBankWRRequests: build.query({
      // UPDATED
      query: (page) => ({
        url: `admin/all-withdraw/${page}`,
        method: 'GET',
      }),
      providesTags: ['bankWR'],
    }),
    GetSearchBankWRRequests: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `admin/wr-search/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['bankWR'],
    }),
    getSuccessRequests: build.query({
      // UPDATED
      query: (page) => ({
        url: `result/SuccessRequests/${page}`,
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
    GetSearchSuccessRequests: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `result/searchSuccessRequests/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['Products'],
    }),

    getCustomers: build.query({
      // UPDATED
      query: (page) => ({
        url: `admin/all-users/${page}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    searchCustomers: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `admin/user-search/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['Customers'],
    }),
    getCustomersById: build.query({
      // UPDATED
      query: (id) => ({
        url: `admin/user/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    getTransactionById: build.query({
      // UPDATED
      query: (id) => ({
        url: `admin/transaction/${id}`,
        method: 'GET',
      }),
      providesTags: ['transaction'],
    }),
    getLudoNameById: build.query({
      // UPDATED
      query: (id) => ({
        url: `admin/ludo-name/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    getTransactions: build.query({
      // UPDATED
      query: (page) => ({
        url: `admin/hold-challenge/${page}`,
        method: 'GET',
      }),
      providesTags: ['HoldGames'],
    }),
    searchHoldGames: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `challenge/HoldGamesSearch/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['HoldGames'],
    }),

    getGeography: build.query({
      // UPDATED
      query: (page) => ({
        url: `admin/all-challenge/${page}`,
        method: 'GET',
      }),
      providesTags: ['AllGames'],
    }),
    getSearchGeography: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `admin/challenge-search/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
    }),
    getOverview: build.query({
      // UPDATED
      query: (page) => ({
        url: `admin/deposits/${page}`,
        method: 'GET',
      }),
      providesTags: ['Overview'],
    }),
    searchOverview: build.query({
      // UPDATED
      query: (keyword) => {
        if (keyword.trim() !== '') {
          return {
            url: `admin/searchDeposits/${encodeURIComponent(keyword)}`,
            method: 'GET',
          };
        }
      },
      providesTags: ['Overview'],
    }),
    getSales: build.query({
      // UNKNOWN
      query: () => 'sales/sales',
      providesTags: ['Sales'],
    }),
    getAdmins: build.query({
      query: () => 'management/admins',
      providesTags: ['Admins'],
    }),
    editAdmin: build.query({
      query: () => ({
        url: `management/edit`,
        method: 'POST',
        // body: {
        //   id: "stagingAdmin",
        //   role: "super_admin",
        //   permissions: [
        //     { id: "dashboard", name: "Dashboard" },
        //     { id: "total_games", name: "Total Games" },
        //     { id: "hold_games", name: "Hold Games" },
        //     { id: "users", name: "Users" },
        //     { id: "withdrawal_request", name: "Withdrawal Request" },
        //     { id: "successful_withdrawals", name: "Successful Withdrawals" },
        //     { id: "deposits", name: "DEPOSITS" },
        //     { id: "settings", name: "Settings" },
        //     { id: "admin_roles", name: "Admin Roles" },
        //   ]
        // }
      }),
      providesTags: ['Admins'],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ['Performance'],
    }),
    getDashboard: build.query({
      query: () => `admin/dashboard`,
      providesTags: ['Dashboard'],
    }),
    getSuperDashboard: build.query({
      query: ({ startDate, finishDate }) =>
        `admin/super-dashboard?startDate=${startDate}&endDate=${finishDate}`,
      providesTags: ['superDashboard'],
    }),
    getStats: build.query({
      // UNKNOWN
      query: () => 'stats/stats', // WAS -> dashboard/stats
      providesTags: ['Dashboard'],
    }),
    getDFilter: build.mutation({
      query: ({ startDate, finishDate }) => ({
        url: `admin/dashboard?startDate=${startDate}&finishDate=${finishDate}`,
        method: 'GET',
      }),
      invalidatesTags: ['DFilter'],
    }),

    updateCustomer: build.mutation({
      // UPDATED
      query: ({ _id, wallet, blocked }) => ({
        url: `admin/block/${_id}`,
        method: 'PATCH',
        body: { wallet, blocked },
      }),
      invalidatesTags: ['Customers'],
    }),
    updateUser: build.mutation({
      // UPDATED
      query: (obj) => ({
        url: `admin/wallet/${obj.id}`,
        method: 'PATCH',
        body: { obj },
      }),
      invalidatesTags: ['Customers'],
    }),
    updateState: build.mutation({
      // UPDATED
      query: ({ id, state, reason }) => ({
        url: `wallet/updateWR/${id}`,
        method: 'PATCH',
        body: { state, reason },
      }),
      invalidatesTags: ['pendingWithDraw'],
    }),
    updateBankWR: build.mutation({
      // UPDATED
      query: ({ id, state, reason, type }) => ({
        url: `wallet/updateBankWR/${id}`,
        method: 'PATCH',
        body: { state, reason, type },
      }),
      invalidatesTags: ['bankWR'],
    }),
    updateHoldGame: build.mutation({
      query: (obj) => ({
        url: `admin/update-challenge`,
        method: 'POST',
        body: obj,
      }),
      invalidatesTags: ['HoldGames'],
    }),
    addSettings: build.mutation({
      query: (obj) => ({
        url: `admin/settings`,
        method: 'POST',
        body: obj,
      }),
      invalidatesTags: ['Settings'],
    }),
    getSettings: build.query({
      query: () => `admin/settings`,
      providedTags: ['Settings'],
    }),
    updateSettings: build.mutation({
      // UNKNOWN
      query: ({ id, body }) => ({
        url: `client/results/${id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    getResult: build.mutation({
      // UNKNOWN
      query: ({ roomCode }) => ({
        url: `client/results/${roomCode}`,
        method: 'PATCH',
        body: {},
      }),
    }),
  }),
});

// export api endpoints
export const {
  useGetUserQuery,
  useGetWithdrawRequestsQuery,
  useGetSearchWithdrawRequestsQuery,
  useGetBankWRRequestsQuery,
  useGetSearchBankWRRequestsQuery,
  useGetSuccessRequestsQuery,
  useGetSearchSuccessRequestsQuery,
  useSearchCustomersQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useSearchHoldGamesQuery,
  useGetGeographyQuery,
  useGetSearchGeographyQuery,
  useGetOverviewQuery,
  useSearchOverviewQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useEditAdminQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetSuperDashboardQuery,
  useGetDFilterMutation,
  useUpdateCustomerMutation,
  useUpdateUserMutation,
  useLoginMutation,
  useUpdateStateMutation,
  useUpdateBankWRMutation,
  useUpdateHoldGameMutation, // Add the mutation hook here
  useGetResultMutation, // Add the mutation hook here
  useGetSettingsQuery,
  useAddSettingsMutation,
  useUpdateSettingsMutation,
  useGetCustomersByIdQuery,
  useGetTransactionByIdQuery,
  useGetLudoNameByIdQuery,
} = api;
