import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AppLayout from "../layout/AppLayout";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ConfirmLegal from "../components/atoms/ConfirmTerms";

export default function LegalTerms(props) {
  const legal_time = localStorage.getItem("legal_timeOut");
  const legal_amount = localStorage.getItem("legal_amount");
  const legalTimeObject = JSON.parse(legal_time);
  const legalAmountObject = JSON.parse(legal_amount);

  const [valueA, setValueA] = useState(0);
  const [valueB, setValueB] = useState(0);
  const [valueC, setValueC] = useState(0);
  const [isVisible, setIsVisible] = useState("back");
  const [is_open, setIsOpen] = useState(false);
  const [amount, setAmount] = useState(legalAmountObject);
  const [timeoutValue, setTimeoutValue] = useState(legalTimeObject);

  return (
    <AppLayout>
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div
          className="container d-flex flex-column align-items-stretch justify-content-start mt-3 mb-5"
          style={{ textAlign: "left" }}
        >
          <ConfirmLegal
            is_open={is_open}
            setIsOpen={setIsOpen}
            amount={amount}
          />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Terms &amp; Conditions</Accordion.Header>
              <Accordion.Body>
                <p>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    These <strong>terms and conditions</strong> of use (“Terms”)
                    along with privacy policy (“Privacy Policy”) forms a legally
                    binding agreement (“Agreement”) between You and us (
                  </span>
                  <span
                    style={{
                      color: "rgb(209,72,65)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    gotiking.com
                  </span>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    ).
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    Hence, We insist that You read these Terms and Privacy
                    Policy and let Us know if You have any questions regarding
                    the same. We will try Our best to answer Your queries.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    <strong>A. USERS’ APPROVAL</strong>
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    1. Users approve of and accept over Agreement by:
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (a) reading all terms and condition
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (b) reading all rules of this app
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    2. Users may accept this Agreement only if:
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (a) Such User is a natural person, is of the legal age (18
                    years or older), eligibility and mental capacity to form a
                    binding contract with us.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (b) Such User is a not a resident of Tamil Nadu, Andhra
                    Pradesh, Telangana, Assam, Orissa, Sikkim, Nagaland.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (c) Such User is a juristic person, is lawfully existing,
                    and has all the authorizations, permits, and allowances to
                    enter into this Agreement and form a binding contract.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (d) Such User is not legally barred or restricted from using
                    the App.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    3. You understand that We want You to not use the App if You
                    do not understand, approve of or accept all the terms
                    specified in this Agreement. Hence, You are requested to
                    read these Terms and Privacy Policy carefully and understand
                    the Agreement before You accept it and agree to be bound by
                    it.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    4. The Agreement shall govern the relationship of each User
                    with us. However, We may also execute separate written
                    agreements with its Users. In case of conflict between terms
                    of such separate written agreement and this Agreement, the
                    terms of the separate written agreement shall prevail.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    <strong>B. PROVISION OF THE APP</strong>
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    1. Section 12 of the Public Gambling Act, 1867 provides that
                    games of mere skill are exempt from the application of the
                    Act. The Supreme Court of India and various High Courts in
                    India have opined that a game of mere skill is a game “in
                    which, although the element of chance necessarily cannot be
                    entirely eliminated, success depends principally upon the
                    superior knowledge, training, attention, experience and
                    adroitness of the player. A game of skill is one in which
                    the element of skill predominates over the element of
                    chance.” No penalty can be imposed upon a person for playing
                    such games of skill.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    2. Users must note that ‘Ludo’ game available for challenge
                    in our platform is ‘Games of Skill’, under Indian law, and
                    that we does not support, endorse or offer to Users ‘games
                    of chance’ for money. While ‘Games of Skill’ do not have a
                    comprehensive definition, they are those games where the
                    impact of a player’s effort and skill on the outcome of a
                    game is higher than the impact of luck and chance.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    3. In adherence to prevailing laws, the Company prohibits
                    individuals residing in specific Indian states, including
                    Andhra Pradesh, Assam, Nagaland, Odisha, Telangana, Sikkim,
                    Tamil Nadu and those residing outside India, from
                    participating in the Games. Access to the Platform is banned
                    for users from these states.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    4. The games rules are clearly defined on this platform and
                    Users are encouraged to read, understand and follow these
                    rules to be successful in these games.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    5. The games on our platform are ‘Games of Skills’, such
                    that the outcome / success in the games is directly
                    dependent on the User’s effort, performance and skill. By
                    choosing how to play, the actions of Users shall have direct
                    impact on the game.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    6. Every game will have some elements of chance, but in the
                    form of challenges / obstacles that a User would be able to
                    overcome using his/her skills and knowledge of the game.
                    Elements of luck are present in every game to add thrill and
                    excitement, but no two attempts at a game are identical so
                    Users must use their skills in order to be successful
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    7. Since the games available on our platform can be won
                    through Users’ skills and such skills may be enhanced with
                    practice and experience, the performance of a User may
                    improve with time and practice.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    8. Certain games may have pre-determined outcomes (Ludo),
                    and these outcomes are achievable by Users using their
                    skills.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <strong>C. GAME RULES</strong>
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    1. User must have to record every game, and if any player is
                    hacking or cheating a game, contact support.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    2. If game is not started, if you haven't played a single
                    move yourself, please show the recording of game to support.
                    Game will be cancelled only if you have recording.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    3. If you don't have any proof against player cheating and
                    error in game, you will be considered as lost.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    4. If you have not moved a single pawn or no pawn is open at
                    home, your game will be cancelled.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    5. If your opponent leaves match purposely in starting or
                    initial game, and opponent doesn't have any valid proof for
                    cancellation, you will be awarded win.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <strong>D. DEPOSIT / WITHDRAWAL</strong>
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    1. Players can deposit their balance in{" "}
                  </span>
                  <a href="https://www.gotiking.com/#/buy" target="_self">
                    <span
                      style={{
                        color: "rgb(33,37,41)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong>Buy Chips</strong>
                    </span>
                  </a>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    section.{" "}
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    Important:- If we detect any type of suspecious
                    transaction/activity in your account, in such cases we have
                    rights to Block your account temporarily. Kindly contact
                    Admins in such cases and provided the needed details to
                    Un-block your account.{" "}
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    2. Player can take withdrawal by setting a Sell Request on
                    your app.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    3. Deposit and withdrawal requests are completed by support
                    at any time.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    4. Any wrong payment detail given by you, will not be
                    considered in refund.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    5. Once a withdrawal is done, you don't have any authority
                    to raise any query.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    6. If withdrawal request go on pending, user must have to
                    wait for 1-5 days.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    <strong>E. PENALITY FOR WRONG UPDATES</strong>
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    1. If you put the wrong update on any match, you will be
                    charged penality of:
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (a) if your game is below 1000, penalty will be 10%.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (b) if your game is above 1000 and below 5000, penality will
                    be 50 flat.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    (c) if your game is above 5000 and below 15000, penality
                    will be 100 flat.
                  </span>
                </p>
                <p style={{ textAlign: "left" }}>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    2. If you don't update result after losing, you will be
                    charged penality of 25 flat.
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      color: "rgb(33,37,41)",
                      backgroundColor: "rgb(255,255,255)",
                      fontSize: "16px",
                    }}
                  >
                    3. Players can have only single account in case multiple
                    accounts found, We have authority to ban those account
                    permanently &amp; add penalty
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Cancellation &amp; Refund Policy
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>Refund Policy:</strong>
                </p>
                <p>
                  Thanks for being connected with
                  <span style={{ color: "rgb(226,80,65)" }}>
                    <strong> Goti King</strong>
                  </span>
                  . If you are not entirely satisfied with your subscription, we
                  are here to help.
                </p>
                <p>
                  <strong>Refunds Process:</strong>
                </p>
                <p>
                  Once we receive your Refund request, we will inspect it and
                  notify you on the status of your refund.
                </p>
                <p>
                  If your refund request is approved, we will initiate a refund
                  to your credit card (or original method of payment) within 7
                  working days. You will receive the credit within a certain
                  amount of days, depending on your card issuer's policies.
                </p>
                <p>
                  In case of unforeseen technical glitch,{" "}
                  <span style={{ color: "rgb(226,80,65)" }}>Goti King</span>{" "}
                  would refund subscription upon reviewing the complaint. Final
                  decision lies with the company.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="w-100" eventKey="2">
              <Accordion.Header>Privacy Policy</Accordion.Header>
              <Accordion.Body>
                <div className="accordion-body">
                  <p>
                    <strong>Introduction</strong>
                  </p>
                  <p>
                    This document explains how{" "}
                    <span style={{ color: "rgb(235,107,86)" }}>Goti King</span>{" "}
                    (https://www.gotiking.com) collects, processes, stores
                    and/or shares any personal data and/or information from
                    users (jointly referred to as “Information”). By accessing
                    and/or using the Services you consent the collection,
                    transfer, manipulation, storage, disclosure and other uses
                    of your information as described in this Privacy Policy. If
                    you have any concerns about providing data, or having it
                    used in any manner permitted in this Privacy Policy, you
                    should not use the Services. As set out in the terms and
                    conditions relating the Services (the “Terms”), you must be
                    at least at legal age{" "}
                    <strong>(minimum age of 18 years)</strong> to access and/or
                    use the Services, or if legally possible, to access with
                    your legal guardian consent, due authorization and agreement
                    with these Privacy Policy.
                  </p>
                  <p>
                    <strong>The Information collected:</strong>
                  </p>
                  <p>
                    <span style={{ color: "rgb(235,107,86)" }}>
                      <strong>Goti King</strong>
                    </span>{" "}
                    and/or third parties, including but not limited to business
                    partners, advertising networks, analytics or search
                    information providers, may collect and process the following
                    data about you: information that you provide when you fill
                    in forms when accessing and/or using the Services, or when
                    you create an account within the Services; details of your
                    use of the Services and the resources that you access; the
                    correspondence between you and Goti King and any
                    interactions, or with other users on the Service, the
                    channels and/or any social media related; from third parties
                    who hold data about you and who agree to share this data
                    with us; and information collected via cookies and other
                    similar technologies and/or other mechanisms, as explained
                    further below.
                  </p>
                  <p style={{ textAlign: "start" }}>
                    <span
                      style={{
                        color: "rgba(0,0,0,0.87)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong>Personal Data</strong>: While using Our Service,
                      We may ask You to provide Us with certain personally
                      identifiable information that can be used to contact or
                      identify You. Personally identifiable information may
                      include, but is not limited to:
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        color: "rgba(0,0,0,0.87)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong> * Email address</strong>
                    </span>
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <span
                      style={{
                        color: "rgba(0,0,0,0.87)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong> * First name and last name</strong>
                    </span>
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <span
                      style={{
                        color: "rgba(0,0,0,0.87)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong> * Phone Number</strong>
                    </span>
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <span
                      style={{
                        color: "rgba(0,0,0,0.87)",
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "16px",
                      }}
                    >
                      <strong>
                        {" "}
                        * Address, State, Province, ZIP/Postal code, City
                      </strong>
                    </span>
                  </p>
                  <p>
                    I. Cookies: <strong>Goti King</strong> uses cookies and
                    other similar technologies in the Services. These
                    technologies operate either by placing a small file which
                    stores some information on your computer or mobile device;
                    and/or by accessing information on your device. Goti King
                    uses cookies and similar technologies to recognize you and
                    your device, for example by identifying your IP address; to
                    allow the Services to interact with a third party social
                    network or platform where you have chosen to allow such
                    interaction; to allow payment processes when you submit
                    payment instructions; to enable Goti King and third parties
                    to provide you with more customized services; and to collect
                    data such as your device’s model, operating system and
                    screen size, other applications installed on your device,
                    and information about how you use the Services. By accessing
                    and/or using the Services you consent the use of cookies and
                    similar technologies in accordance with this Privacy Policy.
                    You can disable cookies through your web or mobile device
                    browser settings but some features of the Services may not
                    function properly. Alternatively, if you do not wish such
                    data collection as described in this section, you should
                    stop using the Services.
                  </p>
                  <p>
                    II. Ads: Advertisers on mobile devices sometimes use
                    advertising identifiers to enable and optimize their
                    advertising, to deliver tailor ads related to your interests
                    (Interest-Based Advertising or IBA). These identifiers are
                    non-permanent, non-personal, device identifiers. Goti King
                    and/or third parties may use your device’s advertising
                    identifier and other information associated with it, to
                    deliver ads that relate to your interests and to improve and
                    measure the effectiveness of ad campaigns.
                  </p>
                  <p>
                    III. Location Information: You may choose to publish your
                    location in your Goti King profile. You may also tell your
                    location when you enable your device to send such location
                    information. Goti King and/or third parties may use and
                    store information about your location to provide special
                    features, to deliver ads that relate your interests and/or
                    to improve and customize the Services.
                  </p>
                  <p>
                    IV. Links: Goti King may keep track of how you interact with
                    links across the Services, including email notifications,
                    third-party services, and client applications, by
                    redirecting clicks or through other means.
                  </p>
                  <p>
                    V. Log Data: Servers automatically record information
                    created by your use of the Services. This data may include
                    information such as your IP address, browser type, operating
                    system, the referring web page, pages visited, location,
                    your mobile carrier, device and application IDs, search
                    terms, and cookie information. Log data is received when you
                    interact with the Services. This data is used to provide the
                    Services and to measure, customize, and improve them.
                  </p>
                  <p>
                    VI. Payment information: If you make a purchase in the
                    Services, Goti King may collect the billing and financial
                    information necessary to process the charges; and/or do so
                    on behalf of the relevant payment service providers.
                    Purchases of third party services are subject to the
                    policies applicable to such provider.
                  </p>
                  <p>
                    VII. Third-party services: Goti King uses a variety of
                    third-party services to help its provision of the Services,
                    such as hosting and other services. These third-party
                    service providers may collect information sent by your
                    browser as part of a web page request, such as cookies or
                    your IP address, location and devices’ unique identifiers.
                    Also, third-party ad partners may share information to
                    measure ad quality and tailor ads, for example to display
                    ads about things you may have already shown interest in.
                  </p>
                  <p>
                    VIII. Customer Support Correspondence: When you ask for
                    assistance from Goti King customer support, the contact
                    information you provide will be collected, as well as
                    information about your game play or activity on the Service,
                    your user ID number, and the correspondence and any
                    information contained within. If available through the
                    Services, you may provide Goti King with profile information
                    to make public, such as a short biography, location,
                    website, cell phone, a picture, information to customize
                    your account, etc. Such contact information may be used to
                    send you information about the Services or related
                    information. You may use your account settings to
                    unsubscribe from notifications from Goti King and/or from
                    other users and find users you know. You may also
                    unsubscribe by following the instructions contained within
                    the notification or the instructions on the Service. Also
                    Goti King may use your contact information to help others
                    find your account, including through third-party services
                    and client applications. Providing any additional
                    information described in this section is entirely optional.
                  </p>
                  <p>
                    <strong>Use of Information</strong>
                  </p>
                  <p>
                    When you create or configure an account in the Services, you
                    provide some personal information, such as your name,
                    username, password, email address and any other information
                    as required time to time. Some of this information, for
                    example, your name and/or username, may be listed publicly
                    on the Services, including on your profile page and in
                    search results. You agree that Goti King and/or third
                    parties on its behalf and/or its partners may use the
                    Information for the purposes of contacting you as part of
                    customer support; to send you updates or information about
                    the Services; managing your account and relationship with
                    the Service and improving your experience when you use it,
                    improving the Services, research, surveying, and engaging
                    with you, for example by sending you communications for
                    these purposes; marketing and promotion of the Services or
                    products; to personalize and optimize the Services,
                    promotional content and/or advertising; to create reports,
                    analysis or similar services for the purposes of research or
                    business intelligence.
                  </p>
                  <p>
                    <strong>Information sharing and disclosure</strong>
                  </p>
                  <p>
                    Goti King will only share your data with third parties
                    according to the Privacy Policy, as reasonably necessary in
                    order to provide the Services, for example, by providing
                    Information to suppliers that Goti King may use to fulfill
                    the Services; where it is necessary to carry out your
                    instructions, for example, to process a payment instruction
                    your Information has to be provided to the payment
                    processors; where your data is on an anonymous and
                    aggregated basis, meaning you could not be personally
                    identified from it; for the delivery of Interest-Based
                    Advertising in the manner set out on this Privacy Policy;
                    when you submit information as part of a competition or
                    otherwise interact with any channel or social media, the
                    information you submitted may be published; as it`s
                    reasonably believed is permitted by law or regulation; in
                    order to comply with any legal obligation, or in order to
                    enforce or apply the Terms, this Privacy Policy and/or any
                    other agreement with you; or to protect the rights and/or
                    property of Goti King or third-party´s rights and/or
                    property. Goti King may share or disclose your non-private
                    information, such as public user profile information, public
                    messages, e-mail, etc., or share or disclose your
                    information in an anonymous or aggregated basis in a manner
                    that does not allow your personal identification.
                  </p>
                  <p>
                    <strong>
                      Interaction with social networks and/or platforms
                    </strong>
                  </p>
                  <p>
                    You may allow the Services to interact with any third party
                    social network and/or platforms, such as Facebook, twitter,
                    whatsApp and any other networks/platforms which will provide
                    data about you to Goti King. Since any other applications or
                    websites different than the Services are owned by a third
                    party, you must ensure that you read their terms of service
                    and the applicable privacy policies. You understand that
                    when you allow the Services to interact with any third party
                    social network and/or platform, Goti King may share data
                    about you with your contacts and other users of the Services
                    and vice versa. This Data may include your name, profile
                    picture, activity status, and information related to your
                    use of the Services. You can change this by adjusting your
                    settings with that third party provider.
                  </p>
                  <p>
                    Goti King has certain links embedded to third party services
                    including but not limited to YouTube. Your interaction with
                    such third party platform/s are governed by their policies,
                    and we urge you to review their policies before you proceed
                    with availing such services via the offerings of Goti King.
                    The YouTute terms are available at{" "}
                    <a href="https://www.youtube.com/t/terms" target="_self">
                      https://www.youtube.com/t/terms
                    </a>{" "}
                    and the Google Privacy Policy is available at
                    https://policies.google.com/privacy?hl=en-US. Also, to
                    control your interaction with Google account or their
                    services allows you to control your rights and activities,
                    and may be accessed at
                    https://myaccount.google.com/permissions?pli=1.
                  </p>
                  <p>
                    <strong>Term</strong>
                  </p>
                  <p>
                    Goti King may retain the Information for as long as is
                    necessary to fulfill the purposes for which it was collected
                    or as needed to provide the Services, even after you have
                    discontinued or deleted any account, or after the end of the
                    provision of the Services, if retention of such Information
                    is reasonably necessary to comply with legal obligations,
                    meet regulatory requirements, resolve disputes between
                    users, prevent fraud, or any other use.
                  </p>
                  <p>
                    <strong>Protection of Information</strong>
                  </p>
                  <p>
                    Goti King maintains appropriate technical and physical
                    safeguards to protect Information against accidental or
                    unlawful destruction or loss, alteration, unauthorized
                    disclosures or access, and any other unlawful forms of
                    processing of the data in its possession. However, Goti King
                    does not guarantee that Information will not be accessed,
                    disclosed, altered or destroyed by breach of any of the
                    abovementioned safeguards. Information may be transferred to
                    and/or stored at worldwide destinations. Goti King takes all
                    steps reasonably necessary to ensure that Information is
                    treated securely and in accordance with this Privacy Policy.
                    In the event that Goti King is involved in a bankruptcy,
                    merger, acquisition, reorganization or sale of assets, your
                    information may be sold or transferred as part of that
                    transaction. The undertakings in this Privacy Policy shall
                    apply to the Information as transferred to the new entity.
                  </p>
                  <p>
                    In the Services you may find links to third party websites.
                    You understand that when you click on these links any data
                    which you provide afterwards is subject to that third
                    party's privacy policy and not to Goti King’s. Consequently,
                    Goti King takes no responsibility for the content, safety or
                    security of any third party website. The Services are not
                    directed to persons under legal age. Goti King does not
                    knowingly collect any Information from children under legal
                    age. If you become aware that a child under legal age has
                    provided with personal information, steps will be taken to
                    remove such information and terminate such account. If you
                    become aware that any child has provided personal
                    information without the essential legal guardian consent,
                    please contact:{" "}
                    <span style={{ color: "rgb(235,107,86)" }}>
                      <strong>support@gotiking.com</strong>
                    </span>
                    . Irrespective of which country you reside in or supply
                    information from, you authorize Goti King to store and/or
                    use the Information according to this Privacy Policy in any
                    country where Goti King may operate.
                  </p>
                  <p>
                    Not with standing anything to the contrary in this Policy,{" "}
                    <strong>Goti King</strong> may preserve or disclose your
                    information to the extent reasonably necessary to comply
                    with a law, regulation or legal request; to protect the
                    safety of any person; to address fraud, security or
                    technical issues; or to protect Goti King's rights or
                    property. However, nothing in this Privacy Policy is
                    intended to limit any legal defenses or objections that you
                    may have to a third party’s, including a government’s
                    request to disclose your information. We wants to make sure
                    that your Information is accurate and up to date. You may
                    ask to modify, correct or remove information with the tools
                    and account settings of the Service, or otherwise by
                    contacting Goti King at support@gotiking.com.
                  </p>
                  <p>
                    If any court or other competent authority finds any of this{" "}
                    <strong>Privacy Policy</strong> to be invalid or
                    unenforceable, the other terms of this Privacy Policy will
                    not be affected. This Privacy Policy is governed by and
                    interpreted in accordance with the laws of Nagaland State
                    Government as well as Republic of India. Any dispute arising
                    in connection with this Privacy Policy will be subject to
                    the exclusive jurisdiction of the courts located in the city
                    of Kurukshetra/Haryana – India. You consent the jurisdiction
                    and venue in such courts and waive any objection as to
                    inconvenient forum Goti King may revise or amend this
                    Privacy Policy from time to time.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>GST Policy</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column ">
                  <p>
                    From <b>1st October 2023</b>, New <b>28%</b> Government Tax
                    (GST) is applicable on the deposits.
                  </p>
                  <h4>Let's understand the new GST regime..</h4>
                  <p>
                    If a player deposits <b>Rs.100</b> to play a game, there
                    will be inclusive <b>28%</b> GST levied on the deposit
                    amount, and the user will need to complete a transaction of{" "}
                    <b>Rs.100</b> (Rs. 78.13 + 28% of Rs. 78.13). Thus, Rs. 100
                    will be settled in the user’s deposit wallet and the Rs.
                    21.88 will be accounted for GST paid. Exact{" "}
                    <b>GST amount</b> will be credited into user Bonus wallet.
                    The details of GST paid by the user can be viewed in the
                    View Transactions on the application.
                  </p>
                  <div className="p-3 card">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        Deposit Amount (Excl. Govt. Tax){" "}
                        <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                          A
                        </span>
                      </span>
                      <span style={{ color: "green" }}>₹ 78.12</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Govt. Tax (28% GST)</span>
                      <span>₹ 21.88</span>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Total</span>
                      <span>₹ 100.00</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        Cashback Bonus
                        <img
                          src="https://ludoplayers.com/static/media/pepeking.9d780f94071645c86f5e.png"
                          alt="pepeking"
                          width="32"
                        />
                        <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                          B
                        </span>
                      </span>
                      <span style={{ color: "green" }}>₹ 21.88</span>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-bold">
                        Add To Wallet Balance{" "}
                        <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                          A
                        </span>{" "}
                        +{" "}
                        <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                          B
                        </span>
                      </span>
                      <span className="fw-medium" style={{ color: "green" }}>
                        ₹ 100.00
                      </span>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>TDS Policy</Accordion.Header>
              <Accordion.Body>
                <div className="flex flex-column ">
                  In line with the provisions contained in{" "}
                  <a
                    target="_blank"
                    href="https://incometaxindia.gov.in/news/finance-bill-2023-highlights.pdf"
                    rel="noreferrer"
                  >
                    Finance Act, 2023
                  </a>
                  , following TDS policy is applicable for all players on
                  Gotiking from 1 Apr, 2023.
                  <ul className="mt-3 mb-3">
                    <li>
                      TDS is applicable at the time of withdrawal or deposit
                      refund
                    </li>
                    <li>
                      30% TDS is applicable on any positive net winnings at the
                      time of withdrawal
                    </li>
                    <li>
                      TDS is also applicable at the end of financial year on
                      year end wallet balance by considering it as a withdrawal
                      amount. Post applying TDS on the remaining balance (as per
                      policy), the remaining amount will be carried forward to
                      the next financial year as deposit balance. Carried
                      forward balance will be considered as investment for next
                      year and TDS would not be applicable for that amount.
                    </li>
                  </ul>
                  <p>
                    Here, Net winnings = Total Withdrawals - Total Deposits (in
                    a financial year). Apr 1 to Mar 31 duration is considered a
                    financial year.
                  </p>
                  <h4>How was TDS deducted before Apr 1, 2023 :</h4>
                  <ul>
                    <li>
                      Before Apr 1 2023, TDS was deducted at a game level when
                      the winnings were greater than 10K.
                    </li>
                  </ul>
                  <p>
                    Example : Previously if I won Rs 11000 in a game, then 30%
                    TDS will be deducted i.e. 3300 and hence Rs 7700 will be
                    credited in the withdrawal wallet.
                  </p>
                  <h4>
                    How is the current TDS policy different from the last TDS
                    policy of FY 2022- 2023
                  </h4>
                  <ul>
                    <li>
                      Post Apr 1, if I win some amount let's assume Rs 11000, NO
                      TDS will be deducted while crediting that amount in the
                      withdrawal wallet. Entire Rs 11000 will be transferred to
                      the winnings wallet.
                    </li>
                    <li>
                      Only when the user will try to withdraw any amount from
                      the wallet, we will look at the net winnings and basis
                      that calculate the TDS applicable.
                    </li>
                    <li>
                      Hence we will deduct TDS only when the withdrawal running
                      total is more than the Deposits. If the user is in loss,
                      there will be no TDS. Hence this will be beneficial to the
                      users.
                    </li>
                  </ul>
                  <p>
                    Please note that the TDS Policy is based on the
                    understanding of the provisions as introduced/ amended by
                    Finance Act, 2023 and the Company reserves its right to
                    modify/ change/ amend the TDS Policy basis law applicable at
                    the relevant time. Attached is the finance bill amendment
                    which says that the policy will be applicable from Apr 1,
                    2023 rather than July 1, 2023.
                  </p>
                  <h4>What Does It Mean For Players?</h4>
                  <p>
                    Find below a few scenarios to understand the application of
                    the aforementioned TDS Policy.
                  </p>
                  <br />
                  <p>
                    <b>Scenario 1:</b> If your net winnings (including the
                    withdrawal amount) is more than 0, then 30% TDS will be
                    deducted on the net winnings at the time of withdrawal.
                  </p>
                  <b>Example:</b>
                  <div className="card">
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total withdrawals in the financial year(A)</small>
                      <small className="ms-3">₹5,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total deposits in the financial year(B)</small>
                      <small className="ms-3">₹10,000</small>
                    </div>
                  </div>
                  <p>
                    In the above scenario, if you want to withdraw ₹7,000 , then
                    the net winnings will be:
                  </p>
                  <div className="card">
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Amount being withdrawn by the player(C)</small>
                      <small className="ms-3">₹7,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Net winnings(A + C - B)</small>
                      <small className="ms-3">₹2,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>30% TDS Applicable on Net Winnings(D)</small>
                      <small className="ms-3">₹600</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>
                        Amount to be credited in the bank account(C-D)
                      </small>
                      <small className="ms-3">₹6,400</small>
                    </div>
                  </div>
                  <p>
                    <b>Zero TDS Withdrawal amount:</b> If you withdraw upto
                    ₹5,000 , then there will be zero TDS deducted as net
                    winnings will be ₹0 (₹5,000 + ₹5,000 - ₹10,000). See
                    calculation below:
                  </p>
                  <div className="card">
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total withdrawals in the financial year(A)</small>
                      <small className="ms-3">₹5,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total deposits in the financial year(B)</small>
                      <small className="ms-3">₹10,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Amount being withdrawn by the player(C)</small>
                      <small className="ms-3">₹5,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Net winnings(A + C - B)</small>
                      <small className="ms-3">₹0</small>
                    </div>
                  </div>
                  <p>
                    <b>Scenario 2:</b> If you have paid TDS in previous
                    withdrawals, then no TDS is applicable on withdrawals till
                    your net winnings crosses the amount for which you have
                    already paid TDS.
                  </p>
                  <b>Example:</b>
                  <div className="card">
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total withdrawals in the financial year</small>
                      <small className="ms-3">₹20,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>Total deposits in the financial year</small>
                      <small className="ms-3">₹20,000</small>
                    </div>
                    <div className="price_row d-flex w-100 p-3 align-items-center justify-content-between price-row border-bottom">
                      <small>
                        TDS paid in the financial year (TDS paid on winnings of
                        ₹6,000 as initially you had positive net winnings of
                        ₹6,000)
                      </small>
                      <small className="ms-3">₹1,800</small>
                    </div>
                  </div>
                  <p>
                    In this scenario, No TDS applicable till your net winnings
                    crosses ₹6,000 (winnings already taxed):
                  </p>
                  <ul>
                    <li>
                      <b>Zero TDS Withdrawal amount:</b> If you withdraw up to
                      ₹6,000, no TDS is applicable
                    </li>
                    <li>
                      If you withdraw ₹10, 000, then 30% TDS is applicable only
                      on ₹4,000 winnings i.e. ₹1,200.
                    </li>
                  </ul>
                  <p>
                    <b>Special Note:</b> In case you have an amount on the table
                    at the end of the financial year, then it will not be
                    considered in next year's starting balance.
                  </p>
                  <div className="card">
                    <div className="text-center  card-header">
                      TDS Calculator
                    </div>
                    <div className="d-flex flex-column gap-2 card-body">
                      <div className="d-flex flex-column">
                        <span>
                          Total Withdrawal in this Financial Year (A):
                        </span>
                        <div className="input-group">
                          <span className="input-group-text bg-light text-dark">
                            ₹
                          </span>
                          <input
                            placeholder="Amount"
                            min="0"
                            type="number"
                            id="amount"
                            className="form-control form-control"
                            value={valueA}
                            onChange={(e) => setValueA(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <span>Total Deposits in the Financial Year (B):</span>
                        <div className="input-group">
                          <span className="input-group-text bg-light text-dark">
                            ₹
                          </span>
                          <input
                            placeholder="Amount"
                            min="0"
                            disabled={!valueA}
                            type="number"
                            id="amount"
                            className="form-control form-control"
                            value={valueB}
                            onChange={(e) => setValueB(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <span>Amount Being Withdrawn By the Player (C):</span>
                        <div className="input-group">
                          <span className="input-group-text bg-light text-dark">
                            ₹
                          </span>
                          <input
                            placeholder="Amount"
                            min="0"
                            disabled={!valueA || !valueB}
                            type="number"
                            id="amount"
                            className="form-control form-control"
                            value={valueC}
                            onChange={(e) => setValueC(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Net Winning (A+C)-B:</span>
                        <span>
                          ₹{" "}
                          {`${Number(valueA) + Number(valueC)}` -
                            Number(valueB)}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>TDS Applicable (30% on net winnings):</span>
                        <span>
                          ₹{" "}
                          {(`${Number(valueA) + Number(valueC)}` -
                            `${Number(valueB)}`) *
                            0.3}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Responsible Gaming</Accordion.Header>
              <Accordion.Body>
                <div class="d-flex flex-column align-items-center gap-3 ">
                  <p>
                    We work hard to deliver a safe and secure gaming experience
                    for you on Gotiking.
                  </p>
                  {isVisible === "back" && (
                    <div class="d-flex flex-column gap-1 w-100">
                      <p>Here are some tools to help you out:</p>
                      <button
                        onClick={() => setIsVisible("alerts")}
                        type="button"
                        class="btn btn-primary"
                      >
                        Set Alerts
                      </button>

                      <button
                        onClick={() => setIsVisible("timeout")}
                        Set
                        Alerts
                        type="button"
                        class="btn btn-primary"
                      >
                        Take Stratigic Timeout
                      </button>
                    </div>
                  )}
                  {/* //////////////////////////////// */}
                  {isVisible === "alerts" && (
                    <div className="d-flex flex-column gap-1 w-100">
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"></path>
                          </svg>{" "}
                          <span>Set Net Loss Limit</span>
                        </div>
                        <small style={{ color: "gray" }}>
                          Set an alert if the loss for financial year exceeds
                        </small>
                        <div className="input-group">
                          <span className="input-group-text bg-light text-dark">
                            ₹
                          </span>
                          <input
                            placeholder="Amount"
                            min="0"
                            max="100000"
                            type="number"
                            id="amount"
                            className="form-control form-control"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <h4 className="mt-1">
                          Current Limit: ₹ {legalAmountObject}
                        </h4>
                        <div
                          role="alert"
                          className="fade alert alert-info show"
                        >
                          <small>
                            <b>NOTE:</b> You will receive an alert when your net
                            loss amount exceeds.
                          </small>
                        </div>
                        <div className="d-flex flex-row gap-1">
                          {!(amount == legal_amount) && (
                            <button
                              onClick={() => {
                                setIsOpen(true);
                              }}
                              type="button"
                              className="btn btn-primary"
                            >
                              Update
                            </button>
                          )}
                          <button
                            onClick={() => setIsVisible("back")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {isVisible === "timeout" && (
                    <div className="d-flex flex-column gap-1 w-100">
                      <div className="d-flex flex-column gap-1 w-100">
                        <div className="d-flex gap-2">
                          <span>Set Strategic Timeout:</span>
                        </div>
                        <h4 className="text-center">{timeoutValue} Days</h4>
                        <Slider
                          marks={{
                            5: "5d",
                            10: "10d",
                            15: "15d",
                            20: "20d",
                            25: "25d",
                            30: "30d",
                          }}
                          step={5}
                          defaultValue={
                            localStorage.getItem("legal_timeOut")
                              ? localStorage.getItem("legal_timeOut")
                              : 0
                          }
                          min={0}
                          max={30}
                          onChange={(value) => setTimeoutValue(value)}
                        />
                        <div
                          role="alert"
                          className="fade mt-3 alert alert-info show"
                        >
                          <small>
                            <b>NOTE:</b> You will receive an alert when your net
                            loss amount exceeds.
                          </small>
                        </div>
                        <div className="d-flex flex-row gap-1">
                          {!(timeoutValue === legalTimeObject) && (
                            <button
                              onClick={() => {
                                localStorage.setItem(
                                  "legal_timeOut",
                                  timeoutValue
                                );
                                toast.success(
                                  `Strategic Timeout updated to ${timeoutValue} days`
                                );
                                setIsVisible("back");
                              }}
                              type="button"
                              className="btn btn-primary"
                            >
                              Set Timeout
                            </button>
                          )}
                          <button
                            onClick={() => setIsVisible("back")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* //////////////////////////////// */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>About Us</Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>Goti King</strong> is a real-money online gaming
                  product.
                </p>
                <p>
                  We are an HTML5 game-publishing company and our mission is to
                  make accessing games fast and easy by removing the friction of
                  app-installs.
                </p>
                <p>
                  Goti King is a skill-based real-money gaming platform
                  accessible only for our users in India. It is accessible on{" "}
                  <span style={{ color: "rgb(209,72,65)" }}>
                    https://www.gotiking.com
                  </span>
                  . On Goti King, users can compete for real cash in Tournaments
                  and Battles. They can encash their winnings via popular
                  options such as Paytm Wallet, UPI or Phonepe.
                </p>
                <p>
                  <span style={{ fontSize: "18px" }}>
                    <strong>Our Games</strong>
                  </span>
                </p>
                <p>
                  We have a wide-variety of high-quality, premium HTML5 games,
                  online games. Our games are especially compressed and
                  optimised to work on low-end devices, uncommon browsers, and
                  patchy internet speeds.
                </p>
                <p>
                  We have games across several popular categories: Arcade,
                  Action, Adventure, Sports &amp; Racing, Strategy, Puzzle &amp;
                  Logic. We also have a strong portfolio of multiplayer games
                  such as Ludo, Chess, 8 Ball Pool, Carrom, Ludo Tournament Tic
                  Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our
                  popular titles are: Escape Run, Bubble Wipeout, Tower Twist,
                  Cricket Gunda, Ludo With Friends. If you have any suggestions
                  around new games that we should add or if you are a game
                  developer yourself and want to work with us, don't hesitate to
                  contact us on{" "}
                  <span style={{ color: "rgb(209,72,65)" }}>
                    support@gotiking.com
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Contact us</Accordion.Header>
              <Accordion.Body>
                <p> Gamgod Tech Pvt Ltd</p>
                <p>support@gotiking.com</p>
                <p>www.gotiking.com</p>

                <Link to="/support">
                  <p>Contact us</p>
                </Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Grievance Redressal</Accordion.Header>
              <Accordion.Body>
                <p>
                  For queries about our data processing or to exercise your
                  rights regarding your Personal Information, contact the
                  Grievance Officer, <b>Mr. Deepak</b>, at
                  <a href="mailto:business@gamegodtech.in">
                    {" "}
                    business@gamegodtech.in
                  </a>
                  , to revoke consent or request data deletion. We will respond
                  within 30 days or as required by law. We will delete your
                  Personal Information unless retention is legally required. If
                  any part of this Privacy Policy is deemed invalid, the rest
                  remains in effect. This policy is governed by Indian law, and
                  disputes will be resolved exclusively in the courts of Jaipur,
                  Rajasthan, India. We may update this Privacy Policy
                  periodically.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </AppLayout>
  );
}
