import { CHALLENGE } from "../contstants";

// ============SIGN UP REDUCER==================
const initialState = {
  isLoading: true,
  data: {
    creatorUserName: "",
    creatorImage: null,
    playerUserName: "",
    playerImage: null,
    playerId: "",
    creatorId: "",
    amount: 0,
    roomCode: "",
    challengeId: "", // You can set this value based on your requirements
  },
  error: "",
};

export const challenge = (state = initialState, action) => {
  switch (action.type) {
    case CHALLENGE.GET_CHALLENGE_LOADING: {
      return { ...state, isLoading: action.payload };
    }

    case CHALLENGE.GET_CHALLENGE_SUCCESS: {
      return { ...state, data: action.payload, error: "", isLoading: false };
    }
    case CHALLENGE.GET_CHALLENGE_ERROR: {
      return { ...state, data: null, error: action.payload, isLoading: false };
    }

    default:
      return state;
  }
};
