import { DASHBOARD } from "../contstants";
import Cookies from "js-cookie";

const initialState = {
  mode: "light",
  userId: "63701cc1f03239b7f700000e",
};

const initialState2 = {
  userDetails: [],
  user: [],
  holdGame: [],
  dashboard: [],
  settings: [],
  isLoading: false,
  data: {},
  error: "",
  updateResponse: "",
};

export const global = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.GLOBAL_SLICE_LOADING: {
      const { payload } = action;
      return {
        ...state,

        isLoading: payload,
      };
    }

    case DASHBOARD.GLOBAL_SLICE_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GLOBAL_SLICE_ERROR: {
      const { payload } = action;
      return {
        ...state,

        error: payload,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export const adminLoginReducer = (
  state = {
    isLoading: false,
    data: {
      isLoggedIn:
        Cookies.get("token") !== null &&
        Cookies.get("token") !== undefined &&
        Cookies.get("token") !== "",
      token: "",
    },
    error: "",
  },
  action
) => {
  switch (action.type) {
    case DASHBOARD.ADMIN_LOGIN_LOADING: {
      const { payload } = action;
      return {
        ...state,
        data: { isLoggedIn: false, token: "" },
        isLoading: payload,
      };
    }

    case DASHBOARD.ADMIN_LOGIN_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: { isLoggedIn: true, token: payload.data.jwtToken.jwtToken },
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.ADMIN_LOGIN_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: { isLoggedIn: false, token: "" },
        error: payload,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export const dashboardSlice = (state = initialState2, action) => {
  console.log("checkaciton", action);

  switch (action.type) {
    case DASHBOARD.GET_USER_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_USER_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_USER_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }
    case DASHBOARD.GET_DFILTER_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_DFILTER_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_DFILTER_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_LOADING: {
      return {
        ...state,
        users: [], // Clear users data while loading
        isLoading: true,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        users: payload, // Store fetched users in a separate field
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        users: [], // Clear users data on error
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_SEARCH_LOADING: {
      const { payload } = action;
      return {
        ...state,
        data: [],
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_SEARCH_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_CUSTOMERS_SEARCH_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: [],
        error: payload,
        isLoading: false,
      };
    }
    case DASHBOARD.GET_HOLD_GAMES_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_HOLD_GAMES_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        holdGame: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_HOLD_GAMES_ERROR: {
      const { payload } = action;
      return {
        ...state,
        holdGame: null,
        error: payload,
        isLoading: false,
      };
    }
    case DASHBOARD.GET_HOLD_GAMES_SEARCH_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_HOLD_GAMES_SEARCH_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        holdGameSearch: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_HOLD_GAMES_SEARCH_ERROR: {
      const { payload } = action;
      return {
        ...state,
        holdGameSearch: null,
        error: payload,
        isLoading: false,
      };
    }
    case DASHBOARD.GET_TOTAL_GAMES_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_TOTAL_GAMES_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_TOTAL_GAMES_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_TOTAL_GAMES_SEARCH_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_TOTAL_GAMES_SEARCH_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_TOTAL_GAMES_SEARCH_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_SETTINGS_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_SETTINGS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        settings: payload,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_SETTINGS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        settings: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.ADD_SETTINGS_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.ADD_SETTINGS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        updateResponse: payload,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.ADD_SETTINGS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        updateResponse: null,
        error: payload,
        isLoading: false,
      };
    }
    case DASHBOARD.GET_USER_DETAILS_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_USER_DETAILS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        userDetails: payload,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_USER_DETAILS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        userDetails: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_ADMINS_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_ADMINS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_ADMINS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.GET_DASHBOARD_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_DASHBOARD_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        dashboard: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_DASHBOARD_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.UPDATE_HOLDGAME_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.UPDATE_HOLDGAME_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.UPDATE_HOLDGAME_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case DASHBOARD.UPDATE_CUSTOMERS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DASHBOARD.UPDATE_CUSTOMERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        updateResponse: payload?.data, // Store update response separately
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.UPDATE_CUSTOMERS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        updateResponse: "", // Clear update response on error
        error: payload,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
export const LudoName = (state = initialState2, action) => {
  switch (action.type) {
    case DASHBOARD.GET_LUDONAME_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }

    case DASHBOARD.GET_LUDONAME_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        data: payload?.data,
        error: "",
        isLoading: false,
      };
    }

    case DASHBOARD.GET_LUDONAME_ERROR: {
      const { payload } = action;
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
