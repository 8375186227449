import { axiosConfig } from "./axiosConfig";

export const adminLoginAPI = async (payload) => {
  try {
    const data = await axiosConfig.post("/admin/login", payload);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getUserApi = async (id) => {
  try {
    const data = await axiosConfig.get(`stats/user/${id?.payload?.userId}`);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getDFilterApi = async (body) => {
  try {
    const data = await axiosConfig.get(
      `admin/dashboard?startDate=${body?.body?.startDate}&finishDate=${body?.body?.finishDate}`
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getCustomersApi = async (page) => {
  try {
    if (page?.payload?.filterName) {
      const data = await axiosConfig.get(
        `admin/all-users/${page?.payload?.page}/${page?.payload?.usersFilter}/${page?.payload?.filterName}`
      );
      return data;
    } else {
      const data = await axiosConfig.get(
        `admin/all-users/${page?.payload?.page}/${page?.payload?.usersFilter}`
      );
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateCustomersApi = async (datas) => {
  try {
    const data = await axiosConfig.patch(
      `admin/block/${datas?.body?._id}`,
      datas?.body
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getUsersSearchQueryApi = async (keyword) => {
  try {
    const data = await axiosConfig.get(
      `result/searchSuccessRequests/${encodeURIComponent(keyword)}`
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getHoldGamesApi = async (page) => {
  try {
    const data = await axiosConfig.get(
      `admin/hold-challenge/${page?.payload?.page}`
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getSearchHoldGamesApi = async (keyword) => {
  console.log(keyword);

  try {
    const data = await axiosConfig.get(
      `challenge/HoldGamesSearch/${encodeURIComponent(keyword?.payload?.seacrh)}`
    );
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getTotalGamesApi = async (page) => {
  console.log("checkpages,", page);

  try {
    let data;
    if (page?.payload?.filterName) {
      data = await axiosConfig.get(
        `admin/all-challenge/${page?.payload?.page}/${page?.payload?.filterName}`
      );
    } else {
      data = await axiosConfig.get(
        `admin/all-challenge/${page?.payload?.page}`
      );
    }
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getTotalGamesSearchApi = async (keyword) => {
  try {
    const data = await axiosConfig.get(
      `admin/challenge-search/${encodeURIComponent(keyword)}`
    );
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getSettingsApi = async () => {
  try {
    const data = await axiosConfig.get(`admin/settings`);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getUserDetailsApi = async (id) => {
  try {
    const data = await axiosConfig.get(`admin/user/${id}`);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateDiamonds = async (obj) => {
  try {
    const data = await axiosConfig.patch(`admin/wallet/diamonds/${obj.id}`, {
      obj: obj,
    });
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateUser = async (obj) => {
  try {
    const data = await axiosConfig.patch(`admin/wallet/${obj.id}`, {
      obj: obj,
    });
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getAdminsApi = async () => {
  try {
    const data = await axiosConfig.get("management/admins");
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const addSettingsApi = async (body) => {
  console.log(body);

  try {
    const data = await axiosConfig.post(`admin/settings`, body);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateChallenge = async (body) => {
  console.log(body);

  try {
    const data = await axiosConfig.post(`admin/update-challenge`, body);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getDashboardApi = async () => {
  try {
    const data = await axiosConfig.get(`admin/dashboard`);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getLudoNameByIdApi = async (id) => {
  console.log(id);

  try {
    const data = await axiosConfig.get(`admin/ludo-name/${id?.id}`);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateHoldGameApi = async () => {
  try {
    const data = await axiosConfig.post(`admin/update-challenge`);
    console.log(data);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
