import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import CircularLoading from "../../atoms/CircularLoading";
import { sellWalletApi, sellBankApi } from "../../../../../apis/wallet";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { automaticLogoutRequest } from "../../../../../redux/actions/auth";

const SellConfirm = ({
  is_open,
  setIsOpen,
  activeTab,
  bankInfo,
  setBanInfoState,
  state,
  setState,
  initialStateForBank,
  initialState,
  setActiveTab,
  setAddNewBank,
  setActiveIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    if (is_open) {
      setIsOpen(false);
    }
    setBanInfoState(initialStateForBank);
    setState(initialState);
    if (setActiveIndex) {
      setActiveIndex(null);
    }
  };

  const sellApi = async () => {
    setIsLoading(true);
    let data = null;
    if (activeTab === 2) {
      data = await sellWalletApi({
        payload: {
          accountNumber: bankInfo.accountNumber,
          amount: Number(bankInfo.amount),
        },
      });
    } else if (activeTab === 1) {
      data = await sellBankApi({
        payload: bankInfo,
      });
    }

    if (data.status == 200) {
      toast.success("Withdraw successfull");
      dispatch({ type: "GET_BANKS", payload: data.data.data });
      setIsLoading(false);
      handleClose();
      setActiveTab(0);
      setAddNewBank(false);
      setActiveIndex(null);
    } else if (data.status == 400) {
      if (data.error === "Please enter a correct UPI ID") {
        setIsLoading(false);
        setIsOpen(false);
        toast.error(data.error);
      } else {
        toast.error(data.error);
        setIsLoading(false);
        handleClose();
        setActiveTab(0);
        setAddNewBank(false);
        setActiveIndex(null);
      }
    } else if (data.status === 401) {
      dispatch(automaticLogoutRequest());
    }
  };

  return (
    <div className="partials">
      <Offcanvas
        style={{ height: "56%" }}
        show={is_open}
        onHide={() => {
          setIsOpen(false);
        }}
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {activeTab === 1
              ? "Confirm Your Bank Account"
              : "Confirm Your UPI ID"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className="text-center"
            style={{
              background: "yellow",
              width: "fit-content",
              margin: "0 auto",
              padding: "0.5rem",
              borderRadius: "10px",
            }}
          >
            <strong>
              <h1 className="m-0">{bankInfo.accountNumber}</h1>
            </strong>
          </div>
          {activeTab === 1 && (
            <div
              className="text-center"
              style={{
                background: "yellow",
                width: "fit-content",
                margin: "1rem auto",
                padding: "0.5rem",
                borderRadius: "10px",
              }}
            >
              <strong>
                <h2 className="m-0">{bankInfo.IFSCCode}</h2>
              </strong>
            </div>
          )}
          <p className="my-4">
            Please confirm that you have entered Correct{" "}
            {activeTab === 1 ? " Bank Account" : " UPI ID"} in Details In case
            you enter wrong {activeTab === 1 ? " Bank Account" : " UPI ID"}{" "}
            while filling details, we are not responsible for any refunds.
          </p>
          <div className="d-flex flex-column align-content-stretch">
            <button
              disabled={isLoading}
              onClick={sellApi}
              className="btn btn-success btn-lg mb-3"
            >
              {isLoading ? (
                <CircularLoading
                  height={"1.5rem"}
                  width={"1.5rem"}
                  color={"white"}
                />
              ) : (
                "Yes, I Confirm"
              )}
            </button>
            <button
              onClick={handleClose}
              className="btn btn-danger btn-lg mb-3"
            >
              No
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SellConfirm;
