// socketReducer.js
const initialState = {
  socket: null,
};

export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SOCKET":
      return {
        ...state,
        socket: action.payload,
      };
    case "CLEAR_SOCKET":
      // Disconnect the socket if it's currently connected
      if (state.socket && state.socket.connected) {
        // state.socket.close();
        state.socket.disconnect();
      }
      return {
        ...state,
        socket: null,
      };
    default:
      return state;
  }
};
