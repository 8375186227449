import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DepositCanvas from "../../components/atoms/DepositCanvas";
import CircularLoading from "../../components/atoms/CircularLoading";
import { convertToDeposit } from "../../../../apis/wallet";
import { toast } from "react-toastify";

export default function WithDraw() {
  const { data: wallet } = useSelector((state) => state.wallet);
  const [is_open, setIsOpen] = useState(false);
  const { ConvertChips } = useSelector((state) => state.settingReducer);
  console.log("cehckkk", ConvertChips);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loginReducer);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const handleAmountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      if (value >= 50 && value <= 100000) {
        setAmount(value);
        setIsDisable(false);
      } else if (value < 50) {
        setAmount(value);
        setIsDisable(true);
      } else if (value > 100000) {
        setAmount(100000);
      }
    } else {
      setAmount("");
    }
  };
  const handleSubmit = async () => {
    const datas = {
      amount: amount,
    };
    try {
      setLoading(true);
      const res = await convertToDeposit(datas);
      console.log("checkkconvert", res);
      console.log("checkkconver23t", res.data.status);

      setIsOpen(false);
      if (res?.data?.status === 200) {
        toast.success("successfully converted");
        setAmount();
        setIsOpen(false);
      } else if (res?.status === 400) {
        toast.error(res.error);
      }

      setLoading(false);
      setAmount(0);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          winning chips
        </div>
        <div className="card-body">
          <div
            className="fade alert alert-primary show"
            style={{ fontSize: "0.8rem" }}
          >
            यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank या UPI
            में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता है
          </div>
          <div className="d-flex align-items-center justify-content-center px-2">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <span
                className="text-capitalize fw-bold"
                style={{ fontSize: "0.8rem" }}
              >
                Chips
              </span>
              <span className="fs-4">
                {wallet?.winningCash?.toFixed(
                  wallet?.winningCash % 1 !== 0 ? 2 : 0
                )}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-stretch pt-4">
            <div className="text-decoration-none d-grid">
              <Link
                to="/sell"
                className="btn btn-primary btn-lg text-capitalize mb-2"
              >
                withdraw
              </Link>
            </div>
            {!ConvertChips && (
              <div className="text-decoration-none d-grid">
                <Link
                  onClick={() => setIsOpen(true)}
                  className="btn btn-primary bg-success border-0 btn-lg text-capitalize mb-2"
                >
                  Convert To Deposit{" "}
                  <span
                    className="bg-white text-success"
                    style={{ borderRadius: "2rem", padding: ".3rem .8rem" }}
                  >
                    2% bonus
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <DepositCanvas
        is_open={is_open}
        setIsOpen={setIsOpen}
        title="Convert To Deposit (2% Cashback)"
      >
        <div
          className=" mb-3"
          style={{
            margin: "0 auto",
          }}
        >
          <div>
            <p style={{ textAlign: "left", marginBottom: "0px" }}>
              Enter Amount
            </p>
            <div className="input-group " style={{ width: "100%" }}>
              <span className="input-group-text bg-light text-dark">₹</span>
              <input
                onChange={handleAmountChange}
                value={amount}
                className="form-control"
                type="number"
                placeholder="Amount"
              ></input>
              <span
                onClick={() => setAmount(wallet?.wallet)}
                className="input-group-text bg-light text-dark"
                style={{ cursor: "pointer" }}
              >
                Use Max
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "12px" }}>Min: 50</p>
              <p style={{ fontSize: "12px" }}>Max: 1,00,000</p>
            </div>
          </div>
          <div className="shadow card">
            <div className="bg-light text-dark text-capitalize card-header">
              Summary
            </div>
            <div className="card-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderBottom: "1px solid #888",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: ".5rem",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>
                    Deposit Amount{" "}
                    <span
                      style={{
                        background: "#0d6efd4a",
                        borderRadius: "4px",
                        padding: "0rem .5rem",
                        color: "#2e85fb",
                      }}
                    >
                      A
                    </span>
                  </p>
                  <p style={{ marginBottom: "0px" }} className="text-success">
                    ₹ {amount ? Number(amount).toFixed(2) : 0.0}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: ".5rem",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>
                    <span>
                      +2% Cash Bonus{" "}
                      <img
                        src="	https://ludoplayers.com/static/media/pepeking.9d780f94071645c86f5e.png"
                        alt="pepeking"
                        width="32"
                      ></img>
                    </span>
                    <span
                      style={{
                        background: "#0d6efd4a",
                        borderRadius: "4px",
                        padding: "0rem .5rem",
                        color: "#2e85fb",
                      }}
                    >
                      B
                    </span>{" "}
                  </p>
                  <p style={{ marginBottom: "0px" }} className="text-success">
                    ₹ {Number((amount / 100) * 2).toFixed(2)}
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "left", margin: "1rem 0rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      fontWeight: "700",
                      fontSize: "14px",
                      margin: ".5rem 0rem",
                    }}
                  >
                    {" "}
                    Add To Wallet Balance (Total){" "}
                    <span
                      style={{
                        background: "#0d6efd4a",
                        borderRadius: "4px",
                        padding: ".2rem .5rem",
                        color: "#2e85fb",
                      }}
                    >
                      A
                    </span>{" "}
                    +{" "}
                    <span
                      style={{
                        background: "#0d6efd4a",
                        borderRadius: "4px",
                        padding: ".2rem .5rem",
                        color: "#2e85fb",
                      }}
                    >
                      B
                    </span>
                  </p>
                  <p style={{ marginBottom: "0px" }} className="text-success">
                    ₹ {Number(amount + (amount / 100) * 2).toFixed(2)}
                  </p>
                </div>
                <div
                  style={{
                    background: "#0d6efd4a",
                    padding: "1rem 1rem",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>
                    आपकी Winning Chips को 2% Bonus के साथ Deposite Chips में डाल
                    दिया जायेगा जिससे आप सिर्फ़ गेम खेल सकते है ! ध्यान रहे की
                    आप फिर से बिना गेम खेले Deposites को Winning में नहीं बदल
                    सकते है *
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            disabled={isDisable || loading}
            className="btn btn-primary"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            {loading ? (
              <CircularLoading
                height={"1.5rem"}
                width={"1.5rem"}
                color={"white"}
              />
            ) : (
              "Convert"
            )}
          </button>
        </div>
      </DepositCanvas>
    </>
  );
}
