import React, { useState, useEffect } from "react";
import UpiSell from "./UpiSell";
import SavedUpis from "./SavedUpis";
import { BsArrowLeftShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import Closed from "./Closed";

const UpiInfo = ({
  setState,
  state,
  upiValidation,
  banks,
  bankInfo,
  setBanInfoState,
  activeTab,
  setActiveTab,
  activeIndex,
  setActiveIndex,
  setAddNewBank,
  setIsOpen,
}) => {
  const { upisellChips } = useSelector((state) => state.settingReducer);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [UPIWithDrawOpen, setIsUPIWithDrawOpen] = useState(null);

  // console.log("sellchips", sellChips);
  useEffect(() => {
    if (banks.length === 0) {
      setIsUPIWithDrawOpen(1);
    } else {
      setIsUPIWithDrawOpen(2);
    }
  }, [banks]);

  return (
    <>
      {!upisellChips ? (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-success mb-2 btn-sm"
              onClick={() => {
                setActiveTab(0);
                setAddNewBank(false);
                setActiveIndex(null);
              }}
            >
              <BsArrowLeftShort className="me-1" />
              Back
            </button>
            {UPIWithDrawOpen === 2 ? (
              <button
                className="btn btn-danger mb-2 btn-sm"
                onClick={() => {
                  setIsConfirmDelete(true);
                }}
              >
                Delete
              </button>
            ) : null}
          </div>
          {UPIWithDrawOpen === 1 && (
            <UpiSell
              setBanInfoState={setBanInfoState}
              bankInfo={bankInfo}
              upiValidation={upiValidation}
            />
          )}

          <>
            {UPIWithDrawOpen === 2 && banks.length > 0 && (
              <SavedUpis
                setActiveTab={setActiveTab}
                setAddNewBank={setAddNewBank}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                isConfirmDelete={isConfirmDelete}
                setIsConfirmDelete={setIsConfirmDelete}
                setIsBankWithDrawOpen={setIsUPIWithDrawOpen}
              />
            )}
          </>
        </div>
      ) : (
        <Closed chips={"Upi Withdraw Chips"} />
      )}
    </>
  );
};

export default UpiInfo;
