import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./styles.css";

const DepositCanvas = ({ is_open, setIsOpen, title, children }) => {
  return (
    <Offcanvas
      style={{ height: "auto", overflow: "auto" }}
      show={is_open}
      onHide={() => {
        setIsOpen(false);
      }}
      placement="bottom"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default DepositCanvas;
