import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  TextField,
  InputAdornment,
  Stack,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../state/api";
import { updateDiamonds, updateUser } from "../../../apis/dashboard";

const Cards = ({ data }) => {
  const [wallet, setWallet] = useState("");
  const [diamonds, setDiamonds] = useState("");
  const [penalty, setPenalty] = useState("");
  const [editedUser, setEditedUser] = useState(null);
  const [penaltyLoading, setPenaltyLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [depositCash, setDepositCash] = useState(false);
  const [depositDiamonds, setDepositDiamonds] = useState(false);
  const [winningCash, setWinningCash] = useState(false);
  const [winningDiamonds, setWinningDiamonds] = useState(false);
  console.log("asdasd", data);

  const handleWalletChange = (event) => {
    const updatedWallet = event.target.value;
    setWallet(updatedWallet);
    setEditedUser((prevUser) => ({
      ...prevUser,
      wallet: updatedWallet,
    }));
  };
  const handleDiamondsWalletChange = (event) => {
    const updatedDiamondsWallet = event.target.value;
    setDiamonds(updatedDiamondsWallet);
    setEditedUser((prevUser) => ({
      ...prevUser,
      diamonds: updatedDiamondsWallet,
    }));
  };

  const handleSaveUserDiamondsWallet = async (e) => {
    e.preventDefault();
    if (!depositDiamonds && !winningDiamonds) {
      return toast.error(`select one check box`);
    }

    setWalletLoading(true);
    const obj = {
      id: data.id,
      editedUser: editedUser,
      winningDiamonds: winningDiamonds,
      depositDiamonds: depositDiamonds,
    };
    const { data: response } = await updateDiamonds(obj);
    console.log("checkkresponse", response);

    if (response && response.status == 200) {
      toast.success(response.message);
    } else {
      toast.error("Request failed");
    }
    setDepositDiamonds(false);
    setWinningDiamonds(false);
    setDiamonds("");
    setWalletLoading(false);
  };
  const handleSaveUserWallet = async (e) => {
    e.preventDefault();
    if (!depositCash && !winningCash) {
      return toast.error(`select one check box`);
    }

    setWalletLoading(true);
    const obj = {
      id: data.id,
      editedUser: editedUser,
      winningCash: winningCash,
      depositCash: depositCash,
    };
    const { data: response } = await updateUser(obj);
    console.log("checkkresponse", response);

    if (response && response.status == 200) {
      toast.success(response.message);
    } else {
      toast.error("Request failed");
    }
    setDepositCash(false);
    setWinningCash(false);
    setWallet("");
    setWalletLoading(false);
  };
  const handlePenaltyWallet = async (e) => {
    if (!penalty) {
      return toast.error(`select amount`);
    }

    setPenaltyLoading(true);
    const { data: response } = await updateUser({
      id: data.id,
      penaltyAmount: Number(penalty),
      penalty: true,
    });
    if (response && response.status === 200) {
      toast.success(response.message);
    } else {
      toast.error("Request failed");
    }
    setPenalty("");
    setPenaltyLoading(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            User Details
          </Typography>
          <Divider />
          <Box style={{ marginTop: "10px" }}>
            <Typography>username : {data.name}</Typography>
            <Typography>name : {data.name}</Typography>
            <Typography>phone : {data.phone}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "1rem" }}>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            Wallet Details
          </Typography>
          <Divider />

          <Box style={{ marginTop: "10px" }}>
            <Typography>Total Wallet: {data.totalWallet}</Typography>
            <Typography>Deposit Cash: {data.depositCash}</Typography>
            <Typography>Winning Cash: {data.winningCash}</Typography>
            <Typography>Total Diamonds: {data.totalDiamonds}</Typography>
            <Typography>Deposit Diamonds: {data.depositDiamonds}</Typography>
            <Typography>Winning Diamonds: {data.winningDiamonds}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "1rem" }}>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            Update Wallet
          </Typography>
          <Divider />
          <Box
            component="form"
            onSubmit={(e) => handleSaveUserWallet(e)}
            sx={{ mt: 1, maxWidth: "400px" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon
                      sx={{
                        color: "#212529",
                        width: "1.1em",
                        height: "1.1em",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              required
              id="wallet"
              variant="outlined"
              type="number"
              value={wallet}
              placeholder="Enter Amount"
              onChange={handleWalletChange}
              margin="normal"
              backgroundcolor="#fff"
              bordercolor="#212529"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "20px 10px" },
                "> .MuiInputBase-root.Mui-focused": {
                  border: "1px solid #212529",
                },
              }}
            />
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root.Mui-checked": {
                    color: "#00000099",
                  },
                }}
                checked={depositCash}
                onChange={(e) => setDepositCash(e.target.checked)}
              />
              <Typography variant="body1" gutterBottom>
                Deposit Cash
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root.Mui-checked": {
                    color: "#00000099",
                  },
                }}
                checked={winningCash}
                onChange={(e) => setWinningCash(e.target.checked)}
              />
              <Typography variant="body1" gutterBottom>
                Winning Cash
              </Typography>
            </Stack>
            <LoadingButton
              loading={walletLoading}
              disabled={wallet?.length === 0}
              type="submit"
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#212529",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#343a40",
                },
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "1rem" }}>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            Update Diamonds Wallet
          </Typography>
          <Divider />
          <Box
            component="form"
            onSubmit={(e) => handleSaveUserDiamondsWallet(e)}
            sx={{ mt: 1, maxWidth: "400px" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon
                      sx={{
                        color: "#212529",
                        width: "1.1em",
                        height: "1.1em",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              required
              id="wallet"
              variant="outlined"
              type="number"
              value={diamonds}
              placeholder="Enter Amount"
              onChange={handleDiamondsWalletChange}
              margin="normal"
              backgroundcolor="#fff"
              bordercolor="#212529"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "20px 10px" },
                "> .MuiInputBase-root.Mui-focused": {
                  border: "1px solid #212529",
                },
              }}
            />
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root.Mui-checked": {
                    color: "#00000099",
                  },
                }}
                checked={depositDiamonds}
                onChange={(e) => setDepositDiamonds(e.target.checked)}
              />
              <Typography variant="body1" gutterBottom>
                Deposit Diamonds
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root.Mui-checked": {
                    color: "#00000099",
                  },
                }}
                checked={winningDiamonds}
                onChange={(e) => setWinningDiamonds(e.target.checked)}
              />
              <Typography variant="body1" gutterBottom>
                Winning Diamonds
              </Typography>
            </Stack>
            <LoadingButton
              loading={walletLoading}
              disabled={diamonds?.length === 0}
              type="submit"
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#212529",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#343a40",
                },
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "1rem" }}>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            Add Penalty(oh yeah)
          </Typography>
          <Divider />
          <Box
            component="form"
            onSubmit={(e) => handlePenaltyWallet(e)}
            sx={{
              mt: 1,
              maxWidth: "400px",
              margin: "0rem auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon
                      sx={{
                        color: "#212529",
                        width: "1.1em",
                        height: "1.1em",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              required
              id="wallet"
              variant="outlined"
              type="number"
              value={penalty}
              placeholder="Enter Penalty Amount"
              onChange={(event) => setPenalty(event.target.value)}
              margin="normal"
              backgroundcolor="#fff"
              bordercolor="#212529"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "20px 10px" },
                "> .MuiInputBase-root.Mui-focused": {
                  border: "1px solid #212529",
                },
              }}
            />
            <LoadingButton
              loading={penaltyLoading}
              disabled={penaltyLoading}
              onClick={handlePenaltyWallet}
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#212529",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#343a40",
                },
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Cards;
