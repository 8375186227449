import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Card,
  Typography,
  Pagination,
} from '@mui/material';

import { toast } from 'react-toastify';
import {
  useGetSuccessRequestsQuery,
  useGetSearchSuccessRequestsQuery,
  useUpdateStateMutation,
} from '../../state/api';
import { Header, SearchBox } from '../../components';

const SuccessWithdrawal = () => {
  // values for backend
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [result, setResult] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [loading, setLoading] = useState(true);

  // get data
  const { data, isLoading, refetch } = useGetSuccessRequestsQuery(page);
  const {
    data: SearchData,
    isLoading: isSearchLoading,
    refetch: searchRefetch,
  } = useGetSearchSuccessRequestsQuery(search);
  //pagination
  const totalItems = data?.total || 0;
  console.log('checkktaot', data);
  const totalPages = Math.ceil(totalItems / 12);
  console.log('checkktotalpages', totalPages);
  useEffect(() => {
    if (search != '') {
      searchRefetch(search);
      setResult(SearchData?.transactions);
    } else {
      setResult(data?.transactions);
    }
  }, [search]);
  useEffect(() => {
    setLoading(isLoading || isSearchLoading);
    if (SearchData) {
      setResult(SearchData?.transactions);
    } else {
      setResult(data?.transactions);
    }
  }, [data, SearchData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  //search
  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setSearch(searchTerm);
        searchRefetch({ search: searchTerm });
      }, 800),
    );
  };

  return (
    <Box
      p={{ xs: '1.5rem 1rem', md: '1.5rem 2.5rem' }}
      pt={{ xs: '5rem', lg: '5rem' }}
      height={'100%'}
    >
      {/* Header */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Header
          title="Successful Withdrawals"
          refetch={refetch}
          isLoading={loading}
        />
        <SearchBox
          handleOnchange={handleSearchInputChange}
          placeholder={'Search by Username or Phone'}
        />
      </Box>
      <Box pt="20px" height={'100%'}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          height={'100%'}
        >
          {!loading && result && result.length > 0 ? (
            result.map((transaction, index) => (
              <Grid item xs={12} md={6} xl={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: '#FAFAFA',
                    padding: '0.6rem 0.5rem',
                    height: '130px',
                  }}
                >
                  <Box
                    gridColumn="span 2"
                    gridRow="span 1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                    border={1}
                    borderColor={'#e0e0e0'}
                    p={{ xs: '0.4rem', md: '0.4rem 1rem' }}
                    flex="1 1 100%"
                    backgroundColor={'#fff'}
                    borderRadius="0.55rem"
                    height={'100%'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                      height={'100%'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        width={'100%'}
                        height={'100%'}
                      >
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            border={1}
                            minWidth={'150px'}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            UPI
                          </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            minWidth={'150px'}
                            border={1}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            Amount:{' '}
                            <span style={{ color: 'green' }}>
                              Rs{transaction?.amount}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent="space-around"
                      height={'100%'}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="600"
                        padding={'0.3rem'}
                        border={1}
                        textAlign={'center'}
                        sx={{
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          borderColor: '#e0e0e0',
                        }}
                      >
                        {transaction?.bank?.accountNumber}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'green',
                          color: '#ffff',
                        }}
                      >
                        {transaction?.status === 0
                          ? 'Decline'
                          : transaction?.status === 1
                            ? 'Success'
                            : transaction?.status === 2
                              ? 'Pending'
                              : null}
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={'100%'}
            >
              {result && result.length == 0 ? (
                search ? (
                  <Typography variant="h4">
                    No Successful withdrawal found
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    No Successful withdrawal found.
                  </Typography>
                )
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Box>
          )}
          {!search && (
            <Box
              my={2}
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'end'}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                color="secondary"
              />
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SuccessWithdrawal;
