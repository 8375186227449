export const indianUsernames = [
  "Aarav",
  "Aditi",
  "Amit",
  "Anaya",
  "Bhavya",
  "Chirag",
  "Deepak",
  "Divya",
  "Gaurav",
  "Isha",
  "Kunal",
  "Lakshmi",
  "Manoj",
  "Mira",
  "Neha",
  "Nikhil",
  "Pooja",
  "Pranav",
  "Radha",
  "Rahul",
  "Rani",
  "Rishi",
  "Riya",
  "Sagar",
  "Shreya",
  "Suman",
  "Sunil",
  "Surya",
  "Tanvi",
  "Uma",
  "Varun",
  "Vidya",
  "Yash",
  "Zara",
  "Aarushi",
  "Alok",
  "Anjali",
  "Arjun",
  "Bhavna",
  "Chetan",
  "Deepika",
  "Dev",
  "Garima",
  "Harish",
  "Indira",
  "Kabir",
  "Kavya",
  "Lalit",
  "Madhuri",
  "Mohan",
  "Nandini",
  "Nitin",
  "Preeti",
  "Raj",
  "Rajesh",
  "Roshni",
  "Sandeep",
  "Shikha",
  "Sushant",
  "Swati",
  "Uday",
  "Vijay",
  "Vrinda",
  "Yogesh",
  "Zoya",
  "Aarohi",
  "Anand",
  "Ananya",
  "Arnav",
  "Bharti",
  "Chandni",
  "Dhruv",
  "Geeta",
  "Hari",
  "Ishita",
  "Jai",
  "Karishma",
  "Krishna",
  "Lata",
  "Manju",
  "Mukesh",
  "Nandini",
  "Nisha",
  "Pradeep",
  "Rajiv",
  "Rani",
  "Ritika",
  "Sanjay",
  "Sheetal",
  "Subhash",
  "Sunita",
  "Tarun",
  "Urmila",
  "Vandana",
  "Yogita",
  "Zeenat",
];
