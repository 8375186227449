import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Amount, fee } from "../../functions/functions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function BankWithDraw({ setBanInfoState, bankInfo, setIsOpen }) {
  const { data } = useSelector((state) => state.wallet);
  const { data: userData } = useSelector((state) => state.user);

  const [isPasswordField, setIsPasswordField] = useState(false);
  const handleInputCopy = (e) => {
    e.preventDefault(); // Prevent the copy action
  };

  const bankValidation = () => {
    if (data.winningCash < bankInfo.amount) {
      return toast.error("total winning amount less");
    }
    if (bankInfo.amount < 195) {
      return toast.error("Amount should be greater than 195");
    } else if (bankInfo.amount > 100000) {
      return toast.error("Amount should be less than 100000");
    }
    const firstThreeCharacters = bankInfo?.IFSCCode.substring(0, 3);
    if (!bankInfo.accountNumber) {
      toast.error("Account number is required");
    } else if (bankInfo.accountNumber !== bankInfo.confirmBankAccount) {
      toast.error("Account Numbers Do Not Match");
    } else if (!bankInfo.amount) {
      toast.error("amount is required");
    } else if (!bankInfo.IFSCCode) {
      toast.error("IFSC Code is required");
    } else if (bankInfo.IFSCCode.length !== 11) {
      toast.error("IFSC Code should be 11 characters");
    } else if (!/^[A-Za-z]+$/.test(firstThreeCharacters)) {
      toast.error("First three characters of IFSC Code should be alphabets");
    } else if (!userData?.kycVerified) {
      toast.error("Complete Your Kyc");
    } else {
      setIsOpen(true);
    }
  };

  return (
    <Accordion.Body className="p-0">
      <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          Bank Account Details
        </div>

        <div className="card-body">
          <div className="vstack gap-3 minBreakpoint-xs">
            <div>
              <label className="text-capitalize text-start w-100 form-label">
                Bank Account Number
              </label>
              <input
                style={{ userSelect: "none" }}
                onCopy={handleInputCopy}
                onContextMenu={handleInputCopy}
                name="accountNumber"
                type={isPasswordField ? "password" : "number"}
                onFocus={() => {
                  setIsPasswordField(false);
                }}
                onBlur={() => {
                  setIsPasswordField(true);
                }}
                onInput={(e) => {
                  // Filter out non-digit characters using a regular expression
                  const inputValue = e.target.value;
                  const cleanValue = inputValue.replace(/[^0-9]/g, "");
                  e.target.value = cleanValue;
                  setBanInfoState({
                    ...bankInfo,
                    accountNumber: cleanValue,
                  });
                }}
                placeholder="Your Bank Account Number"
                className="form-control form-control"
                value={bankInfo.accountNumber}
              />
            </div>
            <div>
              <label className="text-capitalize text-start w-100 form-label">
                Confirm Bank Account Number
              </label>
              <input
                name="confirmAccountNumber"
                type="number"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const cleanValue = inputValue.replace(/[^0-9]/g, "");
                  e.target.value = cleanValue;
                  setBanInfoState({
                    ...bankInfo,
                    confirmBankAccount: cleanValue,
                  });
                }}
                placeholder="Your Bank Account Number"
                className="form-control form-control"
                value={bankInfo.confirmBankAccount}
              ></input>
            </div>
            <div>
              <label className="text-capitalize text-start w-100 form-label">
                IFSC Code
              </label>
              <input
                name="IFSCCode"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  ); // Remove invalid characters

                  // Limit the input to a maximum of 11 characters
                  const truncatedValue = sanitizedValue.slice(0, 11);

                  setBanInfoState({
                    ...bankInfo,
                    IFSCCode: truncatedValue.toUpperCase(), // Convert to uppercase
                  });
                }}
                placeholder="IFSC Code"
                className="form-control form-control"
                value={bankInfo.IFSCCode}
              ></input>
            </div>
            <div>
              <label className="text-capitalize text-start w-100 form-label">
                Chips{" "}
              </label>
              <input
                name="amount"
                onChange={(e) => {
                  setBanInfoState({
                    ...bankInfo,
                    amount: parseFloat(e.target.value),
                  });
                }}
                placeholder="Chips"
                type="number"
                className="form-control form-control"
                value={bankInfo.amount}
              ></input>
              <label
                style={{ marginTop: "10px" }}
                className="text-capitalize text-start w-100 form-label"
              >
                {/* <span style={{ color: "red" }}>Fees</span>: ₹{fee(bankInfo)}{" "} */}
                {/* Amount: ₹{Amount(bankInfo)} */}
              </label>
            </div>
            <p style={{ fontSize: "0.8rem" }}>
              By Continuing, you agree to our{" "}
              <Link to="/legal">Legal Terms</Link> and you are 18 years or
              older.
            </p>

            <button className="btn btn-primary" onClick={bankValidation}>
              Sell
            </button>
          </div>
        </div>
      </div>
    </Accordion.Body>
  );
}
