import { useState } from "react";
import PropTypes from "prop-types";
import React from "react";

import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { alpha, Avatar, Switch } from "@mui/material";
import { useUpdateCustomerMutation } from "../../state/api";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { pink } from "@mui/material/colors";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { DASHBOARD } from "../../../redux/contstants";

// ----------------------------------------------------------------------
const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme?.palette?.action?.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export default function UserTableRow({
  selected,
  user,
  handleClick,
  setRefetch,
  refetch,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const label = { inputProps: { "aria-label": "Block User" } };
  const handleChange = (event, params) => {
    event.preventDefault();
    const updatedUser = { ...params, blocked: !params.isBlocked };
    // Perform update user action
    try {
      const res = dispatch({
        type: DASHBOARD.UPDATE_CUSTOMERS_REQUEST,
        body: updatedUser,
      });
      console.log(res);

      if (res) {
        toast.success("Updated Successfully");
        setRefetch(!refetch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell component="th" scope="row" sx={{ padding: "10px" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              alt={name}
              src={`https://ludo3.s3.ap-south-1.amazonaws.com/avtar/${user.profileImage}`}
            />
            <Typography noWrap>
              <Link
                to={`/admin/details/${user._id}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer",
                  "&:hover": {
                    color: "green",
                  },
                }}
                state={user._id}
              >
                {user?.username}
              </Link>
            </Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ padding: "10px" }}>
          {" "}
          <Typography
            variant="h6"
            fontWeight="600"
            sx={{ marginRight: "10px", fontSize: "14px" }}
          >
            Number -
          </Typography>
          <Typography sx={{ fontSize: "14px" }} fontWeight="600" color="green">
            {user.phone}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: "10px" }}>
          <PinkSwitch
            {...label}
            checked={user.isBlocked}
            defaultChecked
            color="default"
            onChange={() => handleChange(event, user)}
          />
        </TableCell>

        {/* <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell> */}
      </TableRow>

      {/* <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        {state === 'hold' && (
          <MenuItem
            sx={{
              bgcolor: 'green',
              color: '#fff',
              ':hover': { bgcolor: '#fff', color: 'green' },
            }}
            onClick={() => handleEditUser(transaction)}
          >
            Settle
          </MenuItem>
        )}
        {state === 'hold' &&
          transaction.ludoking?.result &&
          typeof transaction.ludoking?.result == 'object' && (
            <MenuItem
              onClick={() => handleResultModal(transaction)}
              sx={{
                bgcolor: 'red',
                color: '#fff',
                ':hover': { bgcolor: '#fff', color: 'red' },
              }}
            >
              Result
            </MenuItem>
          )}
      </Popover> */}
    </>
  );
}

UserTableRow.propTypes = {
  handleClick: PropTypes.any,
  user: PropTypes.any,
};
