import Cookies from "js-cookie";
import { axiosConfig } from "./axiosConfig";
import axios from "axios";
import { toast } from "react-toastify";

export const buyWalletApi = async (data) => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";
  try {
    const res = await axiosConfig.post(`/transaction/buy`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const verifyBuyWalletApi = async (data) => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";
  try {
    const res = await axiosConfig.post(`/transaction/verify-vpa`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const indicBuyApi = async (data) => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";

  try {
    const res = await axios.post(
      `https://testapiadmin.gotiking.com/client/indic-pay`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const sellWalletApi = async (data) => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";

  try {
    const res = await axiosConfig.post(`/transaction/upi-sell`, data.payload, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const convertToDeposit = async (data) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.post(`/user/chipsConvert `, data, {
      headers: {
        Authorization: token,
      },
    });

    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const sellBankApi = async (data) => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.post(`/transaction/bank-sell`, data.payload, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteBankApi = async (data) => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.post(`/transaction/bank-delete`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteUpiApi = async (data) => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.post(`/transaction/upi-delete`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getBankAccounts = async () => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.get(`/user/AllBanks`, {
      headers: {
        Authorization: token,
      },
    });

    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllUpis = async () => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.get(`/user/AllUpids`, {
      headers: {
        Authorization: token,
      },
    });

    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getUpiBankAccounts = async () => {
  const token = Cookies.get("token") || ""; // Simplify the token retrieval

  if (!token) {
    // Check if token is undefined or null
    toast.error("Cannot send request");
    return; // Stop further execution
  }

  try {
    const res = await axiosConfig.get(`/user/AllUpiBanks`, {
      headers: {
        Authorization: token,
      },
    });

    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getWalletApi = async () => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";

  try {
    const res = await axiosConfig.get(`/transaction/wallet`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUPIID = async (id) => {
  const token =
    Cookies.get("token") !== undefined || Cookies.get("token") !== null
      ? Cookies.get("token")
      : "";

  try {
    const res = await axiosConfig.get(`/user/delete/upids/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
