export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const year = dateObject.getFullYear();
  const monthName = months[dateObject.getMonth()];
  const day = dateObject.getDate();

  return ` ${day}th-${monthName}-${year}`;
};

export const formatDateForHistory = (dateString) => {
  const dateObject = new Date(dateString);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const year = dateObject.getFullYear();
  const monthName = months[dateObject.getMonth()];
  const day = dateObject.getDate();

  return ` ${day} ${monthName} `;
};

export const formatToTime = (date) => {
  if (!date) {
    return ''; // Handle the case when roomCodeSetAt is not provided or is falsy
  }

  const formattedTime = new Date(date).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  return formattedTime;
};
export const wrTime = (date) => {
  if (!date) {
    return ''; // Handle the case when date is not provided or is falsy
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const formattedDateTime = new Date(date).toLocaleString([], options);

  return formattedDateTime;
};
export const extractFileNameFromUrl = (imageUrl) => {
  try {
    const url = new URL(imageUrl);
    const pathArray = url.pathname.split('/');
    const fileName = pathArray[pathArray.length - 1];
    return fileName;
  } catch (error) {
    console.error('Error extracting file name:', error);
    return null;
  }
};
export function convertTimeToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
}

export function getTimeDifference(editGame) {
  console.log('cehckeditgame', editGame);
  const creatorUpdatedAt = new Date(editGame?.results?.creator?.updatedAt);
  const playerUpdatedAt = new Date(editGame?.results?.player?.updatedAt);
  const roomCodeSetAt = new Date(editGame?.roomCodeSetAt);

  let timeDifference;

  if (creatorUpdatedAt > playerUpdatedAt) {
    // Calculate time difference in milliseconds
    timeDifference = creatorUpdatedAt - roomCodeSetAt;
  } else if (playerUpdatedAt > creatorUpdatedAt) {
    // Calculate time difference in milliseconds
    timeDifference = playerUpdatedAt - roomCodeSetAt;
  } else {
    return 'N/A'; // or any other value that makes sense in your context
  }

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return `${hours}:h ${minutes}:m ${seconds}:s`;
}
