const CryptoJS = require("crypto-js");
const {
  emit,
  on,
  off,
  removeEventListener,
  removeListener,
} = require("./symbol");
const reservedEvents = require("./reserved-events");

module.exports = (secret) => (socket, next) => {
  const handlers = new WeakMap();

  const encryptArgs = (args, ack) => {
    const encryptedArgs = args
      .map((arg) => {
        if (typeof arg === "function") {
          ack = arg;
          return undefined; // We'll remove functions from the args array
        }
        return CryptoJS.AES.encrypt(JSON.stringify(arg), secret).toString();
      })
      .filter((arg) => arg !== undefined);

    return { encryptedArgs, ack };
  };

  const decryptArgs = (encryptedArgs) => {
    return encryptedArgs.map((a) =>
      JSON.parse(CryptoJS.AES.decrypt(a, secret).toString(CryptoJS.enc.Utf8))
    );
  };

  socket[emit] = socket.emit;
  socket[on] = socket.on;
  socket[off] = socket.off;
  socket[removeEventListener] = socket.removeEventListener;
  socket[removeListener] = socket.removeListener;

  socket.emit = (event, ...args) => {
    if (reservedEvents.includes(event)) return socket[emit](event, ...args);

    const { encryptedArgs, ack } = encryptArgs(args);
    if (encryptedArgs.length === 0) return socket[emit](event, ack);

    return socket[emit](event, { encryptedArgs, ack });
  };

  socket.on = (event, handler) => {
    if (reservedEvents.includes(event)) return socket[on](event, handler);

    const newHandler = function (...args) {
      if (args.length === 1 && args[0] && args[0].encryptedArgs) {
        try {
          args = decryptArgs(args[0].encryptedArgs);
        } catch (error) {
          socket[emit]("error", error);
          return;
        }
      }
      return handler.call(this, ...args);
    };

    handlers.set(handler, newHandler);
    return socket[on](event, newHandler);
  };

  socket.off = (event, handler) => {
    if (reservedEvents.includes(event)) return socket[off](event, handler);

    const properHandler = handlers.get(handler);
    if (properHandler) {
      handlers.delete(handler);
      return socket[off](event, properHandler);
    }

    return socket[off](event, handler);
  };

  socket.removeEventListener = (event, handler) => {
    return socket.off(event, handler);
  };

  socket.removeListener = (event, handler) => {
    return socket.off(event, handler);
  };

  if (next) next();
  return socket;
};
