import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import KycDetails from "../../../pages/kyc/KycDetails";
import { useDispatch, useSelector } from "react-redux";
import { getKycDetailsApi } from "../../../../../apis/user";
import { toast } from "react-toastify";

const Icon = ({ status }) => {
  return !status ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="red"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="green"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
    </svg>
  );
};

const KycAlert = () => {
  const dispatch = useDispatch();
  const [f_open, setOpen] = useState(false);
  const { data: userData } = useSelector((state) => state.user);
  const { data: kycData } = useSelector((state) => state.kycReducer);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 310);
  const viewKycHandler = async () => {
    const data = await getKycDetailsApi();

    if (data.status == 200) {

      dispatch({ type: "GET_KYC_DETAILS", payload: data.data.data });

    } else if (data.status == 400) {
    }
    setOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 380);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <KycDetails f_open={f_open} setOpen={setOpen} />
      {!userData?.kycVerified ? (
        <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto px-3 pt-3 g-0">
          <Alert
            variant="danger"
            className={`d-flex align-items-center justify-content-between m-0 ${
              isSmallScreen ? "flex-column gap-2" : ""
            }`}
          >
            <span>
              <b>
                KYC Pending <Icon status={userData?.kycVerified} />
              </b>
            </span>
            <Link to={"/kyc"} className="btn btn-danger btn-sm text-capitalize">
              complete here
            </Link>
          </Alert>
        </div>
      ) : (
        <div
          className={
            "d-flex flex-row align-items-center justify-content-between p-3 card bg-outline text-dark " +
            (!userData?.kycVerified ? "border-danger" : "border-success")
          }
        >
          <div className="d-flex flex-column align-items-start justify-content-center">
            <span style={{ fontSize: "0.8rem" }}>KYC status</span>
            <span className="fw-bold text-capitalize">
              {!userData?.kycVerified ? "Pending" : "Verified"}{" "}
              <Icon status={userData?.kycVerified} />
            </span>
          </div>
          {!userData?.kycVerified ? (
            <Link
              to={"/kyc"}
              className={"btn btn-sm text-capitalize btn-outline-danger"}
            >
              complete KYC
            </Link>
          ) : (
            <button
              className="btn btn-outline-success"
              onClick={viewKycHandler}
            >
              View kyc detail
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default KycAlert;
