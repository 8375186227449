import React from "react";

import Offcanvas from "react-bootstrap/Offcanvas";
import "./styles.css";
import { toast } from "react-toastify";

const MultiplayerModel = ({ is_open, data, children, setIsOpen }) => {
  console.log(is_open);
  const handleChallenge = () => {
    toast.error(
      "Playing Challenge is Currently Disabled as Website is Under Maintenance. Please try again later "
    );
  };
  return (
    <Offcanvas
      style={{ height: "auto", maxWidth: "480px" }}
      show={is_open}
      onHide={() => {
        setIsOpen(false);
      }}
      placement="bottom"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="offcanvas-header"
      >
        <div className="offcanvas-title h5">Please Confirm Your Entry</div>
        <button
          onClick={() => setIsOpen(false)}
          type="button"
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="d-flex flex-column gap-1">
          <div className="d-flex justify-content-between">
            <strong className="d-flex gap-1 align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="20"
                height="20"
                fill="green"
              >
                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
              </svg>
              Entry Fee
            </strong>
            <span>₹ {data?.entryFee}</span>
          </div>
          <div className="d-flex justify-content-between">
            <strong className="d-flex gap-1 align-items-center">
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/trophy.png"
                alt="avatar"
                style={{ height: "20px", width: "20px" }}
              />
              Win Prize Pool
            </strong>
            <span>₹ {data?.prizePool}</span>
          </div>
          <div className="d-flex justify-content-between">
            <strong className="d-flex gap-1 align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="20"
                height="20"
                fill="currentColor"
              >
                <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z"></path>
                <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z"></path>
              </svg>
              Players
            </strong>
            <div className="d-flex gap-1 align-items-end">
              <small className="text-danger">(4 spots left)</small>
              <span>4 Players</span>{" "}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <strong className="d-flex gap-1 align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="20"
                height="20"
                fill="currentColor"
              >
                <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"></path>
              </svg>
              <span>Winner(s)</span>
            </strong>
            <span>1</span>
          </div>
          <hr />
          <div role="alert" className="fade mb-0 alert alert-warning show">
            <b>NOTE:</b> The Entry amount Can only be Refunded if the game has
            not started. Once the game has started, it cannot be Cancelled.
          </div>
          <hr />
          <button
            onClick={handleChallenge}
            type="button"
            className="fw-semibold py-3 btn btn-primary"
          >
            Join Now
          </button>
          <button type="button" className="colorCode btn btn-ghost">
            <small>Cancel</small>
          </button>
        </div>
      </div>
    </Offcanvas>
  );
};

export default MultiplayerModel;
