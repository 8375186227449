import React, { useState } from "react";
import { Accordion, Card, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { BsBank } from "react-icons/bs";
import { Amount, fee } from "../../functions/functions";
import { useDispatch, useSelector } from "react-redux";
import CircularLoading from "../../components/atoms/CircularLoading";
import SellConfirm from "../../components/appbar/components/SellConfirm";
import { deleteBankApi } from "../../../../apis/wallet";
import { useNavigate } from "react-router-dom";
import KycAlert from "../../components/appbar/components/KycAlert";

export default function SavedBanks({
  setActiveTab,
  setAddNewBank,
  activeIndex,
  setActiveIndex,
  isConfirmDelete,
  setIsConfirmDelete,
  setIsBankWithDrawOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialStateForBank = {
    accountNumber: "",
    IFSCCode: "",
    amount: "",
  };

  const initialState = {
    accountNumber: "",
    confirmBankAccount: "",
    amount: "",
  };

  const { data } = useSelector((state) => state.wallet);
  const { data: userData } = useSelector((state) => state.user);
  const { data: dataArray } = useSelector((state) => state.allBanksReducer);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [is_open, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const deleteBank = async () => {
    const data = await deleteBankApi(bankInfo);

    if (data.status == 200) {
      dispatch({ type: "GET_BANKS", payload: data.data.data });
      toast.success("delete successfull");
      setIsConfirmDelete(false);
      navigate("/wallet");
    } else if (data.status == 400) {
      toast.error(data.error);
      setIsConfirmDelete(false);
      navigate("/wallet");
    }
  };
  const handleClick = (index, item) => {
    setActiveIndex(index);
    setBanInfoState({
      ...bankInfo,
      accountNumber: item.accountNumber,
      IFSCCode: item.IFSCCode,
    });
  };

  const [bankInfo, setBanInfoState] = useState(initialStateForBank);

  const handleSubmit = async () => {
    if (data.winningCash < bankInfo.amount) {
      return toast.error("total winning amount less");
    }
    //for testing yess
    if (bankInfo.amount < 195) {
      return toast.error("Amount should be greater than 195");
    } else if (bankInfo.amount > 100000) {
      toast.error("Amount should be less than 100000");
    } else if (!userData?.kycVerified) {
      toast.error("Complete Your Kyc");
    } else {
      setIsOpen(true);
    }
  };

  function maskAccountNumber(accountNumber) {
    const firstTwoDigits = accountNumber.slice(0, 2);
    const lastThreeDigits = accountNumber.slice(-3); // Get the last three digits
    const hiddenDigits = firstTwoDigits + "x".repeat(accountNumber.length - 3); // Create 'xxx' of the same length as the hidden part

    // Combine the hidden part and the last three digits
    const maskedAccountNumber = hiddenDigits + lastThreeDigits;
    return maskedAccountNumber;
  }

  return (
    <>
      <SellConfirm
        is_open={is_open}
        setIsOpen={setIsOpen}
        state={state}
        setState={setState}
        activeTab={1}
        setActiveTab={setActiveTab}
        bankInfo={bankInfo}
        setBanInfoState={setBanInfoState}
        initialStateForBank={initialStateForBank}
        initialState={initialState}
        setAddNewBank={setAddNewBank}
        setActiveIndex={setActiveIndex}
      />
      <div className="partials">
        <Offcanvas
          show={isConfirmDelete}
          onHide={() => {
            setIsConfirmDelete(false);
          }}
          placement="bottom"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Are You sure you want to delete this bank account
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="d-flex flex-column align-content-stretch">
              <button
                onClick={() => {
                  deleteBank();
                }}
                className="btn btn-success btn-lg mb-3"
              >
                {false ? (
                  <CircularLoading
                    height={"1.5rem"}
                    width={"1.5rem"}
                    color={"white"}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                onClick={() => {
                  setIsConfirmDelete(false);
                }}
                className="btn btn-danger btn-lg mb-3"
              >
                No
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Accordion.Body className="p-0 text-center">
        <div className="shadow card">
          <div className="bg-light text-dark text-capitalize card-header">
            <span className="text-black" style={{ fontSize: "0. 8rem" }}>
              Saved Bank Accounts
            </span>
          </div>
          {dataArray?.map((item, index) => (
            <Card style={{ margin: "5px" }} key={index}>
              <Card.Body
                style={{ padding: "10px" }}
                onClick={() => handleClick(index, item)}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BsBank color="#212529" />
                      <span style={{ marginLeft: "10px", paddingTop: "2px" }}>
                        {maskAccountNumber(item.accountNumber)}
                      </span>
                    </div>
                    <input
                      type="radio"
                      name="selectedCard"
                      checked={activeIndex === index}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}

          {dataArray.length > 0 && (
            <div style={{ margin: "5px" }}>
              <input
                type="number"
                value={bankInfo.amount}
                onChange={(e) => {
                  setBanInfoState({
                    ...bankInfo,
                    amount: parseFloat(e.target.value),
                  });
                }}
                placeholder="Enter Amount"
                className="form-control form-control"
              />
              <label
                style={{ marginTop: "10px" }}
                className="text-capitalize text-start w-100 form-label"
              >
                {/* <span style={{ color: "red" }}>Fees</span>: ₹{fee(bankInfo)}{" "} */}
                {/* Amount: ₹{Amount(bankInfo)} */}
              </label>
              <button
                style={{ width: "100%", marginTop: "5px" }}
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={activeIndex === null}
              >
                Sell
              </button>
            </div>
          )}
        </div>

        <button
          className="btn btn-success mx-auto mt-4 mb-2 w-auto"
          onClick={() => {
            setActiveTab(1);
            setAddNewBank(true);
            setActiveIndex(2);
            setIsBankWithDrawOpen(1);
          }}
        >
          + Add New Account
        </button>
      </Accordion.Body>
    </>
  );
}
