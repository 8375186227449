import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Header, StaticFilter } from "../../components";
import { Grid } from "@mui/material";
import AppWidgetSummary from "./app-widget-summary";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../redux/contstants";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: DASHBOARD.GET_DASHBOARD_REQUEST });
  }, []);
  const data = useSelector((s) => s?.dashboardSlice);
  console.log(data);
  return (
    <Box
      p={{ xs: "1.5rem 1rem", md: "1.5rem 2.5rem" }}
      pt={{ xs: "5rem", lg: "5rem" }}
      height={"100%"}
    >
      <Header title="Statistics" />

      <StaticFilter />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Total Users"
            total={data?.dashboard?.users?.totalUsers || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Total Games"
            total={
              data?.dashboard?.challenges?.resolved?.length > 0
                ? data?.dashboard?.challenges?.resolved[0]?.totalCount
                : 0
            }
            amount={
              data?.dashboard?.challenges?.resolved?.length > 0
                ? data?.dashboard?.challenges?.resolved[0]?.totalSum
                : 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Hold Games"
            total={
              data?.dashboard?.challenges?.holdCount?.length > 0
                ? data?.dashboard?.challenges?.holdCount[0]?.count
                : 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Pending Withdrawals"
            total={
              data?.dashboard?.transactions?.pendingPayout?.totalCount || 0
            }
            amount={
              data?.dashboard?.transactions?.pendingPayout?.totalAmount || 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Total Deposit"
            total={
              data?.dashboard?.transactions?.successDeposits?.totalCount || 0
            }
            amount={
              data?.dashboard?.transactions?.successDeposits?.totalAmount || 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Success Withdrawals"
            total={
              data?.dashboard?.transactions?.successPayout?.totalCount || 0
            }
            amount={
              data?.dashboard?.transactions?.successPayout?.totalAmount || 0
            }
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Challenges Comission 5%"
            // total={
            //   data?.dashboard?.transactions?.successDeposits?.totalCount || 0
            // }
            amount={
              data?.dashboard?.challengeComission?.challengeComission || 0
            }
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Withdrawals Comission 3%"
            // total={
            //   data?.dashboard?.transactions?.successPayout?.totalCount || 0
            // }
            amount={data?.dashboard?.totalWithdraws?.totalAmount * 0.03 || 0}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Penalty Comission"
            // total={data?.dashboard?.challengeComission?.challengeComission || 0}
            amount={data?.dashboard?.accounts?.totalAmountPenalty || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Signup Bonus"
            total={data?.dashboard?.accounts?.totalAmountBonus || 0}
            amount={data?.dashboard?.accounts?.totalAmountBonus || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Total Refers"
            total={data?.dashboard?.users?.totalRefers || 0}
            amount={data?.dashboard?.accounts?.totalReferelBalance || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Commission"
            // total={data?.dashboard?.users?.totalRefers || 0}
            amount={data?.dashboard?.commission || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Running Challenges"
            total={data?.dashboard?.challenges?.playingCount[0]?.count || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Spin & Win"
            total={
              data?.dashboard?.games?.totalSpinCount +
                data?.dashboard?.games?.totalSpinGameCount || 0
            }
            amount={
              data?.dashboard?.games?.totalSpinAmount +
                data?.dashboard?.games?.totalSpinGameAmount || 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            title="Scratch"
            total={data?.dashboard?.games?.totalScratchCount || 0}
            amount={data?.dashboard?.games?.totalScratchAmount || 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
