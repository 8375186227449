import avatar1 from "../../../../assets/avatars/1.svg";
import avatar2 from "../../../../assets/avatars/2.svg";
import avatar3 from "../../../../assets/avatars/3.svg";
import avatar4 from "../../../../assets/avatars/4.svg";
import avatar5 from "../../../../assets/avatars/5.svg";
import avatar6 from "../../../../assets/avatars/6.svg";
import avatar7 from "../../../../assets/avatars/7.svg";
import avatar8 from "../../../../assets/avatars/8.svg";
import avatar9 from "../../../../assets/avatars/9.svg";
import avatar10 from "../../../../assets/avatars/10.svg";
import avatar11 from "../../../../assets/avatars/11.svg";

export const avatars = [
  { id: 1, avatar: avatar1 },
  { id: 2, avatar: avatar2 },
  { id: 3, avatar: avatar3 },
  { id: 4, avatar: avatar4 },
  { id: 5, avatar: avatar5 },
  { id: 6, avatar: avatar6 },
  { id: 7, avatar: avatar7 },
  { id: 8, avatar: avatar8 },
  { id: 9, avatar: avatar9 },
  { id: 10, avatar: avatar10 },
  { id: 11, avatar: avatar11 },
];
