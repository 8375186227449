import React from "react";
import { Box, Typography, Card } from "@mui/material";

// Stat Box
const StatBox = ({ title, value, increase, icon, totalAmount }) => {
  // theme
  return (
    <Card sx={{ backgroundColor: "#FAFAFA", padding: "1rem 0.5rem" }}>
      <Box
        gridColumn="span 2"
        gridRow="span 1"
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        border={1}
        borderColor={"#e0e0e0"}
        p={{ xs: "0.4rem", md: "0.4rem 1rem" }}
        flex="1 1 100%"
        backgroundColor={"#fff"}
        borderRadius="0.55rem"
        minHeight={"7rem"}
      >
        <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
          <img
            src="https://ludo3.s3.ap-south-1.amazonaws.com/logo.webp"
            alt="Icon"
            width={"40px"}
          />
          <Typography sx={{ fontSize: "20px" }} fontWeight="600">
            {title}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"0.5rem"}
          minWidth={"40%"}
        >
          {value || value === 0 ? (
            <Box
              border={1}
              borderColor={"#e0e0e0"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"0.5rem"}
              width={"100%"}
            >
              <Typography sx={{ fontSize: "16px" }} fontWeight="600">
                Number -
              </Typography>
              <Typography
                sx={{ fontSize: "16px" }}
                fontWeight="600"
                color="green"
              >
                {value}
              </Typography>
            </Box>
          ) : null}

          {totalAmount || totalAmount === 0 ? (
            <Box
              border={1}
              borderColor={"#e0e0e0"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"0.5rem"}
              width={"100%"}
            >
              <Typography sx={{ fontSize: "16px" }} fontWeight="600">
                Amount -
              </Typography>
              <Typography
                sx={{ fontSize: "16px" }}
                fontWeight="600"
                color="green"
              >
                Rs {totalAmount.toFixed(2)}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Card>
  );
};

export default StatBox;
