import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Closed from "./Closed";
import UpiSuffixSelector from "../../components/Suffix";

export default function UpiSell({ setBanInfoState, bankInfo, upiValidation }) {
  const { upisellChips } = useSelector((state) => state.settingReducer);
  const [isPasswordField, setIsPasswordField] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.includes(".")) {
      return;
    }
    setBanInfoState({
      ...bankInfo,
      [name]: value.replace(/\s+/g, "").toLowerCase(), // Store the numeric value in the state
    });
  };
  const handleInputCopy = (e) => {
    e.preventDefault(); // Prevent the copy action
  };
  const handleSuffixSelect = (suffix) => {
    const atIndex = bankInfo.accountNumber.indexOf("@");

    if (atIndex !== -1) {
      // Remove everything after and including the '@' typed by the user
      const newUpiId = bankInfo.accountNumber.slice(0, atIndex);
      setBanInfoState({
        ...bankInfo,
        accountNumber: newUpiId + suffix,
      });
    } else {
      // If '@' is not present, simply add the suffix
      setBanInfoState({
        ...bankInfo,
        accountNumber: newUpiId + suffix,
      });
    }
  };
  const handleSuffixConfirmSelect = (suffix) => {
    const atIndex = bankInfo.confirmBankAccount.indexOf("@");

    if (atIndex !== -1) {
      // Remove everything after and including the '@' typed by the user
      const newUpiId = bankInfo.confirmBankAccount.slice(0, atIndex);
      setBanInfoState({
        ...bankInfo,
        confirmBankAccount: newUpiId + suffix,
      });
    } else {
      // If '@' is not present, simply add the suffix
      setBanInfoState({
        ...bankInfo,
        confirmBankAccount: newUpiId + suffix,
      });
    }
  };

  return (
    <>
      {!upisellChips ? (
        <Accordion.Body className="p-0">
          <div className="shadow card">
            <div className="bg-light text-dark text-capitalize card-header text-center">
              UPI Payment Details
            </div>

            <div className="card-body">
              <div className="vstack gap-3 minBreakpoint-xs">
                <div>
                  <label className="text-capitalize text-start w-100 form-label">
                    UPI ID
                  </label>
                  <input
                    name="accountNumber"
                    style={{ userSelect: "none" }}
                    onCopy={handleInputCopy}
                    onContextMenu={handleInputCopy}
                    type={isPasswordField ? "password" : "text"}
                    onFocus={() => {
                      setIsPasswordField(false);
                    }}
                    onBlur={() => {
                      setIsPasswordField(true);
                    }}
                    onInput={(e) => {
                      const { name, value } = e.target;
                      setBanInfoState({
                        ...bankInfo,
                        [name]: value.replace(/\s+/g, "").toLowerCase(), // Store the numeric value in the state
                      });
                    }}
                    placeholder="Your UPI ID"
                    className="form-control form-control"
                    value={bankInfo.accountNumber}
                  ></input>
                  {bankInfo.accountNumber.includes("@") && (
                    <UpiSuffixSelector onSelect={handleSuffixSelect} />
                  )}
                </div>
                <div>
                  <label className="text-capitalize text-start w-100 form-label">
                    Re Enter UPI ID{" "}
                  </label>
                  <input
                    name="confirmBankAccount"
                    onChange={handleChange}
                    placeholder="Your UPI ID"
                    className="form-control form-control"
                    value={bankInfo.confirmBankAccount}
                  ></input>
                  {bankInfo.confirmBankAccount.includes("@") && (
                    <UpiSuffixSelector onSelect={handleSuffixConfirmSelect} />
                  )}
                </div>
                <div>
                  <label className="text-capitalize text-start w-100 form-label">
                    Chips{" "}
                  </label>
                  <input
                    name="amount"
                    onChange={handleChange}
                    placeholder="Chips"
                    type="number"
                    className="form-control form-control"
                    value={bankInfo?.amount}
                    step="0"
                  />
                  <label
                    style={{ marginTop: "10px" }}
                    className="text-capitalize text-start w-100 form-label"
                  >
                    {/* <span style={{ color: "red" }}>Fees</span>: ₹{fee(bankInfo)}{" "} */}
                    {/* Amount: ₹{Amount(bankInfo)} */}
                  </label>
                </div>
                <p style={{ fontSize: "0.8rem" }}>
                  By Continuing, you agree to our{" "}
                  <Link to="/legal">Legal Terms</Link> and you are 18 years or
                  older.
                </p>

                <button className="btn btn-primary" onClick={upiValidation}>
                  Sell
                </button>
              </div>
            </div>
          </div>
        </Accordion.Body>
      ) : (
        <Closed chips={"Upi Withdraw Chips"} />
      )}
    </>
  );
}
