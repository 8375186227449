import React from "react";
import CircularLoading from "../../components/atoms/CircularLoading";
import "../../../common/components/atoms/styles.css";

export default function WinModal({
  handleClose,
  handleImageChange,
  handleWinChallenge,
  wonModal,
  postResultLoading,
  image,
}) {
  return (
    <form onSubmit={handleWinChallenge}>
      <div
        role="dialog"
        aria-modal="true"
        className={`h-auto offcanvas offcanvas-bottom ${
          wonModal ? "show" : "hide"
        }`}
        tabIndex="-1"
        style={{ visibility: "visible" }}
      >
        <div className="offcanvas-header">
          <div className="offcanvas-title h5"></div>
          <button
            onClick={handleClose}
            type="button"
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="pb-3 d-flex flex-column align-items-stretch">
            <div className="vstack gap-3 minBreakpoint-xs">
              <h1 className="text-capitalize">Upload Result</h1>
              {image.Url && (
                <div className="flex align-items-center">
                  <img
                    width={200}
                    height={200}
                    src={image.Url}
                    alt="Selected Image"
                    className="object-fit-contain"
                  />
                </div>
              )}
              <label htmlFor="upload-btn" className="btn btn-primary btn-lg">
                {image.Url ? "Replace Image" : "Upload Image"}
              </label>
              <input
                id="upload-btn"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
              <button
                type="submit"
                disabled={postResultLoading || !image || !image?.image}
                className="btn btn-success btn-lg"
                onClick={handleWinChallenge}
              >
                {" "}
                {postResultLoading ? (
                  <CircularLoading
                    height={"1.5rem"}
                    width={"1.5rem"}
                    color={"white"}
                  />
                ) : (
                  "Post Result"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
