export const USER_AUTH = {
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  SIGNUP_LOADING: "SIGNUP_LOADING",
};

export const LOGIN_AUTH = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_LOADING: "LOGIN_LOADING",
  SOCKET_CONNECTION: "SOCKET_CONNECTION",
};

export const LOGOUT_AUTH = {
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  AUTOMATIC_LOGOUT_REQUEST: "AUTOMATIC_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  LOGOUT_LOADING: "LOGOUT_LOADING",
};

export const USER_PROFILE = {
  USER_GET_PROFILE_REQUEST: "USER_GET_PROFILE_REQUEST",
  USER_GET_PROFILE_SUCCESS: "USER_GET_PROFILE_SUCCESS",
  USER_GET_PROFILE_ERROR: "USER_GET_PROFILE_ERROR",
  USER_GET_PROFILE_LOADING: "USER_GET_PROFILE_LOADING",

  USER_UPDATE_PROFILE_REQUEST: "USER_UPDATE_PROFILE_REQUEST",
  USER_UPDATE_PROFILE_SUCCESS: "USER_UPDATE_PROFILE_SUCCESS",
  USER_UPDATE_PROFILE_ERROR: "USER_UPDATE_PROFILE_ERROR",
  USER_UPDATE_PROFILE_LOADING: "USER_UPDATE_PROFILE_LOADING",
};
export const WALLET = {
  GET_WALLET_REQUEST: "GET_WALLET_REQUEST",
  GET_WALLET_REQUEST1: "GET_WALLET_REQUEST1",
  GET_WALLET_SUCCESS: "GET_WALLET_SUCCESS",
  GET_WALLET_ERROR: "GET_WALLET_ERROR",
  GET_WALLET_LOADING: "GET_WALLET_LOADING",

  UPDATE_WALLET_REQUEST: "UPDATE_WALLET_REQUEST",
  UPDATE_WALLET_SUCCESS: "UPDATE_WALLET_SUCCESS",
  UPDATE_WALLET_ERROR: "UPDATE_WALLET_ERROR",
  UPDATE_WALLET_LOADING: "UPDATE_WALLET_LOADING",

  CONVERT_TO_DEPOSIT_REQUEST: "CONVERT_TO_DEPOSIT_REQUEST",
  CONVERT_TO_DEPOSIT_SUCCESS: "CONVERT_TO_DEPOSIT_SUCCESS",
  CONVERT_TO_DEPOSIT_ERROR: "CONVERT_TO_DEPOSIT_ERROR",
  CONVERT_TO_DEPOSIT_LOADING: "CONVERT_TO_DEPOSIT_LOADING",

  USER_BUY_CHIPS_REQUEST: "USER_BUY_CHIPS_REQUEST",
  USER_BUY_CHIPS_SUCCESS: "USER_BUY_CHIPS_SUCCESS",
  USER_BUY_CHIPS_LOADING: "USER_BUY_CHIPS_LOADING",
  USER_BUY_CHIPS_ERROR: "USER_BUY_CHIPS_ERROR",

  USER_SELL_CHIPS_REQUEST: "USER_SELL_CHIPS_REQUEST",
  USER_SELL_CHIPS_SUCCESS: "USER_SELL_CHIPS_SUCCESS",
  USER_SELL_CHIPS_LOADING: "USER_SELL_CHIPS_LOADING",
  USER_SELL_CHIPS_ERROR: "USER_SELL_CHIPS_ERROR",
};
export const HISTORY = {
  GET_HISTORY_REQUEST: "GET_HISTORY_REQUEST",
  GET_HISTORY_SUCCESS: "GET_HISTORY_SUCCESS",
  GET_HISTORY_ERROR: "GET_HISTORY_ERROR",
  GET_HISTORY_LOADING: "GET_HISTORY_LOADING",
};
export const CHALLENGE = {
  GET_CHALLENGE_REQUEST: "GET_CHALLENGE_REQUEST",
  GET_CHALLENGE_SUCCESS: "GET_CHALLENGE_SUCCESS",
  GET_CHALLENGE_ERROR: "GET_CHALLENGE_ERROR",
  GET_CHALLENGE_LOADING: "GET_CHALLENGE_LOADING",
};
export const PLAY = {
  GET_CHALLENGES_REQUEST: "GET_CHALLENGES_REQUEST",
  GET_CHALLENGES_SUCCESS: "GET_CHALLENGES_SUCCESS",
  GET_CHALLENGES_ERROR: "GET_CHALLENGES_ERROR",
  GET_CHALLENGES_LOADING: "GET_CHALLENGES_LOADING",
};

export const DASHBOARD = {
  GLOBAL_SLICE_REQUEST: "GLOBAL_SLICE_REQUEST",
  GLOBAL_SLICE_SUCCESS: "GLOBAL_SLICE_SUCCESS",
  GLOBAL_SLICE_ERROR: "GLOBAL_SLICE_ERROR",
  GLOBAL_SLICE_LOADING: "GLOBAL_SLICE_LOADING",

  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_ERROR: "ADMIN_LOGIN_ERROR",
  ADMIN_LOGIN_LOADING: "ADMIN_LOGIN_LOADING",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  GET_USER_LOADING: "GET_USER_LOADING",

  GET_DFILTER_REQUEST: "GET_DFILTER_REQUEST",
  GET_DFILTER_SUCCESS: "GET_DFILTER_SUCCESS",
  GET_DFILTER_ERROR: "GET_DFILTER_ERROR",
  GET_DFILTER_LOADING: "GET_DFILTER_LOADING",

  GET_CUSTOMERS_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMERS_ERROR: "GET_CUSTOMER_ERROR",
  GET_CUSTOMERS_LOADING: "GET_CUSTOMER_LOADING",

  UPDATE_CUSTOMERS_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMERS_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMERS_ERROR: "UPDATE_CUSTOMER_ERROR",
  UPDATE_CUSTOMERS_LOADING: "UPDATE_CUSTOMER_LOADING",

  GET_CUSTOMERS_SEARCH_REQUEST: "GET_CUSTOMER_SEARCH_REQUEST",
  GET_CUSTOMERS_SEARCH_SUCCESS: "GET_CUSTOMER_SEARCH_SUCCESS",
  GET_CUSTOMERS_SEARCH_ERROR: "GET_CUSTOMER_SEARCH_ERROR",
  GET_CUSTOMERS_SEARCH_LOADING: "GET_CUSTOMER_SEARCH_LOADING",

  GET_HOLD_GAMES_SEARCH_REQUEST: "GET_HOLD_GAMES_SEARCH_REQUEST",
  GET_HOLD_GAMES_SEARCH_SUCCESS: "GET_HOLD_GAMES_SEARCH_SUCCESS",
  GET_HOLD_GAMES_SEARCH_ERROR: "GET_HOLD_GAMES_SEARCH_ERROR",
  GET_HOLD_GAMES_SEARCH_LOADING: "GET_HOLD_GAMES_SEARCH_LOADING",

  GET_HOLD_GAMES_REQUEST: "GET_HOLD_GAMES_REQUEST",
  GET_HOLD_GAMES_SUCCESS: "GET_HOLD_GAMES_SUCCESS",
  GET_HOLD_GAMES_ERROR: "GET_HOLD_GAMES_ERROR",
  GET_HOLD_GAMES_LOADING: "GET_HOLD_GAMES_LOADING",

  GET_TOTAL_GAMES_SEARCH_REQUEST: "GET_TOTAL_GAMES_SEARCH_REQUEST",
  GET_TOTAL_GAMES_SEARCH_SUCCESS: "GET_TOTAL_GAMES_SEARCH_SUCCESS",
  GET_TOTAL_GAMES_SEARCH_ERROR: "GET_TOTAL_GAMES_SEARCH_ERROR",
  GET_TOTAL_GAMES_SEARCH_LOADING: "GET_TOTAL_GAMES_SEARCH_LOADING",

  GET_TOTAL_GAMES_REQUEST: "GET_TOTAL_GAMES_REQUEST",
  GET_TOTAL_GAMES_SUCCESS: "GET_TOTAL_GAMES_SUCCESS",
  GET_TOTAL_GAMES_ERROR: "GET_TOTAL_GAMES_ERROR",
  GET_TOTAL_GAMES_LOADING: "GET_TOTAL_GAMES_LOADING",

  GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_ERROR: "GET_SETTINGS_ERROR",
  GET_SETTINGS_LOADING: "GET_SETTINGS_LOADING",

  GET_USER_DETAILS_REQUEST: "GET_USER_DETAILS_REQUEST",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_ERROR: "GET_USER_DETAILS_ERROR",
  GET_USER_DETAILS_LOADING: "GET_USER_DETAILS_LOADING",

  GET_ADMINS_REQUEST: "GET_ADMINS_REQUEST",
  GET_ADMINS_SUCCESS: "GET_ADMINS_SUCCESS",
  GET_ADMINS_ERROR: "GET_ADMINS_ERROR",
  GET_ADMINS_LOADING: "GET_ADMINS_LOADING",

  ADD_SETTINGS_REQUEST: "ADD_SETTINGS_REQUEST",
  ADD_SETTINGS_SUCCESS: "ADD_SETTINGS_SUCCESS",
  ADD_SETTINGS_ERROR: "ADD_SETTINGS_ERROR",
  ADD_SETTINGS_LOADING: "ADD_SETTINGS_LOADING",

  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_ERROR: "GET_DASHBOARD_ERROR",
  GET_DASHBOARD_LOADING: "GET_DASHBOARD_LOADING",

  GET_LUDONAME_REQUEST: "GET_LUDONAME_REQUEST",
  GET_LUDONAME_SUCCESS: "GET_LUDONAME_SUCCESS",
  GET_LUDONAME_ERROR: "GET_LUDONAME_ERROR",
  GET_LUDONAME_LOADING: "GET_LUDONAME_LOADING",

  UPDATE_HOLDGAME_REQUEST: "UPDATE_HOLDGAME_REQUEST",
  UPDATE_HOLDGAME_SUCCESS: "UPDATE_HOLDGAME_SUCCESS",
  UPDATE_HOLDGAME_ERROR: "UPDATE_HOLDGAME_ERROR",
  UPDATE_HOLDGAME_LOADING: "UPDATE_HOLDGAME_LOADING",
};
