import React, { useState, useEffect } from "react";
import BankWithDraw from "./BankSell";
import SavedBanks from "./SavedBanks";
import { BsArrowLeftShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import Closed from "./Closed";

const BankInfo = ({
  banks,
  bankInfo,
  setBanInfoState,
  activeTab,
  setActiveTab,
  activeIndex,
  setActiveIndex,
  setAddNewBank,
  setIsOpen,
}) => {
  const { bankSellChips } = useSelector((state) => state.settingReducer);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [bankWithDrawOpen, setIsBankWithDrawOpen] = useState(null);

  useEffect(() => {
    if (banks.length === 0) {
      setIsBankWithDrawOpen(1);
    } else {
      setIsBankWithDrawOpen(2);
    }
  }, [banks]);

  return (
    <>
      {bankSellChips ? (
        <Closed chips={"Bank Withdraw Chips"} />
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-success mb-2 btn-sm"
              onClick={() => {
                setActiveTab(0);
                setAddNewBank(false);
                setActiveIndex(null);
              }}
            >
              <BsArrowLeftShort className="me-1" />
              Back
            </button>
            {bankWithDrawOpen === 2 ? (
              <button
                className="btn btn-danger mb-2 btn-sm"
                onClick={() => {
                  setIsConfirmDelete(true);
                }}
              >
                Delete
              </button>
            ) : null}
          </div>
          {bankWithDrawOpen === 1 && (
            <BankWithDraw
              setBanInfoState={setBanInfoState}
              bankInfo={bankInfo}
              setIsOpen={setIsOpen}
            />
          )}

          <>
            {banks.length > 0 && bankWithDrawOpen !== 1 && (
              <SavedBanks
                setActiveTab={setActiveTab}
                setAddNewBank={setAddNewBank}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                isConfirmDelete={isConfirmDelete}
                setIsConfirmDelete={setIsConfirmDelete}
                setIsBankWithDrawOpen={setIsBankWithDrawOpen}
              />
            )}
          </>
        </div>
      )}
    </>
  );
};

export default BankInfo;
