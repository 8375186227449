import React from "react";
import gamesArray from "./fakeGames/fakeGame";
import { CSSTransition } from "react-transition-group";

const OtherPlayingChallenges = ({ otherPlayer, mode }) => {
  const other = otherPlayer
    .filter(
      (item) =>
        (item.state === "playing" || item.state === "hold") &&
        item.mode === mode
    )
    .slice(0, 20);
  const fakeAndOtherPlayer = [...other, ...gamesArray];

  const renderPlayerInfo = (player, label) => (
    <div className="d-flex align-items-start">
      {label === "Player" ? (
        <div style={{ marginLeft: "22px" }}>
          <span className="fw-semibold text-truncate" style={{ width: "80px" }}>
            {player?.username.slice(0, 5)}...
          </span>

          <img
            src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${player?.profileImage}`}
            alt="avatar"
            style={{ height: "24px", width: "24px", marginLeft: "5px" }}
          />
        </div>
      ) : (
        <>
          <div
            className="bg-dark rounded-circle me-2"
            style={{ height: "24px", width: "24px" }}
          >
            <img
              src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${player.profileImage}`}
              alt="avatar"
            />
          </div>
          <span className="fw-semibold text-truncate" style={{ width: "80px" }}>
            {player.username.slice(0, 5)}...
          </span>
        </>
      )}
    </div>
  );
  const memoizedChallenges = React.useMemo(() => {
    return fakeAndOtherPlayer.map((item) => (
      <CSSTransition
        key={item._id}
        timeout={100}
        classNames={{
          enter: false ? "card-animation-enter" : "",
          enterActive: "card-animation-enter-active",
        }}
      >
        <li className={"p-0 overflow-hidden appear-from-left"}>
          <div className="my-2 card">
            <div className="text-start card-body">
              <div className="d-flex align-items-center justify-content-between">
                {renderPlayerInfo(item.creator, "Creator")}
                <div>
                  <img
                    src="https://ludoplayers.com/static/media/vs.c153e22fa9dc9f58742d.webp"
                    alt="verses-icon"
                    height="40"
                  />
                </div>
                {renderPlayerInfo(item.player, "Player")}
              </div>
              <div className="d-flex align-items-center justify-content-center pt-3">
                <span className="text-success fw-bold">Rs {item.amount}</span>
              </div>
            </div>
          </div>
        </li>
      </CSSTransition>
    ));
  }, [fakeAndOtherPlayer]);

  return <div>{memoizedChallenges}</div>;
};

export default OtherPlayingChallenges;
