import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";
import axios from "axios";
import Cookies from "js-cookie";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbjGhm-sQNyyqAC2uB7V1xJBq7Efc8Qpk",
  authDomain: "newludogotiking.firebaseapp.com",
  projectId: "newludogotiking",
  storageBucket: "newludogotiking.appspot.com",
  messagingSenderId: "494059602943",
  appId: "1:494059602943:web:d06292143527b3d14eddca",
};
const baseUrl = process.env.REACT_APP_CLIENT_BASEURL;

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register("/sw.js", {
          scope: "/firebase-push-notification-scope",
        });
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getPermission = async (userId) => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    const token = await getToken(messaging, {
      vapidKey:
        "BFPqWrAz98A2S3XOu-fWUa8kR2edm0o-esxTo58HXXa76HrBJetpq7mEfm9dm8Pjwab1yKQHqiDiUEkAWnvqCo4",
      serviceWorkerRegistration,
    });
    if (token) {
      const header = new Headers();
      header.append("Content-Type", "application/json");
      const body = {
        token: token,
        userId: userId,
      };
      axios.post(`${baseUrl}/token/subscribe`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      Cookies.set("tokenSent", true);
    }
  }
};
