import React from "react";

import Deposit from "./wallet/Deposit";
import { BsArrowLeftShort } from "react-icons/bs";
import WithDraw from "./wallet/WithDraw";
import { Link } from "react-router-dom";

import AppLayout from "../layout/AppLayout";
import { useSelector } from "react-redux";
import KycAlert from "../components/appbar/components/KycAlert";

export default function Wallet() {
  const { data: userData } = useSelector((state) => state.user);
  return (
    <AppLayout>
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <Link to="/">
              <button className="btn btn-primary border">
                <BsArrowLeftShort className="me-2" />
                Back
              </button>
            </Link>
          </div>
          <Link
            to="/history"
            className="text-capitalize btn btn-outline-primary"
          >
            Wallet History
          </Link>
        </div>
        {!userData?.kycVerified && <KycAlert variant={"fulfilled"} />}
        <Deposit />
        <WithDraw />
      </div>
    </AppLayout>
  );
}
