import * as React from "react";
import {
  DashboardOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemText, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WalletIcon from "@mui/icons-material/Wallet";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Cookies from "js-cookie";

const NavItems = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const navItems = [
    {
      text: "Dashboard",
      icon: <DashboardOutlined />,
    },
    {
      text: "Users",
      icon: <Groups2Outlined />,
    },

    {
      text: "HoldGames",
      icon: <ReceiptLongOutlined />,
    },
    {
      text: "Total Games",
      icon: <PublicOutlined />,
    },
    // {
    //   text: 'Deposits',
    //   icon: <LocalAtmIcon />,
    // },
    // {
    //   text: "Pending Deposits",
    //   icon: <TodayOutlined />,
    // },
    // {
    //   text: "Withdrawal Requests",
    //   icon: <WalletIcon />,
    // },
    // {
    //   text: 'Bank Withdrawals',
    //   icon: <WalletIcon />,
    // },

    // {
    //   text: 'Successful WithDrawls',
    //   icon: <AccountBalanceWalletIcon />,
    // },

    {
      text: "Settings",
      icon: <SettingsIcon />,
    },
    {
      text: "Admin",
      icon: <SupervisorAccountIcon />,
    },
    {
      text: "Logout",
      icon: <SupervisorAccountIcon />,
    },
  ];

  const handleNavigate = (item) => {
    if (item.text === "Logout") {
      Cookies.remove("token");
      Cookies.remove("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");

      window.location.href = "/login";
      toggleDrawer();
    } else {
      const basePath = "/admin";
      navigate(`${basePath}/${item.text.toLowerCase().replace(/\s+/g, "")}`);
      toggleDrawer();
    }
  };

  return (
    <>
      {navItems.map((item, i) => (
        <ListItemButton
          className={i % 2 === 0 ? "bg-light" : "odd"}
          onClick={() => {
            handleNavigate(item);
          }}
          sx={{
            paddingTop: "16px",
            paddingBottom: "16px",
            color: "#212529",
            justifyContent: "space-between",
          }}
          key={i}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <ListItemIcon sx={{ minWidth: "auto" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              sx={{
                span: {
                  fontSize: "1.1rem",
                },
              }}
            />
          </Box>
          <KeyboardArrowRightIcon />
        </ListItemButton>
      ))}
    </>
  );
};

export default NavItems;
