import React, { useEffect, useState } from "react";
import {
  Stack,
  Chip,
  Box,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";

import { Header, AddUserRoleModal } from "../../components";
import { Spa } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../redux/contstants";

const Admin = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const adminsData = useSelector((state) => state?.dashboardSlice);
  console.log(adminsData);
  const [admins, setAdmins] = useState(null);
  // get data

  // useEffect(() => {
  //   dispatch({ type: DASHBOARD.GET_ADMINS_REQUEST });
  //   setAdmins(adminsData?.data);
  //   // useEditAdminQuery();
  // }, []);

  const handleAddUserModal = () => {
    setShowModal(true);
  };

  return (
    <Box
      p={{ xs: "1.5rem 1rem", md: "1.5rem 2.5rem" }}
      pt={{ xs: "5rem", lg: "5rem" }}
      height={"100%"}
    >
      {/* Header */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Header title="Manage User Access" />
      </Box>

      <Box pt="20px" height={"100%"}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          height={"100%"}
        >
          {!adminsData?.isLoading && admins && admins?.length > 0
            ? admins?.map((admin, index) => (
                <Grid item xs={12} md={6} xl={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: "#FAFAFA",
                      padding: "0.6rem 0.5rem",
                    }}
                  >
                    <Box
                      gridColumn="span 2"
                      gridRow="span 1"
                      display="flex"
                      flexDirection={"column"}
                      alignItems={"center"}
                      border={1}
                      borderColor={"#e0e0e0"}
                      p={{ xs: "0.4rem", md: "0.4rem 1rem" }}
                      flex="1 1 100%"
                      backgroundColor={"#fff"}
                      borderRadius="0.55rem"
                      height={"100%"}
                    >
                      <Box textAlign={"start"} width={"100%"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"start"}
                          width={"100%"}
                        >
                          <Box minWidth={"100px"} display={"flex"}>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                            >
                              Username
                            </Typography>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                              marginLeft="auto"
                            >
                              :
                            </Typography>
                          </Box>

                          <Typography
                            variant="h6"
                            fontWeight="600"
                            minWidth={"150px"}
                            textAlign={"center"}
                          >
                            {admin?.username || "No Username"}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"start"}
                          width={"100%"}
                        >
                          <Box minWidth={"100px"} display={"flex"}>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                            >
                              Roles
                            </Typography>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                              marginLeft="auto"
                            >
                              :
                            </Typography>
                          </Box>

                          <Typography
                            variant="h6"
                            fontWeight="600"
                            minWidth={"150px"}
                            textAlign={"center"}
                          >
                            {admin?.role || "No Role"}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"start"}
                          width={"100%"}
                        >
                          <Box minWidth={"100px"} display={"flex"}>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                            >
                              Permissions
                            </Typography>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              textAlign={"center"}
                              marginLeft="auto"
                            >
                              :
                            </Typography>
                          </Box>

                          <Typography
                            variant="h6"
                            fontWeight="600"
                            minWidth={"150px"}
                            textAlign={"center"}
                          >
                            <Stack direction="row" spacing={1}>
                              {admin?.permissions
                                ? admin?.permissions?.map(
                                    (permission, index) => (
                                      <span key={index} className="">
                                        <Chip
                                          label={permission}
                                          color="primary"
                                          sx={{
                                            background: "blue",
                                            color: "white",
                                            margin: "0 10px",
                                          }}
                                        />
                                      </span>
                                    )
                                  )
                                : "No Permissions"}
                            </Stack>
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent={"end"}
                        width={"100%"}
                        gap={2}
                        sx={{
                          minWidth: "200px",
                        }}
                      >
                        <Button
                          sx={{
                            background: "green",
                            borderRadius: "25px",
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          sx={{
                            background: "red",
                            borderRadius: "25px",
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))
            : ""}

          <Grid item xs={12} md={6} xl={3}>
            <Card
              sx={{
                backgroundColor: "#FAFAFA",
                padding: "0.6rem 0.5rem",
                height: "100%",
              }}
            >
              <Box
                gridColumn="span 2"
                gridRow="span 1"
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                border={1}
                borderColor={"#e0e0e0"}
                p={{ xs: "0.4rem", md: "0.4rem 1rem" }}
                flex="1 1 100%"
                backgroundColor={"#fff"}
                borderRadius="0.55rem"
                height={"100%"}
              >
                <Button
                  onClick={handleAddUserModal}
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    fontSize: "1rem",
                  }}
                >
                  + Add User
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <AddUserRoleModal
        showModal={showModal}
        setShowModal={setShowModal}
        Loading={Loading}
      />
    </Box>
  );
};

export default Admin;
