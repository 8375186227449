import React from "react";
import {
  Box,
  Modal,
  Fade,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const AddUserRoleModal = ({ showModal, setShowModal, Loading }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
    >
      <Fade in={showModal || Loading}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "80%" : "30%",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
          }}
        >
          <Box
            display={"flex"}
            padding={"1rem 2rem"}
            backgroundColor={"#000"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight="600" color="#fff">
              + Add User Role
            </Typography>
            <IconButton
              sx={{
                padding: "0",
              }}
              onClick={() => setShowModal(false)}
            >
              <ClearIcon
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  height: "2rem",
                  width: "2rem",
                }}
              />
            </IconButton>
          </Box>
          <Box component="form" padding={"1rem 3rem"}>
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              minWidth={"150px"}
              textAlign={"start"}
            >
              Username
            </Typography>
            <TextField
              size="small"
              placeholder="Username"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
              fullWidth
              sx={{ "& .MuiOutlinedInput-input": { padding: "24px" } }}
              margin="normal"
            />
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              minWidth={"150px"}
              textAlign={"start"}
            >
              Password
            </Typography>
            <TextField
              size="small"
              placeholder="Password"
              type="password"
              // value={password}
              // onChange={(e) => setPassword(e.target.value)}
              fullWidth
              sx={{ "& .MuiOutlinedInput-input": { padding: "24px" } }}
              margin="normal"
            />
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              minWidth={"150px"}
              textAlign={"start"}
            >
              Add User Role
            </Typography>
            <Box display={"flex"} gap={2}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Admin
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderWidth: "2px",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Editor
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Viewer
              </Button>
            </Box>

            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              minWidth={"150px"}
              textAlign={"start"}
              marginTop={2}
            >
              Permission Role
            </Typography>

            <Box display={"flex"} flexWrap={"wrap"} gap={2}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Dashboard
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderWidth: "2px",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Total Games
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Hold Games
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Users
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Withdrawal Request
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Successful Withdrawals
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Deposits
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Settings
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#e0e0e0",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                type={"primary"}
              >
                + Admin Roles
              </Button>
            </Box>
            <Box display={"flex"} alignItems="center" justifyContent={"end"}>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  marginTop: "3rem",
                  textTransform: "capitalize",
                  borderRadius: "25px",
                  marginLeft: "auto",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddUserRoleModal;
