import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Card,
  Typography,
} from '@mui/material';
import {
  useGetSuccessRequestsQuery,
  useGetSearchSuccessRequestsQuery,
} from '../../state/api';
import { Header, SearchBox } from '../../components';

const PendingWithdrawal = () => {
  // values for backend
  const [search, setSearch] = useState('');
  const [result, setResult] = useState('');

  // get data
  const { data, isLoading, refetch } = useGetSuccessRequestsQuery();
  const {
    data: searchData,
    isLoading: isSearchLoading,
    refetch: searchRefetch,
  } = useGetSearchSuccessRequestsQuery(search);

  useEffect(() => {
    if (search) {
      searchRefetch(search);
      setResult(searchData?.transactions);
    } else {
      refetch(search);
      setResult(data?.transactions);
    }
  }, [data, searchData, search]);

  //search
  const handleChange = (e) => {
    if (e.target.value.length <= search.length + 3) {
      console.log('typing', search);

      setTimeout(() => {
        if (
          (search.length > e.target.value.length &&
            (e.target.value.startsWith(search) ||
              search.startsWith(e.target.value))) ||
          search == e.target.value ||
          (search.length < e.target.value.length &&
            (e.target.value.startsWith(search) ||
              search.startsWith(e.target.value)))
        ) {
          console.log('API 1 Used');
          console.log(e.target.value);
          setSearch(e.target.value);
          searchRefetch();
        } else {
          console.log('API 2 Used');
          setSearch(e.target.value);
          searchRefetch();
        }
      }, 800);
    } else if (e.target.value.length > search.length + 3) {
      setSearch(e.target.value);
      searchRefetch();
    }
  };

  return (
    <Box
      p={{ xs: '1.5rem 1rem', md: '1.5rem 2.5rem' }}
      pt={{ xs: '5rem', lg: '5rem' }}
      height={'100%'}
    >
      {/* Header */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Header
          title="Pending Deposits"
          refetch={refetch}
          isLoading={isLoading}
        />

        <SearchBox
          handleOnchange={handleChange}
          setSearch={setSearch}
          search={search}
          placeholder={'Search'}
        />
      </Box>
      <Box pt="20px" height={'100%'}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          height={'100%'}
        >
          {result ? (
            result.length > 0 ? (
              result.map((transaction, index) => (
                <Grid item xs={12} md={6} xl={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: '#FAFAFA',
                      padding: '0.6rem 0.5rem',
                      height: '130px',
                    }}
                  >
                    <Box
                      gridColumn="span 2"
                      gridRow="span 1"
                      display="flex"
                      justifyContent="space-between"
                      alignItems={'center'}
                      border={1}
                      borderColor={'#e0e0e0'}
                      p={{ xs: '0.4rem', md: '0.4rem 1rem' }}
                      flex="1 1 100%"
                      backgroundColor={'#fff'}
                      borderRadius="0.55rem"
                      height={'100%'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'0.5rem'}
                        height={'100%'}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-around'}
                          width={'100%'}
                          height={'100%'}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              padding={'0.3rem'}
                              border={1}
                              minWidth={'150px'}
                              textAlign={'center'}
                              sx={{
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                borderColor: '#e0e0e0',
                              }}
                            >
                              Phone Number
                            </Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              padding={'0.3rem'}
                              minWidth={'150px'}
                              border={1}
                              textAlign={'center'}
                              sx={{
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                borderColor: '#e0e0e0',
                              }}
                            >
                              Amount:{' '}
                              <span style={{ color: 'green' }}>Rs500</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent="space-around"
                        height={'100%'}
                      >
                        <Typography
                          variant="h6"
                          fontWeight="600"
                          padding={'0.3rem'}
                          border={1}
                          textAlign={'center'}
                          sx={{
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            borderColor: '#e0e0e0',
                          }}
                        >
                          Order ID
                        </Typography>
                        <Box display={'flex'} gap={2}>
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: '25px',
                              backgroundColor: 'green',
                              color: '#ffff',
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: '25px',
                              backgroundColor: 'red',
                              color: '#ffff',
                            }}
                          >
                            SS
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Typography variant="h4" fontWeight="600" padding={'0.3rem'}>
                  No pending withdrawal Request
                </Typography>
              </Box>
            )
          ) : (
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={'100%'}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PendingWithdrawal;
