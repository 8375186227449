import React from "react";
import {
  Modal,
  Fade,
  Box,
  Button,
  Select,
  Grid,
  TextField,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import ImageViewer from "./ImageViewver";
import {
  formatDate,
  formatToTime,
  getTimeDifference,
} from "../utils/helpingFunctions";
import vs from "../../../public/assets/vs.webp";
import LudoNames from "./LudoName";

const SettleModal = ({
  editGame,
  showModal,
  setShowModal,
  Loading,
  winner,
  setWinner,
  setPenaltyUser,
  penaltyUser,
  penaltyAmount,
  holdTime,
  setPenaltyAmount,
  setHoldTime,
  saveLoading,
  handleReturnChips,
  returnLoading,
  holdLoading,
  handleSaveUser,
  handlePutOnHold,
}) => {
  const theme = useTheme();
  console.log("checkeditgame", editGame, winner);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
    >
      <Fade in={showModal || Loading}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "90%" : "40%",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              padding: "4px 0",
            }}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            borderBottom={1}
            borderColor={"#e0e0e0"}
          >
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              border={1}
              minWidth={"150px"}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
                fontSize: "14px",
              }}
            >
              {editGame?.creator?.username}
            </Typography>

            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              my={0.3}
            >
              <img
                src={vs}
                alt="verses-icon"
                width="20"
                style={{ margin: "0 auto" }}
              />
              <Typography
                variant="span"
                fontWeight="600"
                sx={{ marginLeft: "5px", color: "green" }}
              >
                Rs{editGame?.amount}
              </Typography>
            </Box>
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              border={1}
              minWidth={"150px"}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
                fontSize: "14px",
              }}
            >
              {editGame?.player?.username}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent="space-around"
            height={"100%"}
            sx={{
              padding: "5px 0",
            }}
          >
            <Typography
              fontWeight="600"
              padding={"0.3rem 1rem"}
              border={1}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
                fontSize: "14px",
              }}
            >
              {editGame?.roomCode ? editGame?.roomCode : "No RoomCode"}
            </Typography>
            <Typography
              fontWeight="600"
              padding={"0.3rem 1rem"}
              border={1}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
                fontSize: "14px",
              }}
            >
              {editGame?.mode ? editGame?.mode : "No RoomCode"}
            </Typography>
            <Box
              padding={"0.3rem 1rem"}
              border={1}
              minWidth={"35%"}
              sx={{
                marginTop: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gap="12px"
                justifyContent="space-between"
              >
                <Typography
                  fontWeight="600"
                  sx={{ fontSize: "14px" }}
                  textAlign={"center"}
                >
                  Challenge Created At:
                </Typography>
                <Typography
                  fontWeight="600"
                  sx={{ fontSize: "14px" }}
                  textAlign={"right"}
                >
                  {editGame?.createdAt
                    ? formatDate(editGame?.createdAt)
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap="12px"
                justifyContent="space-between"
              >
                <Typography
                  fontWeight="600"
                  sx={{ fontSize: "14px" }}
                  textAlign={"center"}
                >
                  Challenge Started At:
                </Typography>

                <Typography
                  fontWeight="600"
                  textAlign={"right"}
                  color={"red"}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {editGame?.createdAt
                    ? formatToTime(editGame?.createdAt)
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap="12px"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  fontWeight="600"
                  textAlign={"center"}
                  sx={{ fontSize: "14px" }}
                >
                  Game Duration:
                </Typography>

                <Typography
                  fontWeight="600"
                  textAlign={"right"}
                  color={"red"}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {editGame && getTimeDifference(editGame)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box borderTop={5} borderColor={"#e0e0e0"}>
            <Grid container>
              <Grid item xs={12} md={6} borderRight={2} borderColor={"#e0e0e0"}>
                <Box
                  sx={{
                    padding: "1rem 2rem",
                  }}
                >
                  <LudoNames id={editGame?.creator?._id} />

                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Creator Posted Result At:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.creator.result
                        ? formatToTime(editGame?.results?.creator?.updatedAt)
                        : "Invalid Date"}
                    </span>
                  </Typography>
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Creator Result:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results?.creator?.result}
                    </span>
                  </Typography>
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Reason:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.cancellationReasons?.creator}
                    </span>
                  </Typography>
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    RoomCode Sent:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.roomCodeSetAt
                        ? formatToTime(editGame?.roomCodeSetAt)
                        : "No RoomCode"}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "0 0.5rem",
                  }}
                >
                  <Typography
                    fontWeight="600"
                    textAlign={"center"}
                    sx={{
                      borderRadius: "25px",
                      backgroundColor: "green",
                      color: "white",
                      padding: "0.5rem 1rem",
                      fontSize: "10px",
                    }}
                  >
                    Creator Screenshot
                  </Typography>
                  <Box textAlign={"center"} padding={"0.5rem 0"}>
                    {editGame?.winnerScreenShot?.creator ? (
                      <ImageViewer
                        imageUrl={editGame?.winnerScreenShot?.creator}
                      />
                    ) : (
                      <Typography
                        fontWeight="600"
                        textAlign={"center"}
                        maxWidth={"60%"}
                        border={3}
                        borderColor={"#333"}
                        sx={{
                          margin: "0 auto",
                          marginTop: "1.5rem",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#000",
                          padding: "0.5rem 1rem",
                          fontSize: "10px",
                        }}
                      >
                        No Screenshot
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} borderRight={2} borderColor={"#e0e0e0"}>
                <Box
                  sx={{
                    padding: "1rem 2rem",
                  }}
                >
                  <LudoNames id={editGame?.player._id} />
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Player Posted Result At:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results?.player?.updatedAt
                        ? formatToTime(editGame?.results?.player?.updatedAt)
                        : "Invalid Date"}
                    </span>
                  </Typography>
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Player Result:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results?.player?.result}
                    </span>
                  </Typography>
                  <Typography fontWeight="600" sx={{ fontSize: "10px" }}>
                    Reason:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.cancellationReasons?.player}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "0 0.5rem",
                  }}
                >
                  <Typography
                    fontWeight="600"
                    textAlign={"center"}
                    sx={{
                      borderRadius: "25px",
                      backgroundColor: "green",
                      color: "white",
                      padding: "0.5rem 1rem",
                      fontSize: "10px",
                    }}
                  >
                    Player Screenshot
                  </Typography>
                  <Box textAlign={"center"} padding={"0.5rem 0"}>
                    {editGame?.winnerScreenShot.player ? (
                      <ImageViewer
                        imageUrl={editGame?.winnerScreenShot?.player}
                      />
                    ) : (
                      <Typography
                        fontWeight="600"
                        textAlign={"center"}
                        maxWidth={"80%"}
                        border={3}
                        borderColor={"#333"}
                        sx={{
                          margin: "0 auto",
                          marginTop: "1.5rem",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#000",
                          padding: "0.5rem 1rem",
                          fontSize: "10px",
                        }}
                      >
                        No Screenshot
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={9} mx={"auto"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Select
                    value={winner}
                    size="small"
                    onChange={(e) => {
                      setWinner(e.target.value);
                      const selectedValue = e.target.value;
                      const otherValue =
                        selectedValue === editGame?.creator?._id
                          ? editGame?.player?._id
                          : editGame?.creator?._id;
                      setPenaltyUser(otherValue);
                    }}
                    displayEmpty
                    sx={{
                      width: "40%",
                      margin: "0 auto",
                      marginBottom: "6px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select winner
                    </MenuItem>
                    <MenuItem value={editGame?.creator?._id}>
                      {editGame?.creator?.username}
                    </MenuItem>
                    <MenuItem value={editGame?.player?._id}>
                      {editGame?.player?.username}
                    </MenuItem>
                  </Select>
                  <Select
                    size="small"
                    placeholder="Select Penalty User"
                    displayEmpty
                    value={penaltyUser}
                    onChange={(e) => {
                      setPenaltyUser(e.target.value);
                      const selectedValue = e.target.value;
                      const otherValue =
                        selectedValue === editGame?.creator?._id
                          ? editGame?.player?._id
                          : editGame?.creator?._id;
                      setWinner(otherValue);
                    }}
                    sx={{
                      width: "40%",
                      margin: "0 auto",
                      marginBottom: "6px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select loser
                    </MenuItem>
                    <MenuItem value={editGame?.player?._id}>
                      {editGame?.player?.username}
                    </MenuItem>
                    <MenuItem value={editGame?.creator?._id}>
                      {editGame?.creator?.username}
                    </MenuItem>
                  </Select>
                  <TextField
                    size="small"
                    type="number"
                    sx={{
                      width: "40%",
                      margin: "0 auto",
                    }}
                    placeholder="Penalty Amount"
                    value={penaltyAmount}
                    onChange={(e) => {
                      const penalty = parseInt(e.target.value);
                      setPenaltyAmount(penalty);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: theme.palette.background.paper,
                    padding: "16px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    margin: "0 auto",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleReturnChips}
                    loading={returnLoading}
                    sx={{
                      backgroundColor: "#E4703F",
                      borderRadius: "25px",
                      color: "white",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    Return Chips
                  </LoadingButton>

                  <LoadingButton
                    sx={{
                      backgroundColor: "#00A651",
                      borderRadius: "25px",
                      color: "#ffff",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    loading={saveLoading}
                    variant="contained"
                    color="primary"
                    disabled={saveLoading}
                    onClick={handleSaveUser}
                  >
                    save
                  </LoadingButton>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#F2334D",
                      borderRadius: "25px",
                      color: "#ffff",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      setWinner("");
                      setPenaltyUser("");
                      setPenaltyAmount("");
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SettleModal;
