import React from "react";

export default function Closed({ chips }) {
  return (
    <>
      <div className="mb-3 shadow card">
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="bg-light text-dark text-capitalize card-header"
        >
          <span>{chips} Closed</span>
        </div>
        <div className="card-body">
          <div className="card-text">
            <span style={{ fontSize: "0.8rem" }}>
              {chips} are Currently Unavailable because of Security Reasons.
              Please Come Back Later. It Will be Available Soon.
            </span>
          </div>
          <div className="image-container">
            <img
              src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/closed.webp"
              alt="Chips"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </>
  );
}
