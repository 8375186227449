import React, { useEffect, useState } from "react";
import {
  Card,
  Stack,
  Table,
  Button,
  Container,
  TableBody,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../../redux/contstants";
import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { applyFilter, getComparator } from "../utils";
import Scrollbar from "../../../components/sharedComponents/scrollbar";
import { Header } from "../../../components";

// ----------------------------------------------------------------------

export default function TotalGames() {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading2, setLoading2] = useState(true);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const dispatch = useDispatch();
  const totalGames = useSelector((state) => state?.dashboardSlice);
  const totalItems = totalGames?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / 50);
  useEffect(() => {
    dispatch({
      type: DASHBOARD.GET_TOTAL_GAMES_REQUEST,
      payload: { page, filterName: search },
    });
  }, [dispatch, page, search]);

  useEffect(() => {
    if (totalGames?.data) {
      setResult(totalGames?.data?.challenges);
    }
    setLoading2(totalGames?.isLoading);
  }, [totalGames]);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        dispatch({
          type: DASHBOARD.GET_TOTAL_GAMES_REQUEST,
          payload: { page, filterName: searchTerm },
        });
      }, 800)
    );
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(id);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const dataFiltered = applyFilter({
    inputData: result,
    comparator: getComparator(order, orderBy),
    filterName: search,
  });

  const notFound = !dataFiltered?.length && !!search;

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={10}
        sx={{ height: "50px" }}
      >
        <Header title="Total Games" isLoading={loading2} />
        <UserTableToolbar
          numSelected={selected?.length}
          filterName={search}
          onFilterName={handleSearchInputChange}
        />
      </Stack>

      <Scrollbar>
        <TableContainer sx={{ overflow: "unset", height: "500px" }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={result?.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={[
                { id: "creatorName", label: "Creator Username" },
                { id: "playerName", label: "Player Username" },
                { id: "amount", label: "Amount" },
                { id: "tRoomCode", label: "Transaction Room Code" },
                { id: "tMode", label: "Transaction Mode" },
                { id: "winner", label: "Winner" },
                { id: "" },
              ]}
            />
            <TableBody>
              {dataFiltered?.slice(0, rowsPerPage)?.map((row) => (
                <UserTableRow
                  key={row?._id}
                  creatorName={row?.creator?.username}
                  creatorResult={row?.results?.creator?.result}
                  transaction={row}
                  amount={row?.amount}
                  state={row?.state}
                  playerName={row?.player?.username}
                  playerResult={row?.results?.player?.result}
                  tMode={row?.mode}
                  tRoomCode={row?.roomCode}
                  selected={selected.indexOf(row.name) !== -1}
                  handleClick={(event) => handleClick(event, row.name)}
                />
              ))}

              {notFound && <TableNoData query={search} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box
        py={2}
        px={2}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
      >
        <Pagination
          count={totalPages}
          onChange={handleChangePage}
          page={page}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Card>
  );
}
