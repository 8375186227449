import { useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Label from "../../components/sharedComponents/label";
import Iconify from "../../components/sharedComponents/iconify";
import { Result, SettleModal, ViewGameModal } from "../../components";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  creatorName,
  creatorResult,
  amount,
  playerName,
  playerResult,
  tMode,
  state,
  tRoomCode,
  transaction,
  handleClick,
}) {
  const [open, setOpen] = useState(null);
  const [editGame, setEditGame] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditUser = async (user) => {
    setShowModal(true);
    setLoading(true);
    setEditGame(user);

    setLoading(false);
    setShowModal(true);
  };
  const handleResultModal = async (user) => {
    setResultModal(true);
    setLoading(true);
    setEditGame(user);

    setLoading(false);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell
          component="th"
          scope="row"
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          <Typography variant="subtitle2" noWrap>
            <Link
              to={`/user/${transaction?.creator?._id}`}
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                "&:hover": {
                  color: "green",
                },
              }}
            >
              {creatorName}
            </Link>
          </Typography>
        </TableCell>
        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          <Typography variant="subtitle2" noWrap>
            <Link
              to={`/user/${transaction?.player?._id}`}
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                "&:hover": {
                  color: "green",
                },
              }}
              state={transaction?.player?._id}
            >
              {playerName}
            </Link>
          </Typography>
        </TableCell>
        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          {amount}
        </TableCell>

        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          {tRoomCode ? tRoomCode : "No RoomCode"}
        </TableCell>
        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          <Label color={(tMode === "" && "error") || "success"}>
            {tMode ? tMode : "No RoomCode"}
          </Label>
        </TableCell>
        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          Winner-Name
        </TableCell>
        {/* <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          {creatorResult && (
            <Label
              color={
                ((creatorResult === "lost" || creatorResult === "cancelled") &&
                  "error") ||
                "success"
              }
            >
              {creatorResult}
            </Label>
          )}
        </TableCell>

        <TableCell
          sx={{ padding: "5px 16px", fontSize: "1rem", height: "55px" }}
        >
          {playerResult && (
            <Label
              color={
                ((playerResult === "lost" || playerResult === "cancelled") &&
                  "error") ||
                "success"
              }
            >
              {playerResult}
            </Label>
          )}
        </TableCell> */}
        <TableCell align="center" sx={{ padding: "5px 16px", height: "55px" }}>
          <Button
            sx={{
              textAlign: "center",
              bgcolor: "green",
              color: "#fff",
              fontSize: ".7rem",
              fontWeight: "bold",
              ":hover": { bgcolor: "#fff", color: "green" },
            }}
            onClick={() => handleEditUser(transaction)}
          >
            View
          </Button>
        </TableCell>
      </TableRow>

      <ViewGameModal
        showModal={showModal}
        setShowModal={setShowModal}
        Loading={Loading}
        editGame={editGame}
      />
      {resultModal && (
        <Result
          showModal={resultModal}
          setShowModal={setResultModal}
          Loading={Loading}
          editGame={editGame}
        />
      )}
    </>
  );
}

UserTableRow.propTypes = {
  creatorName: PropTypes.any,
  creatorResult: PropTypes.any,
  amount: PropTypes.any,
  playerName: PropTypes.any,
  playerResult: PropTypes.any,
  tMode: PropTypes.any,
  selected: PropTypes.any,
  tRoomCode: PropTypes.any,
  handleClick: PropTypes.any,

  transaction: PropTypes.any,
};
