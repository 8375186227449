import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Card,
  Pagination,
  useMediaQuery,
  Modal,
  Fade,
  TextField,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import {
  useGetWithdrawRequestsQuery,
  useGetSearchWithdrawRequestsQuery,
  useUpdateStateMutation,
} from '../../state/api';
import { Header, SearchBox } from '../../components';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
const DeclineModal = ({
  setShowModal,
  showModal,
  handleDeclineChips,
  declineLoader,
  transactionId,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [reason, setReason] = useState(''); // State to store the reason input
  const handleClose = () => {
    setShowModal(false);
    setReason(''); // Clear the reason input when the modal is closed
  };
  const handleDecline = () => {
    handleDeclineChips(transactionId, 0, reason);
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleClose} closeAfterTransition>
      <Fade in={showModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '30%' : '30%',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            overflow: 'auto',
            padding: '16px', // Add some padding to the modal content
          }}
        >
          <TextField
            label="Reason for Decline"
            variant="outlined"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'red',
              color: '#ffff',
              borderRadius: '25px',
              textTransform: 'capitalize',
            }}
            onClick={handleDecline}
          >
            Decline
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

const WithdrawalRequest = () => {
  // values for backend
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransaction] = useState(null);
  const [approvedLoader, setApprovedLoader] = useState(null);
  const [declineLoader, setDeclineLoader] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(null);

  // get data
  const { data, isLoading, refetch } = useGetWithdrawRequestsQuery(page);
  const {
    data: searchData,
    isLoading: isSearchLoading,
    refetch: searchRefetch,
  } = useGetSearchWithdrawRequestsQuery(search);

  // mutation
  const [updateState] = useUpdateStateMutation();

  const handleApprovedChips = async (productId, newState, reason) => {
    if (newState === 1) {
      setApprovedLoader(productId);
      setDeclineLoader(null);
    }
    const { data } = await updateState({
      id: productId,
      state: newState,
    });
    if (data && data.status === 200) {
      toast.success(data.message);
    } else {
      toast.error('request failed');
    }

    setApprovedLoader(null);
  };
  const handleDeclineChips = async (productId, newState, reason) => {
    if (newState === 0) {
      setDeclineLoader(productId);
    }

    const { data } = await updateState({
      id: productId,
      state: newState,
      reason: reason,
    });
    if (data && data.status === 200) {
      toast.success(data.message);
    } else {
      toast.error('request failed');
    }
    setDeclineLoader(null);
  };

  //pagination
  const totalItems = data?.total || 0;
  const totalPages = Math.ceil(totalItems / 12);

  useEffect(() => {
    if (search != '') {
      searchRefetch(search);
      setResult(searchData?.transactions);
    } else {
      setResult(data?.transactions);
    }
  }, [search]);
  useEffect(() => {
    setLoading(isLoading || isSearchLoading);
    if (searchData) {
      setResult(searchData?.transactions);
    } else {
      setResult(data?.transactions);
    }
  }, [data, searchData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  //search
  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setSearch(searchTerm);
        searchRefetch({ search: searchTerm });
      }, 800),
    );
  };

  return (
    <Box
      p={{ xs: '1.5rem 1rem', md: '1.5rem 2.5rem' }}
      pt={{ xs: '5rem', lg: '5rem' }}
      height={'100%'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Header title="UPI Withdrawal" refetch={refetch} isLoading={loading} />
        <SearchBox
          handleOnchange={handleSearchInputChange}
          placeholder={'Search by UPI or Username'}
        />
      </Box>

      <Box pt="20px" height={'100%'}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          height={loading ? '100%' : undefined}
        >
          {!loading && result && result.length > 0 ? (
            result.map((transaction, index) => (
              <Grid item xs={12} md={6} xl={4} key={index}>
                <Card
                  key={index}
                  sx={{
                    backgroundColor: '#FAFAFA',
                    padding: '0.6rem 0.5rem',
                    height: '130px',
                  }}
                >
                  <Box
                    gridColumn="span 2"
                    gridRow="span 1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                    border={1}
                    borderColor={'#e0e0e0'}
                    p={{ xs: '0.4rem', md: '0.4rem 1rem' }}
                    flex="1 1 100%"
                    backgroundColor={'#fff'}
                    borderRadius="0.55rem"
                    height={'100%'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                      height={'100%'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        width={'100%'}
                        height={'100%'}
                      >
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            border={1}
                            minWidth={'150px'}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            <Link
                              to={`/user/${transaction?._id}`}
                              style={{
                                textDecoration: 'none',
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'green',
                                },
                              }}
                              state={transaction?._id}
                            >
                              {transaction?.userId?.username}
                            </Link>
                          </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            minWidth={'150px'}
                            border={1}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            Amount:{' '}
                            <span style={{ color: 'green' }}>
                              Rs{transaction?.amount}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent="space-around"
                      height={'100%'}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="600"
                        padding={'0.3rem'}
                        border={1}
                        textAlign={'center'}
                        sx={{
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          borderColor: '#e0e0e0',
                        }}
                      >
                        {transaction?.upiId}
                      </Typography>
                      <Box display={'flex'}>
                        <LoadingButton
                          variant="contained"
                          loading={approvedLoader === transaction?._id}
                          sx={{
                            backgroundColor: 'green',
                            color: '#ffff',
                            marginRight: '5px',
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                          }}
                          onClick={() =>
                            handleApprovedChips(transaction?._id, 1)
                          }
                        >
                          Approved
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          loading={declineLoader === transaction._id}
                          sx={{
                            backgroundColor: 'red',
                            color: '#ffff',
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => {
                            setTransaction(transaction._id);
                            setShowModal(true);
                          }}
                        >
                          Decline
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={'100%'}
            >
              {result && result.length == 0 ? (
                search ? (
                  <Typography variant="h4">
                    No withdrawal requests found
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    No withdrawal requests found.
                  </Typography>
                )
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Box>
          )}

          {!search && (
            <Box
              my={2}
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'end'}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                color="secondary"
              />
            </Box>
          )}
        </Grid>
      </Box>
      <DeclineModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDeclineChips={handleDeclineChips}
        transactionId={transactionId}
        declineLoader={declineLoader}
      />
    </Box>
  );
};

export default WithdrawalRequest;
