import { toast } from "react-toastify";
import AWS from "aws-sdk";
import CryptoJS from "crypto-js";
AWS.config.update({
  bucketName: "gotiking-staging-s3",
  region: "ap-south-1",
  accessKeyId: "AKIA5NDAKBNHJEXYQL52",
  secretAccessKey: "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",
});
export function sortEvents(events, userId) {
  events.sort((a, b) => {
    if (
      a.state === "playing" &&
      (a.player === userId || a.creator._id === userId)
    ) {
      return -1;
    }
    if (
      b.state === "playing" &&
      (b.player === userId || b.creator._id === userId)
    ) {
      return 1;
    }
    if (a.state === "open" && a.creator?._id === userId) {
      return -1;
    }
    if (b.state === "open" && b.creator?._id === userId) {
      return 1;
    }
    if (a.state === "requested" && a.player?._id === userId) {
      return -1;
    }
    if (b.state === "requested" && b.player?._id === userId) {
      return 1;
    }

    // 1) state == "open" && creator._id == userId
    if (a.state === "requested" && a.creator._id === userId) {
      return -1;
    }
    if (b.state === "requested" && b.creator._id === userId) {
      return 1;
    }
    if (
      (a.state === "playing" && a.creator?._id === userId) ||
      a.player?._id === userId
    ) {
      return -1;
    }
    if (
      (b.state === "playing" && b.creator?._id === userId) ||
      b.player?._id === userId
    ) {
      return 1;
    }
    // if (a.state === "playing" && a.player?._id === userId) return -1;
    // if (b.state === "playing" && b.player?._id === userId) return 1;

    if (
      (a.state === "hold" && a.creator?._id === userId) ||
      a.player?._id === userId
    ) {
      return -1;
    }
    if (
      (b.state === "hold" && b.creator?._id === userId) ||
      b.player?._id === userId
    ) {
      return 1;
    }
    // if (a.state === "hold" && a.player?._id === userId) return -1;
    // if (b.state === "hold" && b.player?._id === userId) return 1;

    // 1) state == "open" && creator._id == userId

    // 1) state == "open" && creator._id == userId
    if (
      a.state === "requested" &&
      a.player === userId &&
      a.creator === userId
    ) {
      return -1;
    }
    if (
      b.state === "requested" &&
      b.player === userId &&
      b.creator === userId
    ) {
      return 1;
    }
    if (a.state === "open" && a.creator._id !== userId && a.player !== userId) {
      return -1;
    }
    if (b.state === "open" && b.creator._id !== userId && b.player !== userId) {
      return 1;
    }
    if (
      a.creator?._id != userId &&
      a.player?._id != userId &&
      a.state == "playing"
    ) {
      return -1;
    }
    if (
      b.creator?._id != userId &&
      b.player?._id != userId &&
      b.state == "playing"
    ) {
      return 1;
    }
    // 1) state == "open" && creator._id == userId

    // Default sorting
    return 0;
  });

  return events;
}

export function filterEvents(mode, events, userId, viewGame) {
  events.forEach((element) => {
    if (
      element.state === "playing" &&
      element.player?._id === userId &&
      element.firstTime
    ) {
      viewGame(element._id);
    }
  });

  return {
    allChallenges: events,
  };
}
export function challengesSort(challegesData, userId, sorting) {
  challegesData.sort((a, b) => {
    if (
      a.state === "playing" &&
      (a.player === userId || a.creator._id === userId)
    )
      return -1;
    if (
      b.state === "playing" &&
      (b.player === userId || b.creator._id === userId)
    )
      return 1;

    // 1) state == "open" && creator._id == userId
    if (a.state === "requested" && a.creator._id === userId) return -1;
    if (b.state === "requested" && b.creator._id === userId) return 1;
    // 1) state == "open" && creator._id == userId
    if (a.state === "requested" && a.player?._id === userId) return -1;
    if (b.state === "requested" && b.player?._id === userId) return 1;

    // 1) state == "open" && creator._id == userId
    if (a.state === "open" && a.creator._id === userId) return -1;
    if (b.state === "open" && b.creator._id === userId) return 1;

    // 1) state == "open" && creator._id == userId
    if (a.state === "requested" && a.player === userId && a.creator === userId)
      return -1;
    if (b.state === "requested" && b.player === userId && b.creator === userId)
      return 1;

    if (sorting == "lowToHigh") {
      if (a.state === "open" && a.creator._id !== userId) {
        if (b.state === "open" && b.creator._id !== userId) {
          return a.amount - b.amount;
        }
        return -1;
      }
      if (b.state === "open" && b.creator._id !== userId) return 1;
    } else if (sorting == "highToLow") {
      if (a.state === "open" && a.creator._id !== userId) {
        if (b.state === "open" && b.creator._id !== userId) {
          return b.amount - a.amount;
        }
        return -1;
      }
      if (b.state === "open" && b.creator._id !== userId) return 1;
    }

    if (a.state === "open" && a.creator._id !== userId && a.player !== userId)
      return -1;
    if (b.state === "open" && b.creator._id !== userId && b.player !== userId)
      return 1;

    if (
      a.creator?._id != userId &&
      a.player?._id != userId &&
      a.state == "playing"
    )
      return -1;
    if (
      b.creator?._id != userId &&
      b.player?._id != userId &&
      b.state == "playing"
    )
      return 1;

    // Default sorting
    return 0;
  });
}
export function validateAmount(amount) {
  if (amount < 50 || amount > 25000) {
    toast.error("Amount should be between 50 and 25000.");
    return false;
  }

  if (amount < 50 && amount % 10 !== 0) {
    toast.error("Amount should be a multiple of 10.");
    return false;
  }

  if (amount >= 50 && amount % 50 !== 0) {
    toast.error("Amount should be a multiple of 50.");
    return false;
  }

  return true;
}

export function isAvailabletoPlayGame(wallet, amount) {
  if (wallet < amount) {
    toast.error("Not nough chips.");
    return false;
  }
  return true;
}
export const formatToTime = (time) => {
  if (!time) {
    return "N/A"; // Handle the case when roomCodeSetAt is not provided or is falsy
  }

  const formattedTime = new Date(time).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return formattedTime;
};
export const fee = (state) => {
  const fee = 0;

  return Math.ceil(fee);
};
export const Amount = (state) => {
  const fee = state.amount;

  return Math.floor(fee);
};
export const buttonLinks = (vpa, amount, Url) => {
  const tn = "ytb"; // Transaction note (you can customize this)
  const cu = "INR"; // Currency (you can customize this)
  const pn = "PG"; // Payment note (you can customize this)

  // Create the UPI payment links for different platforms
  const googlePayLink = `gpay://upi/pay?pa=${vpa}&tn=${tn}&am=${amount}&cu=${cu}&pn=${pn}`;
  const paytmLink = `paytmmp://pay?pa=${vpa}&tn=${tn}&am=${amount}&cu=${cu}&pn=${pn}`;
  const phonePeLink = `phonepe://pay?pa=${vpa}&tn=${tn}&am=${amount}&cu=${cu}&pn=${pn}`;
  const genericUpiLink = `upi://pay?pa=${vpa}&tn=${tn}&am=${amount}&cu=${cu}&pn=${pn}`;
  const QrCode = Url;

  return {
    googlePayLink,
    paytmLink,
    phonePeLink,
    genericUpiLink,
    QrCode,
  };
};
export const rezorButtonLinks = (data) => {
  // "[\"upi://pay?ver=01\",\"mode=15\",\"pa=RBP21.rzp@icici\",\"pn=RBP\",\"tr=EZV2023081115361047492908\",\"cu=INR\",\"mc=4814\",\"qrMedium=04\",\"tn=PaymenttoRBP\",\"am=1\"]";
  // const mode = "15";
  // const pn = "RBP";
  // const cu = "INR";
  // const mc = "4814";
  // const qrMedium = "04";
  // const tn = "PaymenttoRBP";
  // const ver = "01";

  // // Create the UPI payment links for different platforms
  // const googlePayLink = `gpay://upi/pay?ver=${ver}&mode=${mode}&pa=${vpa}&pn=${pn}&tr=${tr}&cu=${cu}&mc=${mc}&qrMedium=${qrMedium}&tn=${tn}&am=${amount}`;
  // const paytmLink = `paytmmp://pay?ver=${ver}&mode=${mode}&pa=${vpa}&pn=${pn}&tr=${tr}&cu=${cu}&mc=${mc}&qrMedium=${qrMedium}&tn=${tn}&am=${amount}`;
  // const phonePeLink = `phonepe://pay?ver=${ver}&mode=${mode}&pa=${vpa}&pn=${pn}&tr=${tr}&cu=${cu}&mc=${mc}&qrMedium=${qrMedium}&tn=${tn}&am=${amount}`;
  // const genericUpiLink = `upi://pay?ver=${ver}&mode=${mode}&pa=${vpa}&pn=${pn}&tr=${tr}&cu=${cu}&mc=${mc}&qrMedium=${qrMedium}&tn=${tn}&am=${amount}`;
  // const QrCode = Url;

  return {
    googlePayLink: data.gpay_link,
    paytmLink: data.paytm_link,
    phonePeLink: data.phonepe_link,
    genericUpiLink: data.bhim_link,
  };
};
export const convertToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
export const encrypt = (plainString) => {
  const key = CryptoJS.enc.Base64.parse(
    "J/PYjc1ftDFK5+77U1PB80v2TamokGap5fghrtyujg5="
  );
  const iv = CryptoJS.enc.Base64.parse("gaOr3uvhZEwFeSbRHwlHcg==");
  const encrypted = CryptoJS.AES.encrypt(plainString, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const uploadImageToS3 = async (file, id) => {
  try {
    const s3 = new AWS.S3();
    const maxFileSize = 10 * 1024 * 1024; // 10 MB (in bytes)

    if (!file) {
      throw new Error("Please provide a valid file.");
    }

    const fileSize = file.size;

    if (fileSize > maxFileSize) {
      throw new Error("File size exceeds the maximum limit.");
    }

    const params = {
      Bucket: "gotiking-staging-s3",
      Key: `${Date.now()}_${file.name}_id_${id}`,
      Body: file,
      ACL: "public-read",
      ContentType: file.type,
    };

    const response = await s3.upload(params).promise();
    console.log("checkkresponse", response);

    const { Location } = response;
    if (!Location) {
      throw new Error("Failed to upload the screenshot.");
    }

    return Location;
  } catch (error) {
    throw error; // Rethrow the error for the caller to handle
  }
};

export const createChallenge = ({
  amount,

  setAmount,

  data,
  mode,
  socket,
  userId,
  username,
}) => {
  const isValidAmount = validateAmount(amount);
  if (!isValidAmount || !isAvailabletoPlayGame(data.wallet, amount)) {
    setAmount("");
    return;
  }
  if (socket) {
    socket.send(
      JSON.stringify({
        type: "create",
        payload: { amount: amount, userId, username, mode },
      })
    );
  }

  setAmount("");
};
