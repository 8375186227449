import * as React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { Typography, Toolbar, IconButton, List, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import NavItems from './NavItems';

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
    },
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },

    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(0),
      },
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

export default function DrawerComponent({ open, toggleDrawer }) {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          backgroundColor: '#212529',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'space-between',
          px: [1],
          color: '#fff',
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          fontWeight={'bold'}
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Goti King
        </Typography>
        <IconButton onClick={toggleDrawer}>
          <MenuIcon sx={{ color: '#ffff' }} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <NavItems toggleDrawer={toggleDrawer} />
      </List>
    </Drawer>
  );
}
