import { useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Label from "../../components/sharedComponents/label";
import Iconify from "../../components/sharedComponents/iconify";
import { Result, SettleModal } from "../../components";

import { useDispatch } from "react-redux";
import { DASHBOARD } from "../../../redux/contstants";
import { Button } from "@mui/material";
import { updateChallenge } from "../../../apis/dashboard";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  creatorName,
  creatorResult,
  amount,
  playerName,
  playerResult,
  tMode,
  state,
  tRoomCode,
  transaction,
  handleClick,
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);
  const [winner, setWinner] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [returnLoading, setReturnLoading] = useState(false);
  const [holdLoading, setHoldLoading] = useState(false);
  const [penaltyUser, setPenaltyUser] = useState("");
  const [penaltyAmount, setPenaltyAmount] = useState("");
  const [holdTime, setHoldTime] = useState(null);
  const [editGame, setEditGame] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ChallengeAmount, setChallengeAmount] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditUser = async (challenge) => {
    console.log("model opened");

    setShowModal(true);
    setLoading(true);
    setEditGame(challenge);
    setChallengeAmount(challenge.amount);
    setChallengeId(challenge._id);
  };
  const handleResultModal = async (user) => {
    setResultModal(true);
    setLoading(true);
    setEditGame(user);

    setLoading(false);
  };

  const handlePutOnHold = async () => {
    if (!holdTime) {
      return toast.error("set hold time");
    }
    if (holdTime > 50) {
      return toast.error("time is too large");
    }

    setHoldLoading(true);

    if (editGame) {
      dispatch({
        type: DASHBOARD?.UPDATE_HOLDGAME_REQUEST,
        data: {
          putOnHold: "adminHold",
          challengeId: challengeId,
          holdTime: holdTime,
        },
      }).then((res) => console.log(res));

      if (data && data.status === 200) {
        toast.success(data.message);
      } else {
        toast.error("Request failed");
      }
    }

    setHoldLoading(false);
    setShowModal(false);
  };
  const handleSaveUser = async () => {
    if (!penaltyUser) {
      return toast.error("Winner is not selected");
    }

    setSaveLoading(true);

    if (editGame) {
      const { data } = await updateChallenge({
        returnChips: "resolve",
        challengeId: challengeId,
        winner: winner,
        penaltyUser: penaltyUser,
        penaltyAmount: penaltyAmount,
        ChallengeAmount: ChallengeAmount,
      });

      if (data && data.status === 200) {
        toast.success(data.message);
        dispatch({
          type: DASHBOARD.GET_HOLD_GAMES_REQUEST,
          payload: { page: 0 },
        });
      } else {
        toast.error("Request failed");
      }

      setShowModal(false);
    }

    setSaveLoading(false);
  };

  const handleReturnChips = async () => {
    setReturnLoading(true);

    if (editGame) {
      const { data } = await updateChallenge({
        returnChips: "returnChips",
        challengeId: challengeId,
        winner: winner,
        penaltyUser: penaltyUser,
        penaltyAmount: penaltyAmount,
        ChallengeAmount: ChallengeAmount,
      });

      if (data && data.status === 200) {
        toast.success(data.message);
        dispatch({
          type: DASHBOARD.GET_HOLD_GAMES_REQUEST,
          payload: { page: 0 },
        });
      } else {
        toast.error("Request failed");
      }
    }

    setReturnLoading(false);
    setShowModal(false);
  };

  return (
    <>
      <TableRow sx={{height:"55px"}} hover tabIndex={-1} role="checkbox">
        <TableCell sx={{ padding: "5px 16px" }} component="th" scope="row">
          <Typography sx={{ fontSize: "1rem" }} variant="subtitle2" noWrap>
            {creatorName}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: "5px 16px" }}>
          <Typography sx={{ fontSize: "1rem" }} variant="subtitle2" noWrap>
            {playerName}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: "5px 16px", fontSize: "1rem" }}>
          {amount}
        </TableCell>
        <TableCell sx={{ padding: "5px 16px", fontSize: "1rem" }}>
          {creatorResult && (
            <Label color={(creatorResult === "lost" && "error") || "success"}>
              {creatorResult}
            </Label>
          )}
        </TableCell>

        <TableCell sx={{ padding: "5px 16px", fontSize: "1rem" }}>
          {playerResult && (
            <Label color={(playerResult === "lost" && "error") || "success"}>
              {playerResult}
            </Label>
          )}
        </TableCell>
        <TableCell sx={{ padding: "5px 16px", fontSize: "1rem" }}>
          {tRoomCode ? tRoomCode : "No RoomCode"}
        </TableCell>
        <TableCell sx={{ padding: "5px 16px", fontSize: "1rem" }}>
          <Label color={(tMode === "" && "error") || "success"}>
            {tMode ? tMode : "No RoomCode"}
          </Label>
        </TableCell>

        <TableCell align="center" sx={{ padding: "5px 16px" }}>
          <Button
            sx={{
              textAlign: "center",
              bgcolor: "green",
              color: "#fff",
              fontSize: ".7rem",
              fontWeight: "bold",
              ":hover": { bgcolor: "#fff", color: "green" },
            }}
            onClick={() => handleEditUser(transaction)}
          >
            Settle
          </Button>
        </TableCell>
      </TableRow>

      <SettleModal
        editGame={editGame}
        showModal={showModal}
        setShowModal={setShowModal}
        // Loading={loading}
        winner={winner}
        setWinner={setWinner}
        setPenaltyUser={setPenaltyUser}
        penaltyUser={penaltyUser}
        penaltyAmount={penaltyAmount}
        setPenaltyAmount={setPenaltyAmount}
        saveLoading={saveLoading}
        handleReturnChips={handleReturnChips}
        returnLoading={returnLoading}
        holdLoading={holdLoading}
        holdTime={holdTime}
        handleSaveUser={handleSaveUser}
        handlePutOnHold={handlePutOnHold}
        setHoldTime={setHoldTime}
      />
      {resultModal && (
        <Result
          showModal={resultModal}
          setShowModal={setResultModal}
          Loading={Loading}
          editGame={editGame}
        />
      )}
    </>
  );
}

UserTableRow.propTypes = {
  creatorName: PropTypes.any,
  creatorResult: PropTypes.any,
  amount: PropTypes.any,
  playerName: PropTypes.any,
  playerResult: PropTypes.any,
  tMode: PropTypes.any,
  selected: PropTypes.any,
  tRoomCode: PropTypes.any,
  handleClick: PropTypes.any,
  transaction: PropTypes.any,
  key: PropTypes.any,
};
