import numeral from "numeral";

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format("$0,0.00") : "";

  return result(format, ".00");
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format("0.0%") : "";

  return result(format, ".0");
}

export function fShortenNumber(number) {
  if (!number) return "";

  let format = "";
  let suffix = "";

  if (number >= 10000000) {
    // Crores
    format = (number / 10000000).toFixed(2);
    suffix = "Cr";
  } else if (number >= 100000) {
    // Lakhs
    format = (number / 100000).toFixed(2);
    suffix = "L";
  } else if (number >= 1000) {
    // Thousands
    format = (number / 1000).toFixed(2);
    suffix = "K";
  } else {
    format = numeral(number).format("0,0");
  }

  return `${format}${suffix}`;
}

export function fData(number) {
  const format = number ? numeral(number).format("0.0 b") : "";

  return result(format, ".0");
}

function result(format, key = ".00") {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, "") : format;
}
