import React, { useMemo, useState } from "react";
import AppLayout from "../layout/AppLayout";
import { toast } from "react-toastify";
import MultiplayerModel from "../components/atoms/multiplayer";

const data = [
  {
    id: 1,
    entryFee: 199,
    prizePool: 557,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 2,
    entryFee: 999,
    prizePool: 2990,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 3,
    entryFee: 1449,
    prizePool: 4557,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 4,
    entryFee: 1999,
    prizePool: 6237,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 5,
    entryFee: 2999,
    prizePool: 9597,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 6,
    entryFee: 3999,
    prizePool: 12797,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 7,
    entryFee: 4999,
    prizePool: 19797,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 8,
    entryFee: 5999,
    prizePool: 23979,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 9,
    entryFee: 6999,
    prizePool: 28979,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 10,
    entryFee: 7999,
    prizePool: 32979,
    currentPlayers: 0,
    mode: "Multi-Players",
  },
  {
    id: 11,
    entryFee: 8999,
    prizePool: 37979,
    currentPlayers: 0,
    mode: "popular",
  },
];

const Multilplayer = () => {
  const [mode, setMode] = useState("Multi-Players");
  const [openModel, SetOpenModel] = useState(false);
  const [itemData, setData] = useState("");
  const challengeData = useMemo(() => {
    return data.filter(({ mode: itemMode }) => itemMode === mode);
  }, [data, mode]);
  console.log(challengeData);
  const handleOPen = (item) => {
    SetOpenModel(true);
    setData(item);
  };
  const handleChallenge = () => {
    toast.error(
      "Playing Challenge is Currently Disabled as Website is Under Maintenance. Please try again later "
    );
  };
  return (
    <AppLayout>
      <div
        className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0"
        style={{ padding: "1rem", important: "true" }}
      >
        <div className="d-flex flex-column">
          {/* <div className="bg-gray-200 h-100 w-100 p-3 bg-light d-flex align-items-center justify-content-between hstack gap-2 ">
            <div className="input-group flex-1 flex-nowrap">
              <input
                type="number"
                onChange={handleChange}
                value={amount}
                className="form-control"
                min={50}
                max={10000}
                placeholder="Amount"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "auto",
                }}
              />
              <button
                onClick={() => {
                  createChallenge({
                    amount,

                    setAmount,

                    data,
                    mode,
                    socket,
                    userId,
                    username,
                  });
                }}
                className="btn btn-primary w-25"
                style={{
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              >
                Set
              </button>
              <br></br>
            </div>
          </div> */}
          <ul className="m-0 px-2">
            <div className="separator mt-3 mb-3">
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
              &nbsp;{`${mode.charAt(0).toUpperCase()}${mode.slice(1)}`} Mode
              &nbsp;
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            {challengeData?.map((item) => (
              <li
                key={item?.id}
                style={{ listStyle: "none", margin: ".6rem 0rem" }}
              >
                <div className="my-2 card" style={{ borderColor: "gray" }}>
                  <div
                    className="d-flex align-items-center justify-content-between card-body"
                    style={{ padding: "0.5rem 1rem" }}
                  >
                    <div className="d-flex flex-column">
                      <small style={{ fontSize: "11px", textAlign: "start" }}>
                        Win Prize Pool
                      </small>
                      <h5 className="mb-0">
                        <img
                          src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/trophy.png"
                          alt="avatar"
                          style={{ height: "28px", width: "28px" }}
                        />{" "}
                        ₹{item?.prizePool}
                      </h5>
                    </div>
                    <div className="d-flex flex-column">
                      <small
                        style={{
                          fontSize: "11px",
                          textAlign: "start",
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        Entry Fee
                      </small>
                      <button
                        onClick={() => handleOPen(item)}
                        type="button"
                        className="fw-semibold p-1 btn btn-primary"
                      >
                        <small>₹ {item?.entryFee}</small>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between card-header">
                    <small>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="me-1"
                      >
                        <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z"></path>
                        <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z"></path>
                      </svg>
                      0 Players
                    </small>
                    <small>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="me-1"
                      >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                      </svg>
                      <small>0/4</small>{" "}
                      <small className="text-danger fw-medium">
                        (4 spots left)
                      </small>
                    </small>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* <ViewChallenge
          holdModal={holdModal}
          holdChallenge={holdChallenge}
          userId={userId}
          setHoldModal={setHoldModal}
        /> */}
      </div>
      <MultiplayerModel
        data={itemData}
        is_open={openModel}
        setIsOpen={SetOpenModel}
      />
    </AppLayout>
  );
};

export default Multilplayer;
