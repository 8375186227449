import React, { useEffect, useMemo } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import Cards from "./Cards";
import PlayerHistory from "./PlayerHistory";
import PlayerInfo from "./PlayerInfo";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../redux/contstants";

const UserDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state;

  useEffect(() => {
    if (id) {
      dispatch({
        type: DASHBOARD.GET_USER_DETAILS_REQUEST,
        payload: { id },
      });
    }
  }, [dispatch, id]);
  const userDetails = useSelector((state) => state?.dashboardSlice);
  console.log(userDetails?.userDetails);

  const data = userDetails.userDetails;
  const userDetail = useMemo(
    () => ({
      id: data?.user?._id || "N/A",
      username: data?.user?.username || "N/A",
      name: data?.user?.username || "N/A",
      phone: data?.user?.phone || "N/A",
      totalWallet: data?.accountDetails?.wallet || "N/A",
      totalDiamonds: data?.accountDetails?.totalDiamonds || "N/A",
      winningCash: data?.accountDetails?.winningCash || "N/A",
      depositCash: data?.accountDetails?.depositCash || "N/A",
      depositDiamonds: data?.accountDetails?.depositDiamonds || "N/A",
      winningDiamonds: data?.accountDetails?.winningDiamonds || "N/A",
      totalDiamonds: data?.accountDetails?.totalDiamonds || "N/A",
    }),
    [data]
  );

  if (!data) {
    return (
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={"100%"}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (!data.user) {
    return "No Data available at that moment";
  }

  return (
    <Box
      p={{ xs: "1.5rem 1rem", md: "1.5rem 2.5rem" }}
      pt={{ xs: "5rem", lg: "5rem" }}
      pb={{ xs: "2rem" }}
    >
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12} md={6}>
          <Cards data={userDetail} />
        </Grid>
        <Grid item lg={4} xs={12} md={6}>
          <PlayerInfo data={data} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <PlayerHistory data={data?.userHistory} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetails;
