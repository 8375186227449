import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";

const KycDetails = ({ f_open, setOpen }) => {
  const { data: kycData } = useSelector((state) => state.kycReducer);
  const handleClose = () => setOpen(false);

  return (
    <div className="partials">
      <Offcanvas
        show={f_open}
        onHide={handleClose}
        placement={"bottom"}
        className={"h-50"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>KYC Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="text-capitalize">
                <b>name</b>
              </span>
              <span>{kycData?.full_name}</span>
            </div>
            <hr />
          </div>
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="text-capitalize">
                <b>dob</b>
              </span>
              <span>{kycData?.dob}</span>
            </div>
            <hr />
          </div>
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="text-capitalize">
                <b>gender</b>
              </span>
              <span>{kycData?.gender}</span>
            </div>
            <hr />
          </div>
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="text-capitalize">
                <b>address</b>
              </span>
              <span>{kycData?.kycAddress}</span>
            </div>
            <hr />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default KycDetails;
