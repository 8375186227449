import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Divider, Stack, Box, Button } from "@mui/material";

import { getDFilterApi } from "../../apis/dashboard";
import { useDispatch } from "react-redux";
import { DASHBOARD } from "../../redux/contstants";

const StaticFilter = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);

  const handleClick = () => {
    const datas = {
      startDate: startDate.format("YYYY-MM-DD"),
      finishDate: finishDate.format("YYYY-MM-DD"),
    };
    if (startDate && finishDate) {
      const res = dispatch({
        type: DASHBOARD.GET_DFILTER_REQUEST,
        body: datas,
      });
      if (res) {
        console.log(res);
      } else if (err) {
        console.error("DashboardFilter failed:", err);
      }
    }
  };

  return (
    <Box p={2} display={"flex"} gap={3}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            sx={{
              "& .MuiInputLabel-root": { top: "-6px" },
              "& .MuiOutlinedInput-root": { padding: "4px 10px" },
            }}
            size={"small"}
            label="Start Date"
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
            renderInput={(props) => <TextField {...props} />}
          />
          <Divider orientation="vertical" flexItem />
          <DatePicker
            sx={{
              "& .MuiInputLabel-root": { top: "-6px" },
              "& .MuiOutlinedInput-root": { padding: "4px 10px" },
            }}
            label="Finish Date"
            value={finishDate}
            onChange={(newDate) => setFinishDate(newDate)}
            renderInput={(props) => <TextField {...props} />}
          />
        </Stack>
      </LocalizationProvider>
      <Button
        size={"small"}
        variant="contained"
        sx={{
          minWidth: "200px",
          backgroundColor: "green",
          color: "white",
          fontSize: "1rem",
          textTransform: "capitalize",
        }}
        onClick={handleClick}
      >
        Apply
      </Button>
    </Box>
  );
};

export default StaticFilter;
