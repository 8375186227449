import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { fShortenNumber } from "../../../utils/format-number";

import ratingUp from "../../../assets/rating up.png";
import ratingdown from "../../../assets/rating down.png";
// ----------------------------------------------------------------------

export default function AppWidgetSummary({
  title,
  rating,
  btn,
  total,
  icon,
  color = "primary",
  sx,
  dataCount,
  amount,
  ...other
}) {
  return (
    <Card
      component={Stack}
      spacing={2}
      direction="row"
      sx={{
        px: 2,
        py: 2,
        borderRadius: "0px",
        border: "1px solid #D3CBFB",
        boxShadow: "none",
        ...sx,
      }}
      {...other}
    >
      <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#797D8C", fontWeight: "semiBold", fontSize: "14px" }}
          >
            {title}
          </Typography>
          {icon && <Box sx={{ width: 32, height: 32 }}>{icon}</Box>}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5rem 0rem",
          }}
        >
          <Box>
            <Typography>Number</Typography>
            <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
              {total ? fShortenNumber(total) : 0}
            </Typography>
          </Box>

          <Box>
            <Typography>Amount</Typography>
            <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
              {amount ? fShortenNumber(amount) : 0}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: "#CF7E1C",
              textDecoration: "underline",
              fontWeight: "semiBold",
              fontSize: "12px",
            }}
          >
            {btn}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.any,
  rating: PropTypes.string,
  btn: PropTypes.any,
  dataCount: PropTypes.any,
};
