export default function Ludo(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const redirectUrl = `https://game.gotiking.com/index/?status=${status}`;
  return (
    <>
      <iframe
        width="100%"
        height="100%"
        src={redirectUrl}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </>
  );
}
