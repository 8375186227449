import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";
import Scrollbar from "../../../components/sharedComponents/scrollbar";
import {
  useGetCustomersQuery,
  useSearchCustomersQuery,
} from "../../../state/api";

import { Header } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../../redux/contstants";
import { Box, Pagination } from "@mui/material";

// ----------------------------------------------------------------------

export default function LudoUsers() {
  const [page, setPage] = useState(1);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [search, setSearch] = useState("i");

  const [result, setResult] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const [usersFilter, setUserFilter] = useState("all");

  const handleUsersFilter = (event) => {
    setUserFilter(event.target.value);
  };
  // get data
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch the action to trigger the getUser saga
    dispatch({
      type: DASHBOARD.GET_CUSTOMERS_REQUEST,
      payload: { page, usersFilter, filterName },
    });
  }, [dispatch, page, usersFilter, refetch, filterName]);
  const data = useSelector((state) => state?.dashboardSlice);

  // let { data, isLoading, refetch } = useGetCustomersQuery(page);
  // let {
  //   data: searchData,
  //   isLoading: searchIsLoading,
  //   refetch: searchRefetch,
  // } = useSearchCustomersQuery(search);
  // let { data: searchData, isLoading: searchIsLoading, refetch: searchRefetch } = search ? useSearchCustomersQuery(search) : {searchData: null, searchIsLoading: false, searchRefetch: (function() {console.log('hi')})};
  // mutation

  useEffect(() => {
    if (filterName) {
      setResult(data?.users?.allUsers);
      // isLoading = searchIsLoading;
    } else {
      setResult(data?.users?.allUsers);
    }
  }, [page, data, filterName]);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const totalItems = data?.users?.total || 0;
  const totalPages = Math.ceil(totalItems / 50);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: result,
    comparator: getComparator(order, orderBy),
    filterName,
  });
  console.log(result?.length);

  const notFound = !dataFiltered?.length && !!filterName;

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={10}
        >
          <Header
            refetch={() => setRefetch(!refetch)}
            title="Ludo Users"
            isLoading={data?.isLoading}
          />
          <UserTableToolbar
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleUsersFilter={handleUsersFilter}
            usersFilter={usersFilter}
            setUserFilter={setUserFilter}
          />
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ overflow: "unset", height: "500px" }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={result?.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: "userName", label: "Username" },
                  { id: "phoneNumber", label: "Phone Number" },
                  { id: "blockUser", label: "Block User" },
                ]}
              />
              <TableBody>
                {dataFiltered?.slice(0, rowsPerPage)?.map((row) => (
                  <UserTableRow
                    key={row?._id}
                    user={row}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                    refetch={refetch}
                    setRefetch={setRefetch}
                  />
                ))}
                {/* <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, result?.length)}
                /> */}

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box
          py={2}
          px={2}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <Pagination
            count={totalPages}
            onChange={handleChangePage}
            page={page}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Card>
    </>
  );
}
