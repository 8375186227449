import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularLoading from "../../components/atoms/CircularLoading";
import {
  getUserProfileReq,
  updateUserProfileReq,
} from "../../../../redux/actions/user";
import { AVATAR } from "../../../../config";
import KycAlert from "../../components/appbar/components/KycAlert";
import Iconify from "../../../../dashboard/components/sharedComponents/iconify";
import { avatars } from "./data";
import { updateUserAvatarApi } from "../../../../apis/user";

export default function ProfileDetails() {
  const dispatch = useDispatch();

  const { data: userData, isLoading } = useSelector((state) => state.user);
  const [editButton, setEditButton] = useState(false);
  const [userName, setUserName] = useState();
  const [loading, setLoading] = useState(false);
  const [showAvatars, setShowAvatars] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(userData?.profileImage); // Add state for selected avatar

  const handleSubmit = () => {
    const trimmedUserName = userName.trim();

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (!alphanumericRegex.test(trimmedUserName)) {
      toast.error("Name cannot contain special characters");
      return;
    }

    if (trimmedUserName.length < 3) {
      toast.error("Name should be at least 3 characters");
      return;
    }

    try {
      dispatch(updateUserProfileReq({ username: trimmedUserName }));
      setEditButton(false);
    } catch (error) {
      toast.error(error);
      setEditButton(false);
    }
  };

  useEffect(() => {
    setUserName(userData.username);
  }, [userData.username]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "userName") {
      const newValue = value.replace(/\s/g, "");

      if (newValue.length <= 10) {
        const lowercaseValue = newValue.toLowerCase();
        setUserName(lowercaseValue);
      }
    }
  };

  const handleShowAvatarModel = () => {
    setShowAvatars(true);
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
  };

  const handleSaveAvatar = async () => {
    if (!selectedAvatar || selectedAvatar == userData?.profileImage) {
      toast.error("please select avatar");
    }
    // Here you can dispatch an action to update the avatar on the server
    setLoading(true);
    const response = await updateUserAvatarApi({ avatar: selectedAvatar });
    if (response.data.status == 200) {
      dispatch(getUserProfileReq());
      toast.success("Successfully Updated");
    } else {
      toast.success("Request Failed");
    }

    setLoading(false);
    setShowAvatars(false);
  };

  return (
    <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          profile
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-center">
            {!showAvatars && (
              <div style={{ height: "80px", width: "80px" }}>
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    position: "relative",
                    backgroundSize: "contain",
                    backgroundImage: `url('https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${userData?.profileImage}')`,
                  }}
                  className="bg-success rounded-circle position-relative shadow"
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      right: "0px",
                      bottom: "0px",
                      background: "#fff",
                      borderRadius: "4rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleShowAvatarModel}
                  >
                    <Iconify
                      width={16}
                      height={16}
                      icon="fluent:edit-32-filled"
                    />
                  </div>
                </div>
              </div>
            )}
            {showAvatars && (
              <div className="p-3">
                <div className="shadow card">
                  <div className="bg-light text-dark text-capitalize card-header">
                    Select Avatar
                  </div>
                  <div className="card-body">
                    <div className="row row-cols-4 g-2">
                      {avatars.map((item) => (
                        <div
                          key={item?.id}
                          className={`col ${
                            selectedAvatar === item?.id
                              ? "border border-primary rounded-circle p-2 bg-light shadow-lg"
                              : ""
                          }`} // Highlight selected avatar
                          onClick={() => handleAvatarSelect(item?.id)} // Handle avatar click
                        >
                          <img
                            src={item?.avatar}
                            width="60"
                            className="border border-white rounded-circle"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="d-grid mb-2 mt-3">
                      <button
                        type="button"
                        className="text-capitalize btn btn-primary"
                        onClick={handleSaveAvatar}
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularLoading
                            height={"1.5rem"}
                            width={"1.5rem"}
                            color={"white"}
                          />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center mb-3">
            <label className="form-label text-capitalize">username</label>
            <div className="align-self-stretch d-flex align-items-center">
              <input
                required
                name="userName"
                type="text"
                className="form-control me-2"
                onChange={handleChange}
                value={userName || ""}
                disabled={!editButton}
              ></input>
              {editButton ? (
                <button
                  className="btn btn-success text-capitalize btn-sm align-self-stretch "
                  onClick={handleSubmit}
                  style={{ width: "75px" }}
                >
                  {isLoading ? (
                    <CircularLoading
                      height={"1.5rem"}
                      width={"1.5rem"}
                      color={"white"}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-primary text-capitalize btn-sm align-self-stretch "
                  onClick={() => {
                    setEditButton(!editButton);
                  }}
                  style={{ width: "75px" }}
                >
                  edit
                </button>
              )}
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center mb-3">
            <label className="form-label text-capitalize">phone</label>
            <div className="align-self-stretch d-flex align-items-center">
              <input
                type="text"
                className="form-control me-2"
                maxLength={10}
                value={userData.phone || ""}
                disabled
              ></input>
            </div>
          </div>
          <KycAlert variant={"contained"} />
        </div>
      </div>
    </div>
  );
}
