import React from "react";
import { Typography, Box, IconButton, CircularProgress } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

// Header
const Header = ({ title, subtitle, refetch, isLoading }) => {
  return (
    <Box marginLeft={"1.3rem"}>
      {/* Title */}
      <Box display={"flex"} alignItems={"center"}>
        <Typography
          sx={{ fontSize: "24px" }}
          color={"#000000DE"}
          fontWeight="500"
        >
          {title}
        </Typography>
        {isLoading ? (
          <Box ml={4}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <IconButton
            type="button"
            sx={{ p: "10px", marginLeft: "1rem" }}
            onClick={refetch}
          >
            <ReplayIcon
              sx={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          </IconButton>
        )}
      </Box>

      {/* Subtitle */}
      <Typography variant="h5" color={"#000000DE"}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
