import React from "react";

const UpiSuffixSelector = ({ onSelect }) => {
  const upiSuffixes = [
    "@ybl",
    "@paytm",
    "@okaxis",
    "@axl",
    "@upi",
    "@paytmupi",
    "@pay",
    "@paytmpay",
    "@okhdfcbank",
    "@okicici",
    "@oksbi",
    "@ibl",
    "@yesbank",
    "@hdfcbank",
    "@axisbank",
    "@icici",
    "@kotak",
    "@sbi",
    "@jio",
    "@airtel",
    "@upiidfc",
    "@dbs",
    "@kvb",
    "@apl",
    "@aplupi",
    "@yapl",
    "@bharat",
    "@idfcbank",
    "@indusind",
    "@abl",
    "@ptyes",
    "@pthdfc",
    "@okaxisbank",
    "@ptaxis",
    "@mairtel",
    "@ikwik",
    "@mobikwik",
    "@wa",
    "@bankofbaroda",
    "@boi",
    "@cnbc",
    "@csb",
    "@dcb",
    "@easypay",
    "@fino",
    "@gpay",
    "@iob",
    "@jbk",
    "@kbl",
    "@laxmi",
    "@orbit",
    "@payg",
    "@pl",
    "@sbm",
    "@sdb",
    "@simon",
    "@sundaram",
    "@tmb",
    "@utib",
    "@uob",
    "@yespay",
    "@zip",
    "@dena",
    "@iif",
    "@ida",
    "@kdfc",
    "@mupay",
    "@nbp",
    "@rbl",
    "@spp",
    "@unibank",
    "@yob",
    "@axis",
    "@hdfc",
    "@icicibank",
    "@kotakbank",
    "@sbiupipay",
    "@upibank",
    "@paytmcredit",
    "@paytmdebit",
    "@eupay",
    "@gp",
    "@sdfc",
    "@syncpay",
    "@tata",
    "@uni",
    "@vpay",
    "@vp",
  ];

  const suffixStyle = {
    margin: "0 5px",
    borderRadius: "9px",
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const mobileSuffixStyle = {
    ...suffixStyle,
    fontSize: "12px", // Adjust the font size for mobile
    padding: "5px 5px 5px 5px", // Adjust padding for mobile
  };

  return (
    <div
      style={{
        display: "flex",
        overflowX: "auto",
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      {upiSuffixes.map((suffix) => (
        <div
          key={suffix}
          onClick={() => onSelect(suffix)}
          style={mobileSuffixStyle} // Apply mobile styles conditionally
        >
          {suffix}
        </div>
      ))}
    </div>
  );
};

export default UpiSuffixSelector;
