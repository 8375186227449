import React from "react";

import { CSSTransition } from "react-transition-group";
import OtherPlayingChallenges from "./OtherPlayingChallenges";
import ButtonChallenges from "./ButtonChallenges";
import { CDN_URL } from "../../../../config/index";
import startGameAudio from "../../styles/notification.mp3";
const ChallengeList = ({
  mode,
  challenges,
  userId,

  challengeButton,
  viewGame,
  viewHold,
}) => {
  const otherPlayer = challenges
    .filter(
      (item) =>
        (item.state === "playing" || item.state === "hold") &&
        item.mode === mode
    )
    .slice(0, 20);
  const openChallenges = challenges.filter(
    (item) =>
      (item.player?._id === userId ||
        item.creator?._id === userId ||
        item.state === "open") &&
      item.mode === mode
  );
  const memoizedChallenges = React.useMemo(
    () =>
      openChallenges
        ?.filter(
          (item) =>
            (item.player?._id === userId ||
              item.creator?._id === userId ||
              item.state === "open") &&
            item.mode === mode
        )
        .map((item) => {
          // if (item.creator?._id === userId && item.state === "requested") {
          //   new Audio(startGameAudio).play();
          // }
          return (
            <CSSTransition
              key={item._id}
              timeout={100}
              classNames={{
                enter: false ? "card-animation-enter" : "",
                enterActive:
                  item.creator?._id !== userId
                    ? "card-animation-enter-active"
                    : "",
              }}
            >
              <li
                className={
                  item.creator?._id !== userId
                    ? "p-0 overflow-hidden appear-from-left"
                    : "p-0 overflow-hidden"
                }
              >
                {item.creator?._id != userId &&
                item.player?._id != userId &&
                item.state == "playing" ? null : item.state == "open" ||
                  item.state == "requested" ||
                  item.state == "playing" ? (
                  <div>
                    <ButtonChallenges
                      item={item}
                      userId={userId}
                      viewGame={viewGame}
                      challengeButton={challengeButton}
                      viewHold={viewHold}
                    />
                  </div>
                ) : (
                  <>
                    {(item.creator?._id == userId ||
                      item.player?._id == userId) &&
                      (item.state == "hold" ||
                        item.state == "playing" ||
                        item.state == "adminHold") && (
                        <div className="my-2 card">
                          <div className="d-flex align-items-center justify-content-between card-header">
                            <span>Challenge running with</span>
                            <span className="text-success fw-bold">
                              Rs {item.amount}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between card-body">
                            <div className="d-flex align-items-center flex-grow-1">
                              <div style={{ height: "24px", width: "24px" }}>
                                <div
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "-5px",
                                    backgroundSize: "contain",
                                    backgroundImage: `url(https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${item?.player?.profileImage})`,
                                  }}
                                  className="bg-success rounded-circle position-relative"
                                ></div>
                              </div>
                              {
                                <div>
                                  <span
                                    className="fw-semibold text-truncate text-start"
                                    style={{ width: "100px" }}
                                  >
                                    {item.state == "open"
                                      ? `${item?.creator?.username.slice(
                                          0,
                                          5
                                        )}...`
                                      : item.creator._id == userId
                                        ? `${item?.player?.username.slice(
                                            0,
                                            5
                                          )}...`
                                        : `${item?.creator?.username.slice(
                                            0,
                                            5
                                          )}...`}
                                  </span>
                                </div>
                              }
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="hstack gap-2 minBreakpoint-xs">
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() => {
                                    viewHold(item);
                                  }}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </li>
            </CSSTransition>
          );
        }),
    [challenges, viewGame, viewHold]
  );
  return (
    <ul style={{ padding: 0 }} className="challenge-list">
      {memoizedChallenges}
      <OtherPlayingChallenges otherPlayer={otherPlayer} mode={mode} />
    </ul>
  );
};

export default ChallengeList;
