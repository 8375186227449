//landingpage
import "../../styles/style.css";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import Offcanvas from "react-bootstrap/Offcanvas";
import AppLayout from "../../layout/AppLayout";
import { useSelector } from "react-redux";
import ComingSoonBadge from "../../components/atoms/ComingSoonBadge";
import KycAlert from "../../components/appbar/components/KycAlert";
import "./styles.css";
import classicRules from "../../../../assets/classic-ludo-rules.jpg";

const tabs = [
  { id: 1, name: "App-Classic", value: "app-classic" },
  { id: 2, name: "App-Popular", value: "app-popular" },
];

export default function LandingPage({ socket }) {
  const [f_open, setOpen] = useState(false);
  const [isTab, setIsTab] = useState("app-classic");
  const handleClose = () => setOpen(false);
  const settings = useSelector((state) => state.settingReducer);
  const { data: userData } = useSelector((state) => state.user);
  const location = useLocation();
  useEffect(() => {
    const spinWheelImage = document.querySelector(".spinwheel-animate-once");

    // Add the class for multiple animations on load and route change
    spinWheelImage.classList.add("spinwheel-animate-multiple");

    // Remove the class after the animation ends to allow for hover effect
    const handleAnimationEnd = () => {
      spinWheelImage.classList.remove("spinwheel-animate-multiple");
    };

    spinWheelImage.addEventListener("animationend", handleAnimationEnd);

    // Cleanup event listener on component unmount
    return () => {
      spinWheelImage.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [location]);
  const toggleSubTab = (value) => {
    setIsTab(value);
  };

  return (
    <AppLayout socket={socket}>
      <div className="partials">
        <Offcanvas
          show={f_open}
          onHide={handleClose}
          placement={"bottom"}
          style={{ height: "auto" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Game Rules</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {tabs?.map((item) => (
              <span
                key={item.value}
                onClick={() => toggleSubTab(item.value)}
                className={`text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill ${
                  isTab === item.value ? "text-white bg-primary" : ""
                }`}
                style={{ cursor: "pointer" }}
              >
                {item.name}
              </span>
            ))}
            {isTab && (
              <div style={{ width: "100%", marginTop: "1rem" }}>
                <img
                  src={classicRules}
                  alt="classic"
                  class="img-fit"
                  style={{ width: "100%" }}
                />
              </div>
            )}
            {/* <div className="iframe-container">
              <iframe
                src="https://www.youtube.com/embed/2IcRDUUsjBg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {!userData?.kycVerified && <KycAlert variant={"fulfilled"} />}

      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0 position-relative flex-grow-1">
        <div className="p-0 container-fluid position-relative">
          <div
            role="alert"
            className="fade d-flex align-items-center justify-content-between alert alert-warning show text-start"
            style={{ fontSize: "0.9rem" }}
          >
            <span>
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="20"
                  height="20"
                  fill="red"
                >
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </svg>{" "}
                &nbsp;&nbsp;Notice:-{settings.setAlertMessage} ! | 🙏
              </b>
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
            <h1 className="text-capitalize text-start">games</h1>

            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <Link
                  className="bg-light border  rounded-circle d-flex align-items-center justify-content-center text-dark "
                  to="/spinWin"
                  style={{
                    height: "50px",
                    width: "50px",
                    zIndex: 10,
                  }}
                >
                  <img
                    src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/spinWheel.webp"
                    height="30px"
                    alt="spin wheel icon"
                    className="spinwheel-animate-once"
                  />
                </Link>

                <Link
                  className="bg-light border  rounded-circle d-flex align-items-center justify-content-center text-dark"
                  to="/support"
                  style={{
                    height: "50px",
                    width: "50px",
                    zIndex: 10,
                  }}
                >
                  <img
                    src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/liveChat.webp"
                    height="30px"
                    alt="support icon"
                  />
                </Link>
              </div>
              <button
                onClick={() => {
                  setOpen(true);
                }}
                type="button"
                className="d-flex align-items-center btn btn-outline-primary btn-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="me-1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                </svg>
                <span className="text-capitalize">Rules</span>
              </button>
            </div>
          </div>
          <div className="mb-3 mt-5 gx-3 row">
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
              }}
            >
              <Link className="text-decoration-none text-black" to="/play">
                <div>
                  <picture>
                    <source
                      media="(min-width: 1024px)"
                      srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_classic.png"
                      type="image/webp" // Specify the image type for WebP format
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_classic.png"
                      type="image/webp" // Specify the image type for WebP format
                    />
                    <img
                      src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_classic.png" // Provide a fallback image for browsers that don't support <picture>
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "auto",
                      }}
                      alt="landing"
                    />
                  </picture>
                </div>
              </Link>
            </div>

            <div
              className="col"
              style={{
                paddingRight: "0.5px",
              }}
            >
              <Link
                className="text-decoration-none text-black"
                to="/popular-game"
              >
                <div>
                  <picture>
                    <source
                      media="(min-width: 1024px)"
                      srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_popular.png"
                      type="image/webp" // Specify the image type for WebP format
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_popular.png"
                      type="image/webp" // Specify the image type for WebP format
                    />
                    <img
                      src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/app_popular.png" // Provide a fallback image for browsers that don't support <picture>
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "auto",
                      }}
                      alt="landing"
                    />
                  </picture>
                </div>
              </Link>
            </div>
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
                pointerEvents: "none",
              }}
            >
              <div className="position-relative">
                <Link className="text-decoration-none text-black" to="/quick">
                  <div>
                    <picture>
                      <img
                        src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/classic_gotiking.png"
                        alt="ludo classic"
                        className="rounded-3"
                        style={{
                          width: "100%",
                          sor: "pointer",
                          height: "auto",
                        }}
                      />
                    </picture>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="mb-3 gx-3 row">
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
                pointerEvents: "none",
              }}
            >
              <div
                className="position-relative"
                style={{ cursor: "not-allowed" }}
              >
                <div>
                  <picture>
                    <img
                      src="	https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/oneToken.webp"
                      alt="ludo classic"
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "not-allowed",
                        height: "auto",
                      }}
                    />
                  </picture>
                </div>
              </div>
            </div>

            <div
              className="col"
              style={{
                paddingRight: "0.5px",
                pointerEvents: "none",
              }}
            >
              <div className="position-relative">
                <Link
                  className="text-decoration-none text-black"
                  to="/multiplayer"
                >
                  <div>
                    <picture>
                      <img
                        src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/multiPlayer.png"
                        alt="ludo classic"
                        className="rounded-3"
                        style={{
                          width: "100%",

                          height: "auto",
                        }}
                      />
                    </picture>
                  </div>
                </Link>
              </div>
            </div>

            <div
              className="col"
              style={{
                paddingRight: "0.5px",
                pointerEvents: "none",
              }}
            >
              <div
                className="position-relative"
                style={{ cursor: "not-allowed" }}
              >
                <div>
                  <picture>
                    <img
                      src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/quick_new.png"
                      alt="ludo classic"
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "not-allowed",
                        height: "auto",
                      }}
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="gx-3 row">
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
              }}
            >
              <div
                className="position-relative"
                style={{ cursor: "not-allowed" }}
              >
                <div>
                  <picture>
                    <img
                      src="https://ludoplayers.com/static/media/call_break.fc5d0bc808caa7f16c11.webp"
                      alt="ludo classic"
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "not-allowed",
                        height: "auto",
                      }}
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
              }}
            >
              <div
                className="position-relative"
                style={{ cursor: "not-allowed" }}
              >
                <div>
                  <picture>
                    <img
                      src="https://ludoplayers.com/static/media/rummy.cee1d0baaa7a5dbbba62.webp"
                      alt="ludo classic"
                      className="rounded-3"
                      style={{
                        width: "100%",
                        cursor: "not-allowed",
                        height: "auto",
                      }}
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                paddingRight: "0.5px",
              }}
            >
              <div
                className="position-relative"
                style={{ cursor: "not-allowed" }}
              >
                <div>
                  <picture>
                    <img
                      src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/poker.png"
                      alt="ludo classic"
                      className="rounded"
                      style={{
                        width: "100%",
                        cursor: "not-allowed",
                        height: "auto",
                        borderRadius: "50px", // Adjust the value as per your requirement
                      }}
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </AppLayout>
  );
}
