import Cookies from "js-cookie";
import { axiosConfig } from "./axiosConfig";

const token = Cookies.get("token");

export const getHistoryApi = async (datas) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(
      `/history/${datas?.page}/${datas?.category}/${datas?.subCategory}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
