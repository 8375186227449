import { spawn } from "redux-saga/effects";

import authSaga from "./authSaga";
import userSaga from "./userSaga";
import walletSaga from "./walletSaga";
import dashboardSaga from "./dashboardSaga";

export default function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(userSaga);
  yield spawn(walletSaga);
  yield spawn(dashboardSaga);
}
