import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const KycGuide = ({ f_open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <div className="partials">
      <Offcanvas
        show={f_open}
        onHide={handleClose}
        placement={"bottom"}
        className={"h-50"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>How to complete KYC?</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="iframe-container">
            <iframe
              src="https://www.youtube.com/embed/2IcRDUUsjBg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default KycGuide;
