import React, { useState } from "react";
import { BiDollarCircle } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { AiFillWarning } from "react-icons/ai";
import { useSelector } from "react-redux";
import ConfirmCanvas from "../../components/atoms/ConfirmCanvas";

export default function Metrics({ socket }) {
  const { data } = useSelector((state) => state.user);
  const { data: wallet } = useSelector((state) => state.wallet);
  const { LogoutButton } = useSelector((state) => state.settingReducer);
  const [is_open, setIsOpen] = useState(false);

  return (
    <>
      <ConfirmCanvas is_open={is_open} setIsOpen={setIsOpen} socket={socket} />
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div className="mb-3 shadow card">
          <div className="bg-light text-dark text-capitalize card-header">
            Metrics
          </div>
          <div className="card-body">
            <div className="g-0 gx-2 mb-2 row">
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div
                    style={{ fontSize: "0.9rem" }}
                    className="text-capitalize text-start px-2 card-header"
                  >
                    <div className="hstack gap-1 minBreakpoint-xs">
                      <img
                        alt="icon"
                        width={"16px"}
                        src="https://ludoplayers.com/static/media/sword.9cc91e4925dc62491c20.webp"
                      />
                      <span>games played</span>
                    </div>
                  </div>
                  <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                    {data?.gamesPlayed}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div
                    style={{ fontSize: "0.9rem" }}
                    className="text-capitalize text-start px-2 card-header"
                  >
                    <div className="hstack gap-1 minBreakpoint-xs">
                      <BiDollarCircle />
                      <span>Chips won</span>
                    </div>
                  </div>
                  <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                    {wallet.totalWin &&
                      wallet?.totalWin.toFixed(
                        wallet?.totalWin % 1 !== 0 ? 2 : 0
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="g-0 gx-2 mb-2 row">
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div
                    style={{ fontSize: "0.9rem" }}
                    className="text-capitalize text-start px-2 card-header"
                  >
                    <div className="hstack gap-1 minBreakpoint-xs">
                      <HiUsers />
                      <span>Referal Earning</span>
                    </div>
                  </div>
                  <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                    {wallet.totalWin &&
                      wallet?.referelBalance?.toFixed(
                        wallet?.referelBalance % 1 !== 0 ? 2 : 0
                      )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div
                    style={{ fontSize: "0.9rem" }}
                    className="text-capitalize text-start px-2 card-header"
                  >
                    <div className="hstack gap-1 minBreakpoint-xs">
                      <AiFillWarning />
                      <span>Penalty</span>
                    </div>
                  </div>
                  <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                    {wallet.totalWin &&
                      wallet?.totalPenalty?.toFixed(
                        wallet?.totalPenalty % 1 !== 0 ? 2 : 0
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!LogoutButton && (
          <div className="d-grid py-2">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="text-capitalize btn btn-outline-danger"
            >
              LOG OUT
            </button>
          </div>
        )}
      </div>
    </>
  );
}
