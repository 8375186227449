import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { put, takeLatest } from "redux-saga/effects";
import { DASHBOARD } from "../contstants";

import { getUserProfileSuccess } from "../actions/user";
import {
  addSettingsError,
  addSettingsLoading,
  addSettingsSuccess,
  adminLoginLoading,
  adminLoginSuccess,
  getAdminsError,
  getAdminsLoading,
  getAdminsSuccess,
  getCustomersError,
  getCustomersLoading,
  getCustomersSuccess,
  getDashboardError,
  getDashboardLoading,
  getDashboardSuccess,
  getDFilterError,
  getDFilterLoading,
  getDFilterSuccess,
  getHoldGamesError,
  getHoldGamesLoading,
  getHoldGamesSearchError,
  getHoldGamesSearchLoading,
  getHoldGamesSearchSuccess,
  getHoldGamesSuccess,
  getSettingsError,
  getSettingsLoading,
  getSettingsSuccess,
  getTotalGamesError,
  getTotalGamesLoading,
  getTotalGamesSearchError,
  getTotalGamesSearchLoading,
  getTotalGamesSearchSuccess,
  getTotalGamesSuccess,
  getUserError,
  getUserLoading,
  getUserSuccess,
  getLudoNameLoading,
  getLudoNameError,
  getLudoNameSuccess,
  updateHoldGameLoading,
  updateHoldGameSuccess,
  updateCustomersLoading,
  updateCustomersSuccess,
  updateCustomersError,
  getUserDetailsError,
  getUserDetailsSuccess,
  getUserDetailsLoading,
} from "../actions/dashboard";
import {
  addSettingsApi,
  getAdminsApi,
  getCustomersApi,
  getDashboardApi,
  getDFilterApi,
  getHoldGamesApi,
  getSearchHoldGamesApi,
  getSettingsApi,
  getTotalGamesApi,
  getTotalGamesSearchApi,
  getUserApi,
  getLudoNameByIdApi,
  updateHoldGameApi,
  updateCustomersApi,
  getUserDetailsApi,
} from "../../apis/dashboard";

function* admiLogin(param) {
  yield put(adminLoginLoading(true));
  const data = param?.payload?.data;
  console.log(data);
  if (data.status === 200) {
    yield put(getUserProfileSuccess(data));
    Cookies.set("token", data.data?.jwtToken?.jwtToken, { expires: 30 });
    Cookies.set("fullName", data.data?.fullName, { expires: 30 });
    Cookies.set("userId", data.data?._id, { expires: 30 });

    yield put(adminLoginSuccess(data));
    // param.navigation(`/`);
  } else if (data.status === 404 || data.status === 504) {
    Cookies.remove("token");
    Cookies.remove("fullName");
    Cookies.remove("userId");
    // param.navigation(`/login`);
    toast.error(data.error);
    yield put(adminLoginLoading(false));
  } else if (data.status === 400) {
    toast.error(data.error);
    yield put(adminLoginLoading(false));
  }
}

function* getUserProfile() {
  yield put(getUserLoading(true));
  const data = yield getUserApi();

  if (data.status === 200) {
    yield put(getUserSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getUserError(data.error));
  } else {
    yield put(getUserError(data.error));
    toast.error(data.error);
  }
}
function* getUser(userId) {
  yield put(getUserLoading(true));
  const data = yield getUserApi(userId);

  if (data?.status === 200) {
    yield put(getUserSuccess(data.data));
  } else if (data?.status === 401) {
    toast.error(data?.error);
    yield put(getUserError(data?.error));
  } else {
    yield put(getUserError(data?.error));
    toast.error(data?.error);
  }
}

function* getDFilter(body) {
  yield put(getDFilterLoading(true));
  const data = yield getDFilterApi(body);
  if (data.status === 200) {
    yield put(getDFilterSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getDFilterError(data.error));
  } else {
    yield put(getDFilterError(data.error));
    toast.error(data.error);
  }
}

function* getCustomers(page) {
  yield put(getCustomersLoading(true));
  const data = yield getCustomersApi(page);
  console.log(data);

  if (data.status === 200) {
    yield put(getCustomersSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getCustomersError(data.error));
  } else {
    yield put(getCustomersError(data.error));
    toast.error(data.error);
  }
}
function* getHoldGames(page) {
  yield put(getHoldGamesLoading(true));
  const data = yield getHoldGamesApi(page);
  console.log(data);

  if (data.status === 200) {
    yield put(getHoldGamesSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getHoldGamesError(data.error));
  } else {
    yield put(getHoldGamesError(data.error));
    toast.error(data.error);
  }
}
function* getHoldGamesSearch(keyword) {
  yield put(getHoldGamesSearchLoading(true));
  const data = yield getSearchHoldGamesApi(keyword);
  if (data.status === 200) {
    yield put(getHoldGamesSearchSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getHoldGamesSearchError(data.error));
  } else {
    yield put(getHoldGamesSearchError(data.error));
    toast.error(data.error);
  }
}
function* getTotalGames(page) {
  yield put(getTotalGamesLoading(true));
  const data = yield getTotalGamesApi(page);

  if (data.status === 200) {
    console.log(data);
    yield put(getTotalGamesSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getTotalGamesError(data.error));
  } else {
    yield put(getTotalGamesError(data.error));
    toast.error(data.error);
  }
}
function* getTotalGamesSearch(keyword) {
  yield put(getTotalGamesSearchLoading(true));
  const data = yield getTotalGamesSearchApi(keyword);
  if (data.status === 200) {
    yield put(getTotalGamesSearchSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getTotalGamesSearchError(data.error));
  } else {
    yield put(getTotalGamesSearchError(data.error));
    toast.error(data.error);
  }
}

function* getSettings() {
  yield put(getSettingsLoading(true));
  const data = yield getSettingsApi();
  console.log(data);
  if (data.status === 200) {
    yield put(getSettingsSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getSettingsError(data.error));
  } else {
    yield put(getSettingsError(data.error));
    toast.error(data.error);
  }
}
function* getUserDetails(action) {
  const { id } = action.payload;
  console.log("cehckkkkdata", id);

  yield put(getUserDetailsLoading(true));
  const data = yield getUserDetailsApi(id);
  if (data.status === 200) {
    console.log("checkdataa", data.data);

    yield put(getUserDetailsSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getUserDetailsError(data.error));
  } else {
    yield put(getUserDetailsError(data.error));
    toast.error(data.error);
  }
}
function* getAdmins() {
  yield put(getAdminsLoading(true));
  const data = yield getAdminsApi();
  if (data.status === 200) {
    yield put(getAdminsSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getAdminsError(data.error));
  } else {
    yield put(getAdminsError(data.error));
    toast.error(data.error);
  }
}
function* addSettings(action) {
  const { payload } = action;
  console.log(payload);

  yield put(addSettingsLoading(true));
  const data = yield addSettingsApi(payload?.settings);
  if (data.status === 200) {
    yield put(addSettingsSuccess(data.data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(addSettingsError(data.error));
  } else {
    yield put(addSettingsError(data.error));
    toast.error(data.error);
  }
}
function* getDashboard() {
  yield put(getDashboardLoading(true));
  const data = yield getDashboardApi();
  if (data.status === 200) {
    yield put(getDashboardSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getDashboardError(data.error));
  } else {
    yield put(getDashboardError(data.error));
    toast.error(data.error);
  }
}

function* getLudoName(id) {
  yield put(getLudoNameLoading(true));
  const data = yield getLudoNameByIdApi(id);
  console.log(data);

  if (data.status === 200) {
    yield put(getLudoNameSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(getLudoNameError(data.error));
  } else {
    yield put(getLudoNameError(data.error));
    toast.error(data.error);
  }
}

function* updateHoldGame() {
  yield put(updateHoldGameLoading(true));
  const data = yield updateHoldGameApi();
  console.log(data);

  if (data.status === 200) {
    yield put(updateHoldGameSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(updateHoldGameError(data.error));
  } else {
    yield put(updateHoldGameError(data.error));
    toast.error(data.error);
  }
}
function* updateCustomer(body) {
  console.log(body);

  yield put(updateCustomersLoading(true));
  const data = yield updateCustomersApi(body);
  console.log(data);

  if (data.status === 200) {
    yield put(updateCustomersSuccess(data));
  } else if (data.status === 401) {
    toast.error(data.error);
    yield put(updateCustomersError(data.error));
  } else {
    yield put(updateCustomersError(data.error));
    toast.error(data.error);
  }
}

export default function* dashboardSaga() {
  yield takeLatest(DASHBOARD.ADMIN_LOGIN_REQUEST, admiLogin);

  yield takeLatest(DASHBOARD.GET_DFILTER_REQUEST, getDFilter);
  yield takeLatest(DASHBOARD.GET_CUSTOMERS_REQUEST, getCustomers);
  yield takeLatest(DASHBOARD.GET_HOLD_GAMES_REQUEST, getHoldGames);
  yield takeLatest(DASHBOARD.GET_HOLD_GAMES_SEARCH_REQUEST, getHoldGamesSearch);
  yield takeLatest(DASHBOARD.GET_TOTAL_GAMES_REQUEST, getTotalGames);
  yield takeLatest(DASHBOARD.GET_SETTINGS_REQUEST, getSettings);
  yield takeLatest(DASHBOARD.GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(DASHBOARD.ADD_SETTINGS_REQUEST, addSettings);
  yield takeLatest(DASHBOARD.GET_ADMINS_REQUEST, getAdmins);
  yield takeLatest(DASHBOARD.GET_DASHBOARD_REQUEST, getDashboard);
  yield takeLatest(DASHBOARD.GET_LUDONAME_REQUEST, getLudoName);
  yield takeLatest(DASHBOARD.UPDATE_HOLDGAME_REQUEST, updateHoldGame);
  yield takeLatest(DASHBOARD.UPDATE_CUSTOMERS_REQUEST, updateCustomer);
  yield takeLatest(
    DASHBOARD.GET_TOTAL_GAMES_SEARCH_REQUEST,
    getTotalGamesSearch
  );
}
