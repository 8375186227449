import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-toastify";

const ConfirmLegal = ({ is_open, setIsOpen, amount }) => {
  return (
    <Offcanvas
      style={{ height: "36%" }}
      show={is_open}
      onHide={() => {
        setIsOpen(false);
      }}
      placement="bottom"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Confirm</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className="text-center mb-3"
          style={{
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          <strong>
            <h2 className="m-0">
              Are you sure you want to update Net Loss Limit?
            </h2>
          </strong>
        </div>

        <div className="d-flex flex-column align-content-stretch">
          <button
            onClick={() => {
              localStorage.setItem("legal_amount", amount);
              setIsOpen(false);
              toast.success(`Limit Amount Set ${amount} `);
            }}
            className="btn btn-success btn-lg mb-3"
          >
            "Yes"
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="btn btn-danger btn-lg mb-3"
          >
            No
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ConfirmLegal;
