import smallest from "../../../../../assets/pile-smallest.71da9d5dd6f504d4e43c.webp";
import smallM from "../../../../../assets/pile-small-medium.f0a2aaf6343c0323e32a.webp";
import medium from "../../../../../assets/pile-medium.b230b12a34e2f315cf3c.webp";
import large from "../../../../../assets/pile-large.3b380705abc3a97391bd.webp";
import bag from "../../../../../assets/bag.cf1c95e5b7a73eff95b5.webp";
import largeBag from "../../../../../assets/bag-large.cf5b629b40ea267c0f2e.webp";
import treasure from "../../../../../assets/treasure.7dc17d85cfedf33a1d1b.webp";
import treasureLarge from "../../../../../assets/treasure-large.45d867b8dfc9247ebd7f.webp";

export const data = [
  {
    id: 1,
    qtv: 50,
    price: 99,
    image: smallest,
  },
  {
    id: 2,
    qtv: 220,
    price: 399,
    image: smallM,
    discount: "8%",
  },
  {
    id: 3,
    qtv: 580,
    price: 799,
    image: medium,
    discount: "30%",
  },
  {
    id: 4,
    qtv: 1050,
    price: 1399,
    image: large,
    discount: "33%",
  },
  {
    id: 5,
    qtv: 3580,
    price: 2199,
    image: bag,
    discount: "69%",
  },
  {
    id: 6,
    qtv: 8255,
    price: 3999,
    image: largeBag,
    discount: "76%",
  },
  {
    id: 7,
    qtv: 14850,
    price: 4999,
    image: treasure,
    discount: "83%",
  },
  {
    id: 8,
    qtv: 21420,
    price: 5999,
    image: treasureLarge,
    discount: "86%",
  },
];
