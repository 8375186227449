import React from "react";
import { CDN_URL } from "../../../../config";
import "../../components/atoms/styles.css";
import { useNavigate } from "react-router-dom";

const ViewChallenge = ({ holdModal, holdChallenge, userId, setHoldModal }) => {
  const navigate = useNavigate();
  return (
    <>
      {holdModal && (
        <div
          role="dialog"
          aria-modal="true"
          className={`h-50 offcanvas offcanvas-bottom ${
            holdModal ? "show" : ""
          }`}
          tabIndex="-1"
          style={{ visibility: "visible", height: "auto", maxWidth: "480px" }}
        >
          <div className="offcanvas-header">
            <div
              className="text-capitalize d-flex flex-column align-items-start justify-content-start offcanvas-title h5"
              style={{ fontSize: "1.3rem" }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>room code:&nbsp;</span>
                <span>{holdChallenge?.roomCode}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Your Result:&nbsp;</span>
                <span>
                  I{" "}
                  {holdChallenge?.creator?._id === userId
                    ? holdChallenge?.results?.creator?.result
                    : holdChallenge?.results?.player?.result}
                </span>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setHoldModal(false);
              }}
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="card">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <img
                        src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${holdChallenge?.player?.profileImage}`}
                        alt="avatar"
                      />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{ width: "80px" }}
                    >
                      {holdChallenge?.player?.username}
                    </span>
                  </div>
                  <div>
                    <img
                      src="https://ludoplayers.com/static/media/vs.c153e22fa9dc9f58742d.webp"
                      height="40"
                      alt="vs"
                    />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <img
                        src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${holdChallenge?.creator?.profileImage}`}
                        alt="avatar"
                      />
                    </div>
                    <span
                      className=" fw-semibold text-truncate"
                      style={{ width: "80px" }}
                    >
                      {holdChallenge?.creator?.username}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">
                    ₹{holdChallenge?.amount}
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <p style={{ fontSize: "0.8rem" }}>
              Your Game Result is Successfully Posted. Please allow us 2-5
              minutes to review &amp; update your game. If you have Posted Wrong
              Result or Screenshot, kindly&nbsp;
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={() => navigate("/support")}
              >
                Contact Support
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewChallenge;
