import React, { useState } from 'react';
import { Email } from '@mui/icons-material';
import AdminChips from './AdminChips';
import {
  Box,
  useTheme,
  CircularProgress,
  Grid,
  Typography,
  Card,
} from '@mui/material';
import { useGetSuperDashboardQuery } from '../../state/api';
import { Header, StatBox } from '../../components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';
const Dashboard = () => {
  // theme
  const theme = useTheme();

  // State variables
  const [dates, setDates] = useState({
    startDate: null,
    finishDate: null,
    starting: null,
    ending: null,
    dateFilter: false,
  });

  // get data
  const { data, isLoading, refetch } = useGetSuperDashboardQuery({
    startDate: dates.startDate,
    finishDate: dates.finishDate,
  });

  // Event handlers
  const handleClick = async () => {
    const { starting, ending, dateFilter } = dates;
    if (starting && ending && !dateFilter) {
      const startOfDay = starting.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const endOfDay = ending.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      setDates({
        ...dates,
        startDate: startOfDay,
        finishDate: endOfDay,
        dateFilter: true,
      });
    } else if (dateFilter) {
      setDates({
        ...dates,
        dateFilter: false,
        ending: null,
        starting: null,
        finishDate: null,
        startDate: null,
      });
    }
  };

  const calculateNetProfit = (data) => {
    const challengeCommission = data?.commission?.totalCommission || 0;
    const withdrawalCommission =
      data?.transactions?.withdrawComission?.totalAmount;
    const referral = data?.scratchReferAll?.referal?.totalAmount || 0;
    const scratchCard = data?.scratchReferAll?.scratch?.totalAmount || 0;
    const penalty = data?.scratchReferAll?.penalty?.totalAmount || 0;
    const spinAndWin =
      data?.games?.totalSpinAmount + data?.games?.totalSpinGameAmount || 0;
    const signupBonus = data?.scratchReferAll?.bonus?.totalAmount || 0;

    let netProfit =
      penalty +
      challengeCommission +
      withdrawalCommission -
      referral -
      scratchCard -
      spinAndWin -
      signupBonus;

    // Set a floor for net profit (e.g., 0)
    netProfit = Math.max(netProfit, 0);
    return netProfit;
  };

  return (
    <Box
      p={{ xs: '1.5rem 1rem', md: '1.5rem 2.5rem' }}
      pt={{ xs: '5rem', lg: '5rem' }}
      height={'100%'}
    >
      {/* Header */}
      <Header title="Super Stats" />

      <Box p={2} display={'flex'} gap={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              size={'small'}
              label="Start Date"
              value={dates.starting}
              onChange={(newDate) => setDates({ ...dates, starting: newDate })}
            />
            <Divider orientation="vertical" flexItem />
            <DatePicker
              label="Finish Date"
              value={dates.ending}
              onChange={(newDate) => setDates({ ...dates, ending: newDate })}
            />
          </Stack>
        </LocalizationProvider>
        <Button
          size={'small'}
          variant="contained"
          sx={{
            minWidth: '200px',
            backgroundColor: dates.dateFilter ? 'red' : 'green',
            color: 'white',
            fontSize: '1rem',
            textTransform: 'capitalize',
          }}
          onClick={handleClick}
        >
          {dates.dateFilter ? 'Cancel' : 'Apply'}
        </Button>
      </Box>

      <div>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              height: '60vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <div>
            <Box pt="20px">
              <Grid container rowSpacing={4} columnSpacing={{ xs: 4, md: 8 }}>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Total Users"
                    value={data?.accounts?.bonusCount || 0}
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt="20px">
              <Typography variant="h2" gutterBottom>
                Challenges
              </Typography>
              <Grid container rowSpacing={4} columnSpacing={{ xs: 4, md: 8 }}>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Running"
                    value={data?.playingCount || 0}
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Hold"
                    value={data?.holdCount || 0}
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Resolved"
                    value={data.resolvedChallenges?.totalCount || 0}
                    totalAmount={data.resolvedChallenges?.totalAmount || 0}
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt="20px">
              <Typography variant="h2" gutterBottom>
                Transactions
              </Typography>
              <Grid container rowSpacing={4} columnSpacing={{ xs: 4, md: 8 }}>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Pending Withdraws"
                    value={data?.transactions?.pendingWithdraw?.totalCount || 0}
                    totalAmount={
                      data?.transactions?.pendingWithdraw?.totalAmount || 0
                    }
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Success Deposits"
                    value={
                      data?.transactions?.successTransactions?.totalCount || 0
                    }
                    totalAmount={
                      data?.transactions?.successTransactions?.totalAmount || 0
                    }
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Success Withdraw"
                    value={data?.transactions?.successWithdraw?.totalCount || 0}
                    totalAmount={
                      data?.transactions?.successWithdraw?.totalAmount || 0
                    }
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StatBox
                    title="Referal"
                    value={data?.scratchReferAll?.referal?.totalCount || 0}
                    totalAmount={
                      data?.scratchReferAll?.referal?.totalAmount || 0
                    }
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt="20px">
              <Typography variant="h2" gutterBottom>
                Accounts
              </Typography>
              <Grid container rowSpacing={4} columnSpacing={{ xs: 4, md: 8 }}>
                <Grid item xs={12} md={6}>
                  {/* Total Customers */}
                  <StatBox
                    title="Wallet Balance"
                    value={data?.accounts?.wallet || 0}
                    icon={
                      <Email
                        sx={{
                          color: theme.palette.info.light,
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt="20px">
              <Card sx={{ backgroundColor: '#FAFAFA', padding: '1rem 0.5rem' }}>
                <Typography variant="h2" gutterBottom>
                  Commission
                </Typography>

                <Typography variant="h4">
                  Challenge Commission: +
                  <strong>{data?.commission?.totalCommission || 0}</strong>
                </Typography>
                <Typography variant="h4">
                  Withdrawal Commission: +
                  <strong>
                    {data?.transactions?.withdrawComission?.totalAmount || 0}
                  </strong>
                </Typography>
                <Typography variant="h4">
                  Referral: -
                  <strong>
                    {data?.scratchReferAll?.referal?.totalAmount || 0}
                  </strong>
                </Typography>
                <Typography variant="h4">
                  Scratch Card: -
                  <strong>
                    {data?.scratchReferAll?.scratch?.totalAmount || 0}
                  </strong>
                </Typography>
                <Typography variant="h4">
                  Penalty: +
                  <strong>
                    {data?.scratchReferAll?.penalty?.totalAmount || 0}
                  </strong>
                </Typography>
                <Typography variant="h4">
                  Spin and Win: -
                  <strong>
                    {data?.games?.totalSpinAmount +
                      data?.games?.totalSpinGameAmount || 0}
                  </strong>
                </Typography>
                <Typography variant="h4">
                  Signup Bonus: -
                  <strong>
                    {data?.scratchReferAll?.bonus?.totalAmount || 0}
                  </strong>
                </Typography>

                <Typography variant="h3">
                  Net Profit:{' '}
                  <strong>{calculateNetProfit(data).toFixed(0)}</strong>
                </Typography>
              </Card>
            </Box>
            <Grid item lg={4} xs={12}>
              <AdminChips data={data?.histories && data?.histories} />
            </Grid>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Dashboard;
