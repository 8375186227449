import React from 'react';
import {
  Card,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useGetTransactionByIdQuery } from '../../state/api';

const WrDetails = ({ handleCloseModal, openModal, selectedWithdraw }) => {
  const { data } = useGetTransactionByIdQuery(
    `${selectedWithdraw.transactionId}`,
  );

  return (
    <Card>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle style={{ backgroundColor: 'black', color: 'white' }}>
          Withdrawal Details
        </DialogTitle>
        <DialogContent>
          <Typography>UPI: {data?.upiId}</Typography>
          <Typography>UTR: {data?.orderId}</Typography>
          <Typography>Status: {selectedWithdraw?.status}</Typography>
          <Typography>Reason: {selectedWithdraw?.reason}</Typography>
          <Typography>Amount: {selectedWithdraw?.amount}</Typography>

          <Typography>
            Duplicate Account Holder: {selectedWithdraw?.approvedTime || 'N/A'}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default WrDetails;
