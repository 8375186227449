import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Card,
  Typography,
  Button,
  Pagination,
} from '@mui/material';

import { toast } from 'react-toastify';
import {
  useGetOverviewQuery,
  useUpdateStateMutation,
  useSearchOverviewQuery,
} from '../../state/api';
import { Header, SearchBox } from '../../components';

const Deposits = () => {
  // values for backend
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(null);

  // get data
  let { data, isLoading, refetch } = useGetOverviewQuery(page);
  let {
    data: searchData,
    isLoading: isSearchLoading,
    refetch: searchRefetch,
  } = useSearchOverviewQuery(search);

  // mutation
  const [updateState] = useUpdateStateMutation();

  //pagination
  const totalItems = data?.total || 0;
  const totalPages = Math.ceil(totalItems / 12);

  useEffect(() => {
    if (search != '') {
      searchRefetch(search);
      setResult(searchData?.transactions);
    } else {
      setResult(data?.transactions);
    }
  }, [search, page]);
  useEffect(() => {
    setLoading(isLoading || isSearchLoading);
    if (searchData) {
      setResult(searchData?.transactions);
    } else {
      refetch(page);
      setResult(data?.transactions);
    }
  }, [data, searchData, page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  //search
  const handleChange = (e) => {
    const searchTerm = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setSearch(searchTerm);
        searchRefetch({ search: searchTerm });
      }, 800),
    );
  };

  const handleStateUpdate = (productId, newState) => {
    // Send request to update state
    updateState({ id: productId, state: newState })
      .unwrap()
      .then((response) => {
        refetch();
        toast.success('State updated successfully');
        console.log('State updated successfully:', response);
      })
      .catch((error) => {
        toast.error('Error updating state');

        console.log('Failed to update state:', error);
      });
  };

  return (
    <Box
      p={{ xs: '1.5rem 1rem', md: '1.5rem 2.5rem' }}
      pt={{ xs: '5rem', lg: '5rem' }}
      height={'100%'}
    >
      {/* Header */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Header title="DEPOSITS" refetch={refetch} isLoading={loading} />
        <SearchBox
          handleOnchange={handleChange}
          placeholder={'Search by Username or Phone'}
        />
      </Box>

      <Box pt="20px" height={'100%'}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          height={loading ? '100%' : undefined}
        >
          {!loading && result && result.length > 0 ? (
            result.map((transaction, index) => (
              <Grid item xs={12} md={6} xl={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: '#FAFAFA',
                    padding: '0.6rem 0.5rem',
                    height: '130px',
                  }}
                >
                  <Box
                    gridColumn="span 2"
                    gridRow="span 1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                    border={1}
                    borderColor={'#e0e0e0'}
                    p={{ xs: '0.4rem', md: '0.4rem 1rem' }}
                    flex="1 1 100%"
                    backgroundColor={'#fff'}
                    borderRadius="0.55rem"
                    height={'100%'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                      height={'100%'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        width={'100%'}
                        height={'100%'}
                      >
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            border={1}
                            minWidth={'150px'}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            {transaction?.userId?.username}
                          </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            padding={'0.3rem'}
                            minWidth={'150px'}
                            border={1}
                            textAlign={'center'}
                            sx={{
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              borderColor: '#e0e0e0',
                            }}
                          >
                            Amount:{' '}
                            <span style={{ color: 'green' }}>
                              Rs{transaction?.amount}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent="space-around"
                      height={'100%'}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="600"
                        padding={'0.3rem'}
                        border={1}
                        textAlign={'center'}
                        sx={{
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          borderColor: '#e0e0e0',
                        }}
                      >
                        {transaction?.orderId}
                      </Typography>
                      <Typography
                        variant="span"
                        fontWeight="600"
                        sx={{ color: 'red' }}
                      >
                        UPI
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent="space-around"
                      height={'100%'}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="600"
                        padding={'0.3rem'}
                        border={1}
                        textAlign={'center'}
                        sx={{
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          borderColor: '#e0e0e0',
                        }}
                      >
                        {transaction?.userId?.phone}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'green',
                          color: '#ffff',
                        }}
                        // onClick={() => handleEditUser(transaction)}
                      >
                        {transaction?.status === 0
                          ? 'Pending'
                          : transaction?.status === 1
                            ? 'Success'
                            : transaction?.status === 3
                              ? 'Decline'
                              : null}
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={'100%'}
            >
              {result && result.length === 0 ? (
                search ? (
                  <Typography variant="h4">
                    No Successful withdrawal found
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    No Successful withdrawal found.
                  </Typography>
                )
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Box>
          )}
        </Grid>
        {!search && (
          <Box
            my={2}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              color="secondary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Deposits;
