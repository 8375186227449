const { indianNames } = require("./indianName");

const profileImages = [
  "1.svg",
  "2.svg",
  "3.svg",
  "4.svg",
  "5.svg",
  "6.svg",
  "7.svg",
  "8.svg",
  "9.svg",
];

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const gamesArray = [];

for (let i = 1; i <= 25; i++) {
  const creatorName = indianNames[getRandomInt(0, indianNames.length - 1)];
  const playerName = indianNames[getRandomInt(0, indianNames.length - 1)];
  const creatorProfileImage =
    profileImages[getRandomInt(0, profileImages.length - 1)];
  const playerProfileImage =
    profileImages[getRandomInt(0, profileImages.length - 1)];
  const amount = getRandomInt(1, 20) * 50;

  const game = {
    _id: i,
    creator: {
      profileImage: creatorProfileImage,
      username: creatorName,
    },
    player: {
      profileImage: playerProfileImage,
      username: playerName,
    },
    amount: amount,
  };

  gamesArray.push(game);
}

module.exports = gamesArray;
