import React from 'react';
import {
  Modal,
  Fade,
  Box,
  Grid,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Card,
} from '@mui/material';
import {
  formatDate,
  formatToTime,
  getTimeDifference,
} from '../utils/helpingFunctions';
import CloseIcon from '@mui/icons-material/Close';

const Result = ({ showModal, setShowModal, Loading, editGame }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
 

  // Find the creator player from aPlayers
  const creatorData = editGame?.ludoking?.result?.aPlayers.find(
    (player) => player._userId === editGame?.ludoking?.creatorId,
  );
  const playerData = editGame?.ludoking?.result?.aPlayers.find(
    (player) => player._userId !== editGame?.ludoking?.creatorId,
  );

  // Check if result is not found or not an object
  const resultNotFound =
    !editGame?.ludoking?.result ||
    typeof editGame?.ludoking?.result !== 'object';

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
    >
      <Fade in={showModal || Loading}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '90%' : '50%',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            overflow: 'auto',
            borderRadius: '10px', // Added border radius for unique styling
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px', // Added padding
              borderBottom: '1px solid #ccc', // Added bottom border
            }}
          >
            <Typography variant="h5">LudoKing Result</Typography> {/* Title */}
            <IconButton onClick={() => setShowModal(false)}>
              {' '}
              {/* Close Button */}
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Body */}
          <Box sx={{ padding: '16px' }}>
            {' '}
            {/* Added padding */}
            {/* Display result not found message if result is not found or not an object */}
            {resultNotFound && <Typography>Result not found</Typography>}
            {/* Display creator player's data if found */}
            {creatorData && !resultNotFound && (
              <div>
                <Typography variant="h6">Creator's Data:</Typography>
                <Typography>creator Result: {creatorData.eStatus}</Typography>
                <Typography>
                  Date: {formatDate(editGame?.ludoking?.result?.timestamp)}
                </Typography>
                <Typography>
                  Time:{' '}
                  {editGame?.ludoking?.result?.timestamp
                    ? formatToTime(editGame?.ludoking?.result?.timestamp)
                    : 'N/A'}
                </Typography>
                <Typography>
                  Game Status: {editGame?.ludoking?.result.eStatus}
                </Typography>
                {/* Display other relevant data here */}
              </div>
            )}
            {playerData && !resultNotFound && (
              <div>
                <Typography variant="h6">player's Data:</Typography>
                <Typography>Player Result: {playerData.eStatus}</Typography>
                {/* Display other relevant data here */}
              </div>
            )}
            {/* Display loading message if Loading state is true */}
            {Loading && <Typography>Loading...</Typography>}
          </Box>

          {/* Footer */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px', // Added padding
              borderTop: '1px solid #ccc', // Added top border
            }}
          >
            {/* You can add additional footer content here */}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Result;
