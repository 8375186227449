import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { CDN_URL } from "../../../config";

import AppLayout from "../layout/AppLayout";
import Closed from "./wallet/Closed";
import BuyPaymentMode from "./wallet/BuyPaymentMode";
import BuyChipsLoader from "./wallet/BuyChipsLoader";
import ScratchPad from "../components/appbar/components/ScratchCard";
import { buttonLinks, rezorButtonLinks } from "../functions/functions";
import {
  buyWalletApi,
  deleteUPIID,
  getAllUpis,
  verifyBuyWalletApi,
} from "../../../apis/wallet";
import CircularLoading from "../components/atoms/CircularLoading";
import { automaticLogoutRequest } from "../../../redux/actions/auth";
import DepositCanvas from "../components/atoms/DepositCanvas";
import SavedUpi from "./SavedUpi";
import UpiSuffixSelector from "../components/Suffix";

const data = [
  { id: 1, upiId: "abc12345@okicici" },
  { id: 2, upiId: "def67890@okhdfc" },
  { id: 3, upiId: "ghi13579@oksbi" },
  { id: 4, upiId: "jkl24680@okaxis" },
];

export default function Buy({ socket }) {
  const dispatch = useDispatch();
  const { data: allUPI } = useSelector((state) => state.allUPIReducer);
  const { buyChips } = useSelector((state) => state.settingReducer);
  const { show } = useSelector((state) => state.scratchReducer);
  const [paymentStatus, setPaymentStatus] = useState("isLoading");
  const [buyPaymentModeOpen, setBuyPaymentModeOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [buyChipsLoader, setBuyChipsLoader] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buy, setBuy] = useState(false);
  const [amount, setAmount] = useState("");
  const [is_open, setIsOpen] = useState(false);
  const [isActive, setActive] = useState("");
  const [upiPay, setUpiPay] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [disable, setDisabled] = useState(false);
  const [showForm, setShowForm] = useState(false);
  console.log(amount);

  const navigate = useNavigate();
  useEffect(() => {
    if (socket) {
      socket.on("paymentStatus", (message) => {
        const data = JSON.parse(message);
        if (data.status === 200) {
          setPaymentStatus("success");
        }
      });
    }
  }, [socket]);
  console.log("CHECKKK1212", allUPI);

  const handleCloseScratchClose = () => {
    window.location.href = "/buy";
    dispatch({ type: "SCRATCH_SHOW", payload: false });
  };

  const handleCloseScratch = () => {
    window.location.href = "/buy";
    if (disable) {
      dispatch({ type: "SCRATCH_SHOW", payload: false });
    }
  };

  const handleOpen = async () => {
    if (amount < 10) {
      toast.error("amount should be greater than 10");
      setDisabled(true);
    } else if (amount > 20000) {
      toast.error("Maximum amount limit is 20000");
      setDisabled(true);
    } else {
      setUpiPay(true);
      const UPI = await getAllUpis();

      if (UPI?.data?.status === 200) {
        dispatch({
          type: "GET_UPI",
          payload: UPI.data.data.upids,
        });
      }
    }
  };
  const savedPay = async () => {
    if (amount < 10) {
      toast.error("amount should be greater than 10");
      setDisabled(true);
    } else if (amount > 20000) {
      toast.error("Maximum amount limit is 20000");
      setDisabled(true);
    } else if (!upiId) {
      toast.error("Please Enter UPI");
      setDisabled(true);
    } else if (!upiId.includes("@")) {
      toast.error("Wrong UPI ID");
      setDisabled(true);
    } else if (upiId.length < 4) {
      toast.error("UPI ID must be at least 4 characters long");
      setDisabled(true);
    } else {
      setBuy(true);
      setIsLoading(true);
      setBuyChipsLoader(true);
      setIsOpen(false);
      const response = await buyWalletApi({
        amount: Number(amount),
        upiId,
      });
      console.log("checkresponsee", response);

      if (response.status === 200) {
        setUpiPay(false);
        setIsLoading(false);
        setAmount("");
        setUpiId("");
        setActive("");
      } else if (response.status === 401) {
        dispatch(automaticLogoutRequest());
        setUpiPay(false);
        setIsLoading(false);
        setAmount("");
        setUpiId("");
        setActive("");
      } else {
        setBuyChipsLoader(false);
        toast.error(response.error);

        setIsLoading(false);

        setUpiId("");
        setActive("");
      }
    }
  };
  const pay = async () => {
    if (amount < 10) {
      toast.error("amount should be greater than 10");
      setDisabled(true);
    } else if (amount > 20000) {
      toast.error("Maximum amount limit is 20000");
      setDisabled(true);
    } else if (!upiId) {
      toast.error("Please Enter UPI");
      setDisabled(true);
    } else if (!upiId.includes("@")) {
      toast.error("Wrong UPI ID");
      setDisabled(true);
    } else if (upiId.length < 4) {
      toast.error("UPI ID must be at least 4 characters long");
      setDisabled(true);
    } else {
      setBuy(true);
      setIsLoading(true);

      const verifyVpa = await verifyBuyWalletApi({
        upiId,
      });

      if (verifyVpa.status === 200) {
        setBuyChipsLoader(true);
        setIsOpen(false);
        const response = await buyWalletApi({
          amount: Number(amount),
          upiId,
        });
        if (response.status === 200) {
          setUpiPay(false);
          setIsLoading(false);
          setAmount("");
          setUpiId("");
          setActive("");
        } else if (response.status === 401) {
          dispatch(automaticLogoutRequest());
          setUpiPay(false);
          setIsLoading(false);
          setAmount("");
          setUpiId("");
          setActive("");
        } else {
          setBuyChipsLoader(false);
          toast.error(response.error);
          setIsLoading(false);
          setUpiId("");
          setActive("");
        }
      } else if (verifyVpa.status === 402) {
        toast.error(verifyVpa.error);
        setIsLoading(false);
        setUpiId("");
      }
    }
  };
  const handleSuffixSelect = (suffix) => {
    const atIndex = upiId.indexOf("@");

    if (atIndex !== -1) {
      // Remove everything after and including the '@' typed by the user
      const newUpiId = upiId.slice(0, atIndex);
      setUpiId(newUpiId + suffix);
    } else {
      // If '@' is not present, simply add the suffix
      setUpiId(upiId + suffix);
    }
  };

  return (
    <>
      <AppLayout>
        <div className="relative">
          {buyChipsLoader && (
            <BuyChipsLoader
              buy={buy}
              status={paymentStatus}
              setBuyChipsLoader={setBuyChipsLoader}
            />
          )}

          <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <button
                  className="btn btn-primary border"
                  onClick={() => navigate(-1)}
                >
                  <BsArrowLeftShort className="me-2" />
                  Back
                </button>
              </div>
              <button
                type="button"
                className="btn btn-outline-primary d-flex align-items-center justify-content-center"
              >
                <img
                  style={{ marginRight: "4px" }}
                  src={`${CDN_URL}svgs/info.svg`}
                  alt="info"
                />
                <p className="m-0 p-0">Guide</p>
              </button>
            </div>
            {!buyChips ? (
              <>
                {buyPaymentModeOpen ? (
                  <BuyPaymentMode
                    paymentUrl={paymentUrl}
                    setBuyChipsLoader={setBuyChipsLoader}
                  />
                ) : (
                  <>
                    {!upiPay && (
                      <>
                        <div className="mb-3 shadow card">
                          <div className="bg-light text-dark text-capitalize card-header">
                            Buy Chips
                          </div>
                          <div className="card-body">
                            <label
                              htmlFor="amount"
                              className="form-label w-100 text-start"
                            >
                              Enter Amount
                            </label>
                            <div className="input-group mb-4">
                              <span className="input-group-text bg-light text-dark">
                                ₹
                              </span>
                              <input
                                onChange={(e) => {
                                  setAmount(e.target.value);
                                }}
                                value={amount}
                                className="form-control"
                                type="number"
                                placeholder="Amount"
                              ></input>
                            </div>
                            <div className="d-flex align-items-center justify-content-evenly mb-4">
                              <button
                                onClick={() => {
                                  setAmount("100");
                                  setActive("100");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "100" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                ₹100
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("200");
                                  setActive("200");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "200" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                ₹200
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("500");
                                  setActive("500");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "500" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                ₹500
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("1000");
                                  setActive("1000");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "1000" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                ₹1000
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("2000");
                                  setActive("2000");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "2000" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                2000
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("5000");
                                  setActive("5000");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "5000" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                5000
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("7500");
                                  setActive("7500");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "7500" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                7500
                              </button>
                              <button
                                onClick={() => {
                                  setAmount("10000");
                                  setActive("10000");
                                }}
                                style={{
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.65rem",
                                  borderRadius: "0.2rem",
                                }}
                                className={`btn ${isActive === "10000" ? "btn-primary" : "btn-outline-primary"} d-flex align-items-center justify-content-center`}
                              >
                                10000
                              </button>
                            </div>

                            <div className="d-grid">
                              <button
                                className="btn btn-primary d-flex align-items-center justify-content-center gap-2"
                                onClick={handleOpen}
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <>
                                    <span>Please Wait...</span>

                                    <CircularLoading
                                      height={"1.5rem"}
                                      width={"1.5rem"}
                                      color={"white"}
                                    />
                                  </>
                                ) : (
                                  "Proceed"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 shadow card">
                          <div className="bg-light text-dark card-header">
                            Summary
                          </div>
                          <div
                            className="card-body"
                            style={{ fontSize: "14px" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                Deposit Amount (Excl. Govt. Tax){" "}
                                <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                                  A
                                </span>
                              </span>
                              <span style={{ color: "green" }}>
                                ₹{Number((amount / 100) * 78.13).toFixed(2)}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Govt. Tax (28% GST)</span>
                              <span>
                                ₹
                                {Number(
                                  (((amount / 100) * 78.13) / 100) * 28
                                ).toFixed(2)}
                              </span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Total</span>
                              <span>₹{Number(amount).toFixed(2)}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                Cashback Bonus
                                <img
                                  src="	https://ludoplayers.com/static/media/pepeking.9d780f94071645c86f5e.png"
                                  width="32"
                                />
                                <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                                  B
                                </span>
                              </span>
                              <span style={{ color: "green" }}>
                                ₹{" "}
                                {Number(
                                  (((amount / 100) * 78.13) / 100) * 28
                                ).toFixed(2)}
                              </span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-bold">
                                Add To Wallet Balance{" "}
                                <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                                  A
                                </span>{" "}
                                +{" "}
                                <span className="bg-opacity-25 bg-primary text-primary fw-medium px-1 rounded-1">
                                  B
                                </span>
                              </span>
                              <span
                                className="fw-medium"
                                style={{ color: "green" }}
                              >
                                ₹ {Number(amount).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="text-capitalize text-secondary">
                            payments secured by
                          </p>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="hstack gap-2 minBreakpoint-xs">
                              <img
                                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg"
                                alt="gpay logo"
                                width={"48"}
                              />
                              <img
                                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg"
                                alt="paytm logo"
                                width={"48"}
                              />
                              <img
                                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg"
                                alt="phone logo"
                                width={"48"}
                              />
                              <img
                                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg"
                                alt="upi logo"
                                width={"48"}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {upiPay && (
                      <>
                        {(allUPI.length <= 0 || showForm) && (
                          <div className="mb-3 shadow card">
                            <div className="bg-light text-dark text-capitalize card-header">
                              UPI Payment
                            </div>
                            <div className="card-body">
                              <label
                                htmlFor="amount"
                                className="form-label w-100 text-start"
                              >
                                Enter Your UPI ID
                              </label>
                              <div
                                className="input-group"
                                style={{
                                  marginBottom: "3px",
                                  position: "relative",
                                }}
                              >
                                <input
                                  onChange={(e) => {
                                    const valueWithoutSpaces = e.target.value
                                      .replace(/\s+/g, "")
                                      .toLowerCase();
                                    setUpiId(valueWithoutSpaces);
                                  }}
                                  value={upiId}
                                  className="form-control"
                                  placeholder="number@bank or name@bank"
                                  style={{ paddingRight: "30px" }} // Extra padding for the cross button
                                />
                                {upiId && (
                                  <button
                                    type="button"
                                    onClick={() => setUpiId("")}
                                    style={{
                                      position: "absolute",
                                      right: "9px", // Adjusted to make space for the UpiSuffixSelector
                                      top: "23%",
                                      transform: "translateY(-50%)",
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                      fontSize: "18px",
                                      lineHeight: "1",
                                    }}
                                  >
                                    &times; {/* This represents the "cross" */}
                                  </button>
                                )}
                                {upiId.includes("@") && (
                                  <UpiSuffixSelector
                                    onSelect={handleSuffixSelect}
                                  />
                                )}
                              </div>

                              <p style={{ fontSize: "13px" }}>
                                UPI ID format is mobilenumber@bank or name@bank
                              </p>
                              <div className="d-grid">
                                <button
                                  onClick={pay}
                                  className="btn btn-primary d-flex align-items-center justify-content-center gap-2"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <>
                                      <span>Please Wait...</span>

                                      <CircularLoading
                                        height={"1.5rem"}
                                        width={"1.5rem"}
                                        color={"white"}
                                      />
                                    </>
                                  ) : (
                                    "Verify and Pay"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {allUPI.length > 0 && !showForm && (
                          <SavedUpi
                            data={allUPI}
                            setUpiId={setUpiId}
                            pay={savedPay}
                            setShowForm={setShowForm} // Add a prop to toggle the form
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <Closed chips={"Buy Chips"} />
            )}
          </div>
        </div>
      </AppLayout>
      <DepositCanvas
        is_open={is_open}
        setIsOpen={setIsOpen}
        title="Deposit Details"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid #999",
            padding: "1rem 0rem",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ marginBottom: "0px" }}>
              Deposit Amount (Excl Govt. tax){" "}
              <span
                style={{
                  background: "#0d6efd4a",
                  borderRadius: "4px",
                  padding: "0rem .5rem",
                  color: "#2e85fb",
                }}
              >
                A
              </span>
            </p>
            <p className="text-success" style={{ marginBottom: "0px" }}>
              ₹{Number((amount / 100) * 78.13).toFixed(2)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ marginBottom: "0px" }}>Govt. Tax (28% GST)</p>
            <p className="text-success" style={{ marginBottom: "0px" }}>
              ₹{Number((((amount / 100) * 78.13) / 100) * 28).toFixed(2)}
            </p>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #999", padding: "1rem 0rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ marginBottom: "0px" }}>Total</p>
            <p className="text-success" style={{ marginBottom: "0px" }}>
              ₹{Number(amount).toFixed(2)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ marginBottom: "0px" }}>
              <span>
                Cashback Bonus{" "}
                <img
                  src="	https://ludoplayers.com/static/media/pepeking.9d780f94071645c86f5e.png"
                  alt="pepeking"
                  width="32"
                ></img>
              </span>
              <span
                style={{
                  background: "#0d6efd4a",
                  borderRadius: "4px",
                  padding: "0rem .5rem",
                  color: "#2e85fb",
                }}
              >
                B
              </span>{" "}
            </p>
            <p style={{ marginBottom: "0px" }} className="text-success">
              ₹ {Number((((amount / 100) * 78.13) / 100) * 28).toFixed(2)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              marginBottom: "0px",
              fontWeight: "700",
              fontSize: "14px",
              margin: ".5rem 0rem",
            }}
          >
            {" "}
            Add To Wallet Balance (Total){" "}
            <span
              style={{
                background: "#0d6efd4a",
                borderRadius: "4px",
                padding: ".2rem .5rem",
                color: "#2e85fb",
              }}
            >
              A
            </span>{" "}
            +{" "}
            <span
              style={{
                background: "#0d6efd4a",
                borderRadius: "4px",
                padding: ".2rem .5rem",
                color: "#2e85fb",
              }}
            >
              B
            </span>
          </p>
          <p style={{ marginBottom: "0px" }} className="text-success">
            ₹ {Number(amount).toFixed(2)}
          </p>
        </div>
        <div>
          <button
            onClick={pay}
            disabled={isDisable || isLoading}
            className="btn btn-primary"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            {isLoading ? (
              <CircularLoading
                height={"1.5rem"}
                width={"1.5rem"}
                color={"white"}
              />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </DepositCanvas>
    </>
  );
}
