import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Routes from "./routing/Routes";
import { automaticLogoutRequest } from "./redux/actions/auth";
import store from "./redux";
import { getPermission } from "./firebase";

export const centerDivStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

function App() {
  const dispatch = useDispatch();
  const checkCookies = (isLoggedIn) => {
    if (isLoggedIn) {
      if (!Cookies.get("userId") || !Cookies.get("token"))
        dispatch(automaticLogoutRequest());
    }
  };
  useEffect(() => {
    if (!Cookies.get("tokenSent")) {
      getPermission(Cookies.get("userId"));
    }
    const interval = setInterval(() => {
      const { data } = store.getState().loginReducer;
      checkCookies(data.isLoggedIn);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="App " style={{ height: "100dvh" }}>
      <ToastContainer position="bottom-right" autoClose={2000} />
      <Router>
        <Fragment>
          <Routes />
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
