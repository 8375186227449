//spinWiin.js
import React, { useState, useEffect, Component } from "react";
import AppLayout from "../../layout/AppLayout";
import WinPopup from "./WinPopup";
import {
  spinWinApi,
  spinWinApi1,
  spinWinApi2,
} from "../../../../apis/spinWinApi";
import { indianUsernames } from "./names";
// import "react-wheel-of-prizes/dist/index.css";
import "./style.css";
import WheelComponent from "./WheelComponent";
import { useSelector } from "react-redux";
import Closed from "../wallet/Closed";
import { Link } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";

const SpinNWin = () => {
  const [winningSegment, setWinningSegment] = useState(null);
  const { spinner } = useSelector((state) => state.settingReducer);

  function generateWinnersList(numWinners) {
    const names = indianUsernames;
    const winnersList = [];

    for (let i = 0; i < numWinners; i++) {
      const randomName = names[Math.floor(Math.random() * names.length)];
      const randomAmount = Math.floor(Math.random() * (50 - 10 + 1)) + 10; // Generating random amounts between 10 and 50

      // Generate a random timestamp within the last 24 hours (in milliseconds)

      winnersList.push({
        name: randomName,
        amount: randomAmount,
      });
    }

    return winnersList;
  }

  const winnersList = generateWinnersList(10);
  const [spin, setSpin] = useState("button-0");
  const [winValue, setWinValue] = useState();
  const [show, setShow] = useState(false);

  const modelClosed = () => {

    // setSpin(false);
  };
  const firstBtnClick = async () => {
    setSpin("button-1");
  };
  const secondBtnClick = async () => {
    setSpin("button-2");
  };

  const segments = [
    "2",
    "5",
    "17",
    "29",
    "3",
    "40",
    "36",
    "1",
    "16",
    "4",
    "37",
  ];
  const segColors = [
    "#F65694",
    "#FFE8A2",
    "#FF9000",
    "#00B9CB",
    "#6DB5FF",
    "#48DEC5",
    "#FEB69E",
    "#6B65ED",
    "#FF8270",
    "#FFCA6A",
    "#007CE9",
  ];
  return (
    <AppLayout>
      {!spinner ? (
        <div>
          <WinPopup
            winValue={winValue}
            show={show}
            setShow={setShow}
            modelClosed={modelClosed}
          />
          <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
            <div className="parent">
              <div>
                <div className="container mt-3 mb-5">
                  <div className="Wheel">
                    <div id="wheelCircle">
                      <WheelComponent
                        spin={spin}
                        setSpin={setSpin}
                        segments={segments}
                        segColors={segColors}
                        onFinished={(winner) => {
                          setWinValue(winner);
                          setShow(true);
                        }}
                        primaryColor="black"
                        primaryColoraround="#ffffffb4"
                        contrastColor="white"
                        buttonText="Spin"
                        isOnlyOnce={false}
                        size={190}
                        upDuration={510}
                        downDuration={4000}
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{
                    margin: "10px",
                    width: "90%",
                    cursor: "pointer",
                    opacity: "0.65",
                  }}
                  onClick={() => firstBtnClick()}
                >
                  Play Daily Free Spin
                </button>

                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{
                    margin: "10px",
                    width: "90%",
                    cursor: "pointer",
                    opacity: "0.65",
                  }}
                  onClick={() => secondBtnClick()}
                >
                  Play 20 Games &amp; Spin Again
                </button>

                <div className="mt-3 list-group">
                  <div className="list-group-item list-group-item-info">
                    <b>Last 24 Hour Winners List</b>
                  </div>
                  {winnersList.map((winner, index) => (
                    <div className="list-group-item" key={index}>
                      <div className="challengeText mx-auto">
                        {winner.name} WON <b>{winner.amount}</b> 💵
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
              <Link to="/">
                <button className="btn btn-primary border">
                  <BsArrowLeftShort className="me-2" />
                  Back
                </button>
              </Link>
            </div>
            <Link
              to="/history"
              className="text-capitalize btn btn-outline-primary"
            >
              Wallet History
            </Link>
          </div>
          <Closed chips={"Spinner"} />
        </div>
      )}
    </AppLayout>
  );
};

export default SpinNWin;
