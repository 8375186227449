import React from "react";
import Countdown from "react-countdown";
import { useState, useEffect } from "react";

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    localStorage.setItem("end_date", "over");
    return <></>;
  } else {
    return (
      <div
        className="text-lowercase fw-bold"
        style={{
          marginBottom: "5px",
          fontSize: "0.6rem",
          top: "40%",
          color: "red",
        }}
      >
        ({seconds})
      </div>
    );
  }
};

const getLocalStorageValue = (s) => localStorage.getItem(s);

export default function CountDown({ enabled, setEnable }) {
  const [data, setData] = useState({ date: Date.now(), delay: 40000 });
  const wantedDelay = 40000;
  useEffect(() => {
    const savedDate = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      if (delta > wantedDelay) {
        if (localStorage.getItem("end_date").length > 0) {
          // localStorage.removeItem("end_date");
        }
      } else {
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);

  return (
    <div>
      <Countdown
        date={data.date + data.delay}
        renderer={renderer}
        onStart={(delta) => {
          if (localStorage.getItem("end_date") == null)
            localStorage.setItem(
              "end_date",
              JSON.stringify(data.date + data.delay),
            );
        }}
        onComplete={() => {
          localStorage.setItem("end_date", "over");
          setEnable("over");
        }}
      />
    </div>
  );
}
