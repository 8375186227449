import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Sidebar } from "../../components";
import { getUserApi } from "../../../apis/dashboard";
import { DASHBOARD } from "../../../redux/contstants";

// Layout
const Layout = () => {
  //handler

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  // is desktop
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  // get user id from redux selector
  const userId = useSelector((state) => state?.global?.userId);
  console.log(userId);
  // const userId = useSelector((state) => state.global.userId);
  // get data
  const dispatch = useDispatch();

  // Access user data from the Redux store
  const user = useSelector((state) => state?.dashboardSlice);
  console.log(user);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      {/* Sidebar */}
      <Sidebar
        user={{}}
        isNonMobile={isNonMobile}
        open={open}
        toggleDrawer={toggleDrawer}
      />

      {/* Navbar */}
      <Box flexGrow={1}>
        <Navbar user={{}} open={open} toggleDrawer={toggleDrawer} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
