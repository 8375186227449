import React, { useMemo, useState } from "react";
import AppLayout from "../layout/AppLayout";
import { toast } from "react-toastify";

const data = [
  {
    id: 1,
    amount: 5,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 2,
    amount: 10,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 3,
    amount: 20,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 4,
    amount: 50,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 5,
    amount: 100,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 6,
    amount: 200,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 7,
    amount: 400,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 8,
    amount: 500,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 9,
    amount: 700,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 10,
    amount: 1000,
    currentPlayers: 0,
    mode: "classic",
  },
  {
    id: 11,
    amount: 2000,
    currentPlayers: 0,
    mode: "popular",
  },
];

const QuickPlay = () => {
  const [mode, setMode] = useState("classic"); // Memoize the filtered data to optimize performance
  const challengeData = useMemo(() => {
    return data.filter(({ mode: itemMode }) => itemMode === mode);
  }, [data, mode]);
  const handleChallenge = () => {
    toast.error(
      "Playing Challenge is Currently Disabled as Website is Under Maintenance. Please try again later "
    );
  };
  return (
    <AppLayout>
      <div
        className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0"
        style={{ padding: "1rem", important: "true" }}
      >
        <div className="d-flex flex-column">
          {/* <div className="bg-gray-200 h-100 w-100 p-3 bg-light d-flex align-items-center justify-content-between hstack gap-2 ">
            <div className="input-group flex-1 flex-nowrap">
              <input
                type="number"
                onChange={handleChange}
                value={amount}
                className="form-control"
                min={50}
                max={10000}
                placeholder="Amount"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "auto",
                }}
              />
              <button
                onClick={() => {
                  createChallenge({
                    amount,

                    setAmount,

                    data,
                    mode,
                    socket,
                    userId,
                    username,
                  });
                }}
                className="btn btn-primary w-25"
                style={{
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              >
                Set
              </button>
              <br></br>
            </div>
          </div> */}
          <ul className="m-0 px-2">
            <div className="separator mt-3 mb-3">
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
              &nbsp;{`${mode.charAt(0).toUpperCase()}${mode.slice(1)}`} Mode
              &nbsp;
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
            </div>

            {challengeData?.map((item) => (
              <li
                key={item?.id}
                style={{ listStyle: "none", margin: ".6rem 0rem" }}
              >
                <div className="card" style={{ border: "1px solid gray" }}>
                  <div className="d-flex align-items-center justify-content-between card-header">
                    <span>Currently Playing: {item?.currentPlayers}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between card-body">
                    <div className="d-flex align-items-center flex-grow-1">
                      <img
                        src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/trophy.png"
                        alt="avatar"
                        style={{ height: "30px", width: "30px" }}
                      />
                      <span
                        className="fw-semibold text-truncate text-success"
                        style={{ width: "100px", fontSize: "120%" }}
                      >
                        Rs {item?.amount}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="hstack gap-2">
                        <button
                          onClick={handleChallenge}
                          className="btn btn-primary playChallange btn-sm"
                        >
                          Play
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* <ViewChallenge
          holdModal={holdModal}
          holdChallenge={holdChallenge}
          userId={userId}
          setHoldModal={setHoldModal}
        /> */}
      </div>
    </AppLayout>
  );
};

export default QuickPlay;
