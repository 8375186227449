import React from 'react';

import { Paper, InputBase, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const SearchBox = ({ handleOnchange, search, setSearch, placeholder }) => {
  return (
    <Paper
      onChange={(e) => handleOnchange(e)}
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, p: '5px' }}
        name="search"
        value={search}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        required
      />
      {search && (
        <IconButton onClick={() => setSearch('')}>
          <ClearIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBox;
