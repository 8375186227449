import { DASHBOARD } from "../contstants";

export const adminLoginRequest = (data, history) => {
  return {
    type: DASHBOARD.ADMIN_LOGIN_REQUEST,
    payload: data,
    navigation: history,
  };
};
export const adminLoginSuccess = (data) => {
  return {
    type: DASHBOARD.ADMIN_LOGIN_SUCCESS,
    payload: data,
  };
};
export const adminLoginError = (error) => {
  return {
    type: DASHBOARD.ADMIN_LOGIN_ERROR,
    payload: error,
  };
};
export const adminLoginLoading = (load) => {
  return {
    type: DASHBOARD.ADMIN_LOGIN_LOADING,
    payload: load,
  };
};

export const getUserRequest = (data, history) => {
  return {
    type: DASHBOARD.GET_USER_REQUEST,
    payload: data,
    navigation: history,
  };
};
export const getUserSuccess = (data) => {
  return {
    type: DASHBOARD.GET_USER_SUCCESS,
    payload: data,
  };
};
export const getUserError = (error) => {
  return {
    type: DASHBOARD.GET_USER_ERROR,
    payload: error,
  };
};
export const getUserLoading = (load) => {
  return {
    type: DASHBOARD.GET_USER_LOADING,
    payload: load,
  };
};

export const getDFilterRequest = (data) => {
  return {
    type: DASHBOARD.GET_DFILTER_REQUEST,
    payload: data,
  };
};
export const getDFilterSuccess = (data) => {
  return {
    type: DASHBOARD.GET_DFILTER_SUCCESS,
    payload: data,
  };
};
export const getDFilterError = (error) => {
  return {
    type: DASHBOARD.GET_DFILTER_ERROR,
    payload: error,
  };
};
export const getDFilterLoading = (load) => {
  return {
    type: DASHBOARD.GET_DFILTER_LOADING,
    payload: load,
  };
};

export const getCustomersRequest = (data) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_REQUEST,
    payload: data,
  };
};
export const getCustomersSuccess = (data) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_SUCCESS,
    payload: data,
  };
};
export const getCustomersError = (error) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_ERROR,
    payload: error,
  };
};
export const getCustomersLoading = (load) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_LOADING,
    payload: load,
  };
};

export const getCustomersSearchRequest = (data) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_SEARCH_REQUEST,
    payload: data,
  };
};
export const getCustomersSearchSuccess = (data) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_SEARCH_SUCCESS,
    payload: data,
  };
};
export const getCustomersSearchError = (error) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_SEARCH_ERROR,
    payload: error,
  };
};
export const getCustomersSearchLoading = (load) => {
  return {
    type: DASHBOARD.GET_CUSTOMERS_SEARCH_LOADING,
    payload: load,
  };
};

export const getHoldGamesRequest = (data) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_REQUEST,
    payload: data,
  };
};
export const getHoldGamesSuccess = (data) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_SUCCESS,
    payload: data,
  };
};
export const getHoldGamesError = (error) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_ERROR,
    payload: error,
  };
};
export const getHoldGamesLoading = (load) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_LOADING,
    payload: load,
  };
};

export const getHoldGamesSearchRequest = (data) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_SEARCH_REQUEST,
    payload: data,
  };
};
export const getHoldGamesSearchSuccess = (data) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_SEARCH_SUCCESS,
    payload: data,
  };
};
export const getHoldGamesSearchError = (error) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_SEARCH_ERROR,
    payload: error,
  };
};
export const getHoldGamesSearchLoading = (load) => {
  return {
    type: DASHBOARD.GET_HOLD_GAMES_SEARCH_LOADING,
    payload: load,
  };
};

export const getTotalGamesSearchRequest = (data) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_SEARCH_REQUEST,
    payload: data,
  };
};
export const getTotalGamesSearchSuccess = (data) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_SEARCH_SUCCESS,
    payload: data,
  };
};
export const getTotalGamesSearchError = (error) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_SEARCH_ERROR,
    payload: error,
  };
};
export const getTotalGamesSearchLoading = (load) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_SEARCH_LOADING,
    payload: load,
  };
};

export const getTotalGamesRequest = (data) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_REQUEST,
    payload: data,
  };
};
export const getTotalGamesSuccess = (data) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_SUCCESS,
    payload: data,
  };
};
export const getTotalGamesError = (error) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_ERROR,
    payload: error,
  };
};
export const getTotalGamesLoading = (load) => {
  return {
    type: DASHBOARD.GET_TOTAL_GAMES_LOADING,
    payload: load,
  };
};

export const getSettingsRequest = (data) => {
  return {
    type: DASHBOARD.GET_SETTINGS_REQUEST,
    payload: data,
  };
};
export const getSettingsSuccess = (data) => {
  return {
    type: DASHBOARD.GET_SETTINGS_SUCCESS,
    payload: data,
  };
};
export const getSettingsError = (error) => {
  return {
    type: DASHBOARD.GET_SETTINGS_ERROR,
    payload: error,
  };
};
export const getSettingsLoading = (load) => {
  return {
    type: DASHBOARD.GET_SETTINGS_LOADING,
    payload: load,
  };
};
export const getUserDetailsRequest = (data) => {
  return {
    type: DASHBOARD.GET_USER_DETAILS_REQUEST,
    payload: data,
  };
};
export const getUserDetailsSuccess = (data) => {
  return {
    type: DASHBOARD.GET_USER_DETAILS_SUCCESS,
    payload: data,
  };
};
export const getUserDetailsError = (error) => {
  return {
    type: DASHBOARD.GET_USER_DETAILS_ERROR,
    payload: error,
  };
};
export const getUserDetailsLoading = (load) => {
  return {
    type: DASHBOARD.GET_USER_DETAILS_LOADING,
    payload: load,
  };
};

export const addSettingsRequest = (data) => {
  return {
    type: DASHBOARD.ADD_SETTINGS_REQUEST,
    payload: data,
  };
};
export const addSettingsSuccess = (data) => {
  return {
    type: DASHBOARD.ADD_SETTINGS_SUCCESS,
    payload: data,
  };
};
export const addSettingsError = (error) => {
  return {
    type: DASHBOARD.ADD_SETTINGS_ERROR,
    payload: error,
  };
};
export const addSettingsLoading = (load) => {
  return {
    type: DASHBOARD.ADD_SETTINGS_LOADING,
    payload: load,
  };
};
export const getAdminsRequest = (data) => {
  return {
    type: DASHBOARD.GET_ADMINS_REQUEST,
    payload: data,
  };
};
export const getAdminsSuccess = (data) => {
  return {
    type: DASHBOARD.GET_ADMINS_SUCCESS,
    payload: data,
  };
};
export const getAdminsError = (error) => {
  return {
    type: DASHBOARD.GET_ADMINS_ERROR,
    payload: error,
  };
};
export const getAdminsLoading = (load) => {
  return {
    type: DASHBOARD.GET_ADMINS_LOADING,
    payload: load,
  };
};

export const getDashboardRequest = (data) => {
  return {
    type: DASHBOARD.GET_DASHBOARD_REQUEST,
    payload: data,
  };
};
export const getDashboardSuccess = (data) => {
  return {
    type: DASHBOARD.GET_DASHBOARD_SUCCESS,
    payload: data,
  };
};
export const getDashboardError = (error) => {
  return {
    type: DASHBOARD.GET_DASHBOARD_ERROR,
    payload: error,
  };
};
export const getDashboardLoading = (load) => {
  return {
    type: DASHBOARD.GET_DASHBOARD_LOADING,
    payload: load,
  };
};

export const getLudoNameRequest = (data) => {
  return {
    type: DASHBOARD.GET_LUDONAME_REQUEST,
    payload: data,
  };
};
export const getLudoNameSuccess = (data) => {
  return {
    type: DASHBOARD.GET_LUDONAME_SUCCESS,
    payload: data,
  };
};
export const getLudoNameError = (error) => {
  return {
    type: DASHBOARD.GET_LUDONAME_ERROR,
    payload: error,
  };
};
export const getLudoNameLoading = (load) => {
  return {
    type: DASHBOARD.GET_LUDONAME_LOADING,
    payload: load,
  };
};

export const updateHoldGameRequest = (data) => {
  return {
    type: DASHBOARD.UPDATE_HOLDGAME_REQUEST,
    payload: data,
  };
};
export const updateHoldGameSuccess = (data) => {
  return {
    type: DASHBOARD.UPDATE_HOLDGAME_SUCCESS,
    payload: data,
  };
};
export const updateHoldGameError = (error) => {
  return {
    type: DASHBOARD.UPDATE_HOLDGAME_ERROR,
    payload: error,
  };
};
export const updateHoldGameLoading = (load) => {
  return {
    type: DASHBOARD.UPDATE_HOLDGAME_LOADING,
    payload: load,
  };
};

export const updateCustomersRequest = (data) => {
  return {
    type: DASHBOARD.UPDATE_CUSTOMERS_REQUEST,
    payload: data,
  };
};
export const updateCustomersSuccess = (data) => {
  return {
    type: DASHBOARD.UPDATE_CUSTOMERS_SUCCESS,
    payload: data,
  };
};
export const updateCustomersError = (error) => {
  return {
    type: DASHBOARD.UPDATE_CUSTOMERS_ERROR,
    payload: error,
  };
};
export const updateCustomersLoading = (load) => {
  return {
    type: DASHBOARD.UPDATE_CUSTOMERS_LOADING,
    payload: load,
  };
};
