// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from "redux";

// Reducers
import { signUpReducer, loginReducer, signupPage1 } from "./auth";
import { user } from "./user";
import { socketReducer } from "./socket";
import { wallet } from "./wallet";
import { wallet1, displaytimer } from "./buychips";
import { history } from "./history";
import { challenge } from "./getChallenge";
import { settingReducer } from "./settings";
import { allBanksReducer } from "./AllBanks";
import { allUpiBanksReducer } from "./AllUpiBanks";
import { kycReducer } from "./kyc";
import { scratchReducer } from "./scratch";
import {
  adminLoginReducer,
  dashboardSlice,
  getCustomerReducer,
  getCustomerSearchReducer,
  getDFilterReducer,
  getUserReducer,
  global,
  LudoName,
} from "./dashboard";
import { allUPIReducer } from "./AllUpi";

export default combineReducers({
  signupPage1,
  socketReducer,
  signUpReducer,
  loginReducer,
  user,
  wallet,
  allUPIReducer,
  challenge,
  settingReducer,
  allBanksReducer,
  allUpiBanksReducer,
  kycReducer,
  scratchReducer,
  wallet1,
  history,
  displaytimer,
  adminLoginReducer,
  global,
  dashboardSlice,
  LudoName,
  // Here you can registering another reducers.
});
