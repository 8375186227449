import React, { useState, useEffect } from "react";
import {
  Card,
  Avatar,
  TextField,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { adminLoginAPI } from "../../apis/dashboard";

const defaultTheme = createTheme();

export default function SignIn() {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await adminLoginAPI({ username: emailValue, password: passwordValue })
      .then((data) => {
        console.log("login", data);
        if (data?.error) {
          setIsLoading(false);
          toast.error(`${data?.error?.data?.error}`);
        } else {
          Cookies.set("token", data?.data?.admin?.jwtToken?.jwtToken);
          setIsAuthenticated(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Login failed:", err);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      Cookies.set("isAuthenticated", true);
      window.location.href = "/admin/dashboard";
    }
  }, [isAuthenticated, navigate]);

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid item xl={5} height="85vh">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
              padding: "0 1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "-3rem",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#fff",
                  width: "125px",
                  height: "125px",
                }}
              >
                <AccountCircle
                  sx={{
                    color: "#212529",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Avatar>
            </div>
            <Card
              sx={{
                backgroundColor: "#f4f4f4",
                padding: "3rem 2rem",
                borderRadius: "8%",
              }}
            >
              <Box
                component="form"
                onSubmit={(e) => handleLogin(e)}
                noValidate
                sx={{ mt: 1, maxWidth: "400px" }}
              >
                <TextField
                  // error={isError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          sx={{
                            color: "#212529",
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  placeholder="Email Address"
                  sx={{
                    backgroundColor: "#fff",
                    "& .MuiOutlinedInput-input": {
                      padding: "28px 14px",
                    },
                  }}
                />
                <TextField
                  // error={isError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          sx={{
                            color: "#212529",
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  sx={{
                    backgroundColor: "#fff",
                    "& .MuiOutlinedInput-input": {
                      padding: "28px 14px",
                    },
                  }}
                />

                <LoadingButton
                  loading={isLoading}
                  disabled={
                    emailValue.length === 0 || passwordValue.length === 0
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#212529",
                    "&:hover": {
                      backgroundColor: "#343a40",
                    },
                  }}
                >
                  Login
                </LoadingButton>
              </Box>
            </Card>
          </Box>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
