// socketReducer.js

const initialState = {
  maintenanceMode: true,
  playPage: false,
  setChallenge: false,
  registration: false,
  requestedChallenge: false,
  buyChips: false,
  spinner: false,
  sellChips: false,
  cyrusBankPayout: false,
  upisellChips: false,
  setAlertMessage: "",
  setMessage: null,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ON_SETTING":
      return {
        ...state,
        ...action.payload,
        isLoading: true,
        error: null,
      };

    default:
      return state;
  }
};
