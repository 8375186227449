import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Cookies from "js-cookie";
import "./styles.css";

const HistoryDownloadModel = ({ btn, is_open, setIsOpen }) => {
  const histories = useSelector((state) => state.history.data);
  // console.log(histories);

  const [selectedRange, setSelectedRange] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [highlightedDates, setHighlightedDates] = useState([]);

  const handleDateClick = (dayInfo) => {
    const date = new Date(dayInfo.day);
    // console.log(dayInfo?.day);

    if (!selectedStartDate) {
      // First date clicked
      setSelectedStartDate(date);
      setSelectedEndDate(null); // Reset end date
    } else if (selectedStartDate && !selectedEndDate) {
      // Second date clicked
      let startDate = selectedStartDate;
      let endDate = date;

      // Swap if startDate is after endDate
      if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate];
      }

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);

      // Generate range of dates
      const range = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        range.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setHighlightedDates(range);
    } else {
      // Reset selection if a third click happens
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      setHighlightedDates([]);
    }
  };

  const isHighlighted = (date) => {
    return highlightedDates.some(
      (d) => d.toDateString() === date.toDateString()
    );
  };

  const handleRangeSelection = (range) => {
    const currentDate = new Date();
    let startDate;

    switch (range) {
      case "1 Month":
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
        setSelectedRange(range);
        break;
      case "3 Months":
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        setSelectedRange(range);
        break;
      case "6 Months":
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        setSelectedRange(range);
        break;
      case "1 Year":
        startDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() - 1)
        );
        setSelectedRange(range);
        break;
      case "Custom":
        setSelectedRange(range);
        return; // Exit early if "Custom" is selected
      default:
        setSelectedRange(range); // Handle other ranges or invalid cases
        return;
    }
    // console.log(selectedRange);

    // Directly store startDate and endDate in the state
    setStartDate(
      new Date(startDate).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    );
    setEndDate(
      new Date().toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    );
  };

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Function to get the number of days in a month
  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Function to handle month change
  const changeMonth = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(prevDate.getMonth() + direction);
      return newDate;
    });
  };

  // Generate the days for the calendar
  const generateCalendarDays = () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const totalDays = daysInMonth(month, year);

    // Days of previous month to fill the first week
    const daysFromPreviousMonth = Array.from(
      { length: firstDayOfMonth },
      (_, i) => ({
        day: daysInMonth(month - 1, year) - firstDayOfMonth + i + 1,
        isCurrentMonth: false,
      })
    );

    // Current month's days
    const daysOfCurrentMonth = Array.from({ length: totalDays }, (_, i) => ({
      day: i + 1,
      isCurrentMonth: true,
    }));

    // Combine days and return
    return [...daysFromPreviousMonth, ...daysOfCurrentMonth];
  };

  const calendarDays = generateCalendarDays();

  const generatePDF = (startDate, endDate) => {
    // Convert startDate and endDate to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    // Retrieve user data from cookies using js-cookie
    const userName = Cookies.get("fullName");
    const userPhone = Cookies.get("phone");
    const userId = Cookies.get("userId");

    // User info data
    const userInfo = [
      ["Name", userName || "N/A"],
      ["Phone", userPhone || "N/A"],
      ["User ID", userId || "N/A"],
    ];

    if (btn === "Classic" || btn === "Quick" || btn === "Popular") {
      filteredData = histories.filter(
        (item) => item.mode === btn.toLowerCase()
      );
      // console.log(filteredData);
    } else if (btn === "Wallet") {
      filteredData = histories.filter(
        (item) => item.type === "buy" || item.type === "sell"
      );
      // console.log(filteredData);
    }
    const filteredData = histories.filter((item) => {
      const createdAt = new Date(item.createdAt);
      return createdAt >= start && createdAt <= end;
    });
    // console.log(filteredData);

    // Define columns for the data table
    const dataColumns = [
      "Action",
      "Amount",
      "Narration",
      "Order ID",
      "UPI ID / UTR",
      "Game Code",
      "Date & Time",
      "Closing Balance",
    ];

    const dataRows = filteredData.map((item) => [
      item.type || "-",
      item.amount || "-",
      item.historyText || "-",
      item._id || "-",
      item.upiId || "-",
      item.roomCode || "-",
      new Date(item.createdAt).toLocaleString() || "-",
      item.closingBalance || "-",
    ]);

    // Generate PDF
    const doc = new jsPDF();

    // Add user info table
    doc.text("User Information", 14, 20);
    doc.autoTable({
      startY: 30,
      body: userInfo,
      theme: "plain",
      styles: {
        lineColor: [169, 169, 169], // Grey color for lines
        lineWidth: 0.1, // Thin lines
      },
    });

    // Add some space between tables
    const finalY = doc.autoTable.previous.finalY + 10;

    // Add data table
    doc.text("History Report", 14, finalY);
    doc.autoTable({
      startY: finalY + 10,
      head: [dataColumns],
      body: dataRows,
      theme: "striped",
      styles: {
        lineColor: [169, 169, 169], // Grey color for lines
        lineWidth: 0.1, // Thin lines
      },
      headStyles: {
        fillColor: [240, 240, 240], // Light grey background for header
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Very light grey for alternate rows
      },
    });

    // Save the generated PDF
    doc.save("history_report.pdf");
  };

  const handleDownloadClick = () => {
    generatePDF(startDate, endDate);
    setIsOpen(false);
  };

  return (
    <Offcanvas
      style={{ height: "auto" }}
      show={is_open}
      onHide={() => setIsOpen(false)}
      placement="bottom"
    >
      <div className="offcanvas-header">
        <div className="offcanvas-title h5">Download History</div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setIsOpen(false)}
        ></button>
      </div>
      <div className="offcanvas-body" style={{ paddingTop: "0px" }}>
        <div
          style={{
            display: "grid",
            gap: "5px",
            gridTemplateColumns: "repeat(1, 1fr)",
            marginBottom: "8px",
            width: "100%",
          }}
        >
          <button
            type="button"
            className={`btn ${
              selectedRange === "1 Month"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => handleRangeSelection("1 Month")}
          >
            Last 1 Month
          </button>
          <button
            type="button"
            className={`btn ${
              selectedRange === "3 Months"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => handleRangeSelection("3 Months")}
          >
            Last 3 Months
          </button>
          <button
            type="button"
            className={`btn ${
              selectedRange === "6 Months"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => handleRangeSelection("6 Months")}
          >
            Last 6 Months
          </button>
          <button
            type="button"
            className={`btn ${
              selectedRange === "1 Year" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => handleRangeSelection("1 Year")}
          >
            Last 1 Year
          </button>
          <button
            type="button"
            className={`btn ${
              selectedRange === "Custom" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => handleRangeSelection("Custom")}
          >
            Custom
          </button>
        </div>
        <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
          <div className="d-flex w-100 align-items-center gap-2 justify-content-between">
            <input
              type="text"
              disabled=""
              className="form-control"
              placeholder="Select Start Date"
              value={startDate}
            />
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="28"
                height="28"
                fill="currentColor"
              >
                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"></path>
              </svg>
            </span>
            <input
              type="text"
              disabled=""
              className="form-control"
              placeholder="Select End Date"
              value={endDate}
            />
          </div>
          <div>
            {selectedRange === "Custom" && (
              <div className="d-flex">
                <div
                  className="d-flex flex-column card"
                  style={{ minWidth: "10rem" }}
                >
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <h4 className="align-self-center mb-0 ms-2">
                      {" "}
                      {selectedDate.toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      {selectedDate.getFullYear()}
                    </h4>
                    <div className="d-flex gap-1 align-items-center">
                      <button
                        onClick={() => changeMonth(-1)}
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="14"
                          height="14"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          ></path>
                        </svg>
                      </button>
                      <button
                        onClick={() => changeMonth(1)}
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="14"
                          height="14"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(7, 1fr)",
                      padding: "1rem",
                      gap: "0.5rem",
                    }}
                  >
                    {daysOfWeek.map((day) => (
                      <div
                        className="text-center"
                        key={day}
                        style={{ width: "4ch" }}
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(7, 1fr)",
                      padding: "1rem",
                      gap: "0.5rem",
                    }}
                  >
                    {calendarDays.map((dayInfo, index) => {
                      const date = new Date(dayInfo.date);
                      return (
                        <div
                          key={index}
                          onClick={() => handleDateClick(dayInfo)}
                          className={`text-center rounded text-muted ${isHighlighted(date) ? "bg-primary text-white" : ""}`}
                          style={{
                            width: "4ch",
                            border: "1px solid rgb(204, 204, 204)",
                            cursor: dayInfo.isCurrentMonth
                              ? "pointer"
                              : "default",
                          }}
                        >
                          {dayInfo.day}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={handleDownloadClick}
            type="button"
            disabled=""
            className="w-100 btn btn-primary"
          >
            Download
          </button>
        </div>
      </div>
    </Offcanvas>
  );
};

export default HistoryDownloadModel;
