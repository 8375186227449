import React, { useEffect, useState } from "react";
import { getHistoryApi } from "../../../apis/history";
import AppLayout from "../layout/AppLayout";
import { useDispatch } from "react-redux";
import All from "./history/tabs/All";
import Wallet from "./history/tabs/Wallet";
import Classic from "./history/tabs/Classic";
import Popular from "./history/tabs/Popular";
import Quick from "./history/tabs/Quick";
import Diamond from "./history/tabs/Diamonds";
import {
  getHistoryLoading,
  getHistorySuccess,
} from "../../../redux/actions/history";
import { automaticLogoutRequest } from "../../../redux/actions/auth";
import HistoryDownloadModel from "../components/atoms/historyDownload";
import Games from "./history/tabs/Games";

const tabs = [
  {
    name: "All",
    value: "all",
    subTabs: [], // No sub-tabs
  },
  {
    name: "Wallet",
    value: "wallet",
    subTabs: [
      { name: "Buy", value: "buy" },
      { name: "Sell", value: "sell" },
      { name: "Refund", value: "refund" },
    ],
  },
  {
    name: "Diamond",
    value: "diamond",
    subTabs: [
      { name: "Credit", value: "credit" },
      { name: "Debit", value: "debit" },
    ],
  },
  {
    name: "Games",
    value: "games",
    subTabs: [
      { name: "App-Classic", value: "CLASSIC" },
      // { name: "Quick", value: "QUICK" },
      { name: "App-Popular", value: "POPULAR" },
    ],
  },
];

export default function History() {
  const dispatch = useDispatch();
  const [is_open, setIsOpen] = useState(false);
  const [btn, setBtn] = useState("all");
  const [subBtn, setSubBtn] = useState([]); // State for sub-tabs
  const [page, setPage] = useState(1);

  const toggleSubTab = (value) => {
    setSubBtn((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const datas = {
        page,
        category: btn,
        subCategory: btn === "all" ? "allSubCategory" : subBtn,
      };
      console.log(datas);

      dispatch(getHistoryLoading(true));
      const history = await getHistoryApi(datas);

      if (history.status === 200) {
        dispatch(getHistorySuccess(history.data.data));
      } else if (history.status === 401) {
        dispatch(automaticLogoutRequest());
      }
      dispatch(getHistoryLoading(false));
    };
    fetchHistory();
  }, [page, btn, subBtn]);

  const handleOpenModel = () => {
    setIsOpen(true);
  };

  const currentTab = tabs.find((tab) => tab.value === btn);

  return (
    <AppLayout>
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div></div>
        <div>
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-1 pt-3 px-0 container">
            {tabs?.map((tab) => (
              <span
                key={tab.value}
                onClick={() => {
                  setBtn(tab.value);
                  setSubBtn(""); // Reset sub-tab on main tab click
                }}
                className={`text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill ${
                  btn === tab.value ? "text-white bg-primary" : ""
                }`}
                style={{ cursor: "pointer" }}
              >
                {tab.name}
              </span>
            ))}
          </div>

          {currentTab?.subTabs?.length > 0 && (
            <div className="d-flex align-items-center justify-content-start flex-wrap gap-1 pt-3 px-0 container">
              {currentTab.subTabs.map((subTab) => (
                <span
                  key={subTab.value}
                  onClick={() => toggleSubTab(subTab.value)}
                  className={`text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill ${
                    subBtn.includes(subTab.value) ? "text-white bg-primary" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  {subTab.name}
                </span>
              ))}
            </div>
          )}

          <div className="d-flex flex-column align-items-stretch px-0 py-3 overflow-auto container-fluid">
            {btn === "all" && <All page={page} setPage={setPage} />}
            {btn === "wallet" && (
              <Wallet
                page={page}
                setPage={setPage}
                filter={subBtn} // Pass sub-tab as a filter
              />
            )}
            {btn === "diamond" && (
              <Diamond
                page={page}
                setPage={setPage}
                filter={subBtn} // Pass sub-tab as a filter
              />
            )}
            {btn === "games" && (
              <>
                <Games page={page} setPage={setPage} />
              </>
            )}
            {/* {btn === "games" && subBtn === "classic" && (
              <Classic page={page} setPage={setPage} />
            )}
            {btn === "games" && subBtn === "quick" && (
              <Quick page={page} setPage={setPage} />
            )}
            {btn === "games" && subBtn === "popular" && (
              <Popular page={page} setPage={setPage} />
            )} */}
          </div>
        </div>
      </div>
      <HistoryDownloadModel is_open={is_open} btn={btn} setIsOpen={setIsOpen} />
    </AppLayout>
  );
}
