import React from "react";
import { Amount, fee, formatToTime } from "../../../functions/functions";
import "../../../components/atoms/styles.css";
import Label from "../../../../../dashboard/components/sharedComponents/label";

const WithdrawalStatusModal = ({ handleClose, statusModal, modalData }) => {
  console.log(modalData);

  return (
    <div
      role="dialog"
      aria-modal="true"
      className={`offcanvas offcanvas-bottom ${statusModal ? "show" : "hide"}`}
      tabIndex="-1"
      style={{ visibility: "visible", width: "480px" }}
    >
      <div className="offcanvas-header d-flex justify-content-center gap-2 align-items-center">
        <div className="offcanvas-title h5">Withdrawal Details</div>
        <button
          onClick={handleClose}
          type="button"
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="pb-3 d-flex flex-column align-items-stretch">
          <div className="vstack gap-3 minBreakpoint-xs">
            <div className="m-auto w-100">
              <table class="table w-100">
                <tbody>
                  <tr>
                    <th className="text-start" style={{ fontSize: "14px" }}>
                      UPI ID / Ac. Number:
                    </th>
                    <td className="text-end">{modalData?.upiId}</td>
                  </tr>
                  <tr>
                    <th className="text-start" style={{ fontSize: "14px" }}>
                      Amount:
                    </th>
                    <td className="text-end">{modalData?.amount}</td>
                  </tr>
                  {modalData?.status === "declined" ? (
                    ""
                  ) : (
                    <tr>
                      <th className="text-start" style={{ fontSize: "14px" }}>
                        UTR:
                      </th>
                      <td className="text-end">
                        {modalData.utr ? modalData.utr : "N/A"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th className="text-start" style={{ fontSize: "14px" }}>
                      Status:
                    </th>
                    <td className="text-end">
                      <Label
                        // color={
                        //   modalData.status !== "success" ? "error" : "success"
                        // }
                        sx={{
                          backgroundColor:
                            modalData.status !== "success" ? "red" : "green",
                          color: "#fff",
                        }}
                      >
                        {modalData?.status}
                      </Label>
                    </td>
                  </tr>
                  {modalData?.status === "declined" && (
                    <tr>
                      <th className="text-start" style={{ fontSize: "14px" }}>
                        Reason:
                      </th>
                      <td className="text-end">
                        {modalData?.reason ? modalData?.reason : "N/A"}
                      </td>
                    </tr>
                  )}
                  {modalData?.status === "declined" ? (
                    ""
                  ) : (
                    <>
                      <tr>
                        <th className="text-start" style={{ fontSize: "14px" }}>
                          Requested Time:
                        </th>
                        <td className="text-end">
                          {new Date(modalData?.createdAt).toLocaleString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start" style={{ fontSize: "14px" }}>
                          Processed Time:
                        </th>
                        <td className="text-end">
                          {new Date(modalData?.updatedAt).toLocaleString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  {/* <tr>
                    <th className="text-start">Reason:</th>
                    <td className="text-end">
                      {modalData?.reason ? modalData?.reason : "N/A"}
                    </td>
                  </tr> */}

                  {/* {modalData?.status === "success" && (
                    <>
                      <tr>
                        <th className="text-start">Comission:</th>
                        <td className="text-end">{fee(modalData)}</td>
                      </tr>
                      <tr>
                        <th className="text-start">Net Paid Amount:</th>
                        <td className="text-end">{Amount(modalData)}</td>
                      </tr>
                    </>
                  )} */}
                </tbody>
              </table>
              {/* <p style={{ fontSize: "0.7rem" }}>
                नोट:- आपका Withdrawl - Success हो गया है , आप दी हुई UPI ID में
                चेक कर सकते है। यदि आपकी UPI ID सही है लेकिन बैंक में अभी पैसा
                नहीं आया है तो 3-5 दिन का इंतज़ार करें Withdrawl बैंक से
                'Reversal' आते ही आपके ID में पैसा ऐड कर दिया जायेगा। यदि
                Success हुए 7 दिन से ऊपर हो गया है तो&nbsp;
                <a href="/support">Support</a>&nbsp;पेज में से हमें संपर्क करें
                ।
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalStatusModal;
