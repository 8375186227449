import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import TableNoData from "./table-no-data";
import UserTableRow from "./user-table-row";
import UserTableHead from "./user-table-head";
import TableEmptyRows from "./table-empty-rows";
import UserTableToolbar from "./user-table-toolbar";
import { DASHBOARD } from "../../../redux/contstants";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Scrollbar from "../../components/sharedComponents/scrollbar";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      // color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Settings = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [search, setSearch] = useState("");

  const [result, setResult] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [settings, setSettings] = useState({
    maintenanceMode: true,
    playPage: false,
    setChallenge: false,
    registration: false,
    requestedChallenge: false,
    buyChips: false,
    sellChips: false,
    cyrusBankPayout: false,
    bankSellChips: false,
    upisellChips: false,
    firebase: false,
    setAlertMessage: "",
    spinner: false,
    ConvertChips: false,
    LogoutButton: false,
  });

  useEffect(() => {
    // Dispatch the action to trigger the getUser saga
    console.log("dispaching");
    dispatch({ type: DASHBOARD.GET_SETTINGS_REQUEST });
  }, [dispatch]);
  const settingsData = useSelector((state) => state?.dashboardSlice);
  console.log(settingsData?.settings);

  useEffect(() => {
    if (
      !settingsData?.isLoading &&
      settingsData?.settings &&
      settingsData?.settings?.data
    ) {
      setSettings(settingsData?.settings?.data); // Assuming data contains the settings object from the database
    }
  }, [settingsData]);
  //form input
  const handleSettingsInput = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked);

    setSettings({
      ...settings,
      [name]: !checked,
    });
  };
  const setInputField = (event) => {
    const updatedSettings = {
      ...settings,
      setAlertMessage: event.target.value,
    };
    setSettings(updatedSettings);
  };
  console.log(settings);

  //handleSubmit
  const handleSubmit = async (e) => {
    setSaveLoading(true);
    e.preventDefault();
    try {
      const res = dispatch({
        type: DASHBOARD.ADD_SETTINGS_REQUEST,
        payload: { settings },
      });

      if (res?.success) {
        toast.success(`Changes updated successfully`);
      }
      setSaveLoading(false);
    } catch (error) {
      toast.error(`${error}`);
      throw error;
    }
  };

  const handleUsersFilter = (event) => {
    setUserFilter(event.target.value);
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={10}
        >
          <Header
            refetch={() => setRefetch(!refetch)}
            title="Site Settings"
            isLoading={settingsData?.isLoading}
          />
          <Button
            disabled={saveLoading}
            onClick={(e) => handleSubmit(e)}
            variant="contained"
            sx={{ background: "green", color: "#ffff" }}
          >
            {saveLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "save"
            )}
          </Button>
        </Stack>
        <Grid
          style={{ margin: "3px" }}
          container
          rowSpacing={2}
          columnSpacing={{ xs: 4 }}
          mb={5}
        >
          <Grid item xl={8} mx={"auto"}>
            <Card>
              <CardContent>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  multiline
                  rows={2}
                  name="setAlertMessage"
                  placeholder="Write The Message To Display"
                  value={settings?.setAlertMessage}
                  backgroundcolor="#fff"
                  bordercolor="#212529"
                  InputProps={{
                    style: {
                      fontSize: "15px",
                    },
                  }}
                  sx={{
                    "> .MuiInputBase-root.Mui-focused": {
                      border: "1px solid #212529",
                    },
                  }}
                  onChange={(e) => {
                    setInputField(e);
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ overflow: "unset", height: "500px" }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={result?.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: "pageName", label: "Page Name" },
                  { id: "blockPage", label: "Inactive/Active " },
                ]}
              />
              <TableBody>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Play Page</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.playPage}
                        />
                      }
                      label="Play Page"
                      name="playPage"
                      value={!settings?.playPage}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Set Challenge</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.setChallenge}
                        />
                      }
                      label="Set Challenge"
                      name="setChallenge"
                      value={!settings?.setChallenge}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Requested Challenges</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.requestedChallenge}
                        />
                      }
                      label="Requested Challenges"
                      name="requestedChallenge"
                      value={!settings?.requestedChallenge}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Registration</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.registration}
                        />
                      }
                      label="Registration"
                      name="registration"
                      value={!settings?.registration}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Buy Chips</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.buyChips}
                        />
                      }
                      label="Buy Chips"
                      name="buyChips"
                      value={!settings?.buyChips}
                    />
                  </TableCell>
                </TableRow>

                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Logout Button</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.LogoutButton}
                        />
                      }
                      label="Logout Button"
                      name="LogoutButton"
                      value={!settings?.LogoutButton}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Covert Chips</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.ConvertChips}
                        />
                      }
                      label="Convert Chips"
                      name="ConvertChips"
                      value={!settings?.ConvertChips}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Bank Payout</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.bankSellChips}
                        />
                      }
                      label="Bank Payout"
                      name="bankSellChips"
                      value={!settings?.bankSellChips}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Upi Payout</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.upisellChips}
                        />
                      }
                      label="Upi Payout"
                      name="upisellChips"
                      value={!settings?.upisellChips}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Firebase notification</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.firebase}
                        />
                      }
                      label="Firebase notification"
                      name="firebase"
                      value={!settings?.firebase}
                    />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px" }}
                  >
                    <Typography noWrap>Spinner</Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={handleSettingsInput}
                          checked={!settings?.spinner}
                        />
                      }
                      label="spinner"
                      name="spinner"
                      value={!settings?.spinner}
                    />
                  </TableCell>
                </TableRow>

                {/* {notFound && <TableNoData query={filterName} />} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={!result?.length ? 0 : result?.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};

export default Settings;
