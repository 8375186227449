import React, { useState } from "react";
import upilogo from "../../../../public/assets/upi-icon.webp";
import "../styles/style.css";
import deleteIcon from "../../../assets/delete.png";
import { deleteUPIID } from "../../../apis/wallet";
import CircularLoading from "../components/atoms/CircularLoading";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const SavedUpi = ({ pay, setUpiId, data, setShowForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [PayLoading, setPayLoading] = useState(false);
  const [selectedUpi, setSelectedUpi] = useState(null); // State to track selected UPI ID
  const dispatch = useDispatch();
  const deleteUpiID = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteUPIID(id);

      setIsLoading(false);
      console.log(res);
      if (res?.data?.status == 200) {
        dispatch({
          type: "GET_UPI",
          payload: res.data.data.upids,
        });
        toast.success("UPI ID deleted successfully");
      }
      handleUpiSelection("");
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpiSelection = (id) => {
    setSelectedUpi(id); // Set the selected UPI ID
    setUpiId(id); // Pass the selected UPI ID to the parent
  };
  return (
    <div className="mb-3 shadow card rounded-card">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
        }}
      >
        <p style={{ fontSize: "18px", marginBottom: "0px" }}>Saved UPIs</p>
        {selectedUpi && (
          <button
            onClick={() => deleteUpiID(selectedUpi)}
            disabled={isLoading}
            style={{
              background: "none",
              border: "1px solid #999",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <img width="25px" height="25px" src={deleteIcon} alt="delete" />
          </button>
        )}
      </div>
      <div className="card-body">
        <div
          className="all-upis-card"
          style={{
            borderRadius: "10px",
            border: "1px solid #ccc",
            padding: "1px 13px 9px 13px",
            backgroundColor: "#f8f9fa", // Light background color
          }}
        >
          {data?.map((id, index) => (
            <div key={index}>
              <div
                style={{
                  marginTop: "10px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "5px 0px 11px 0px",
                  borderBottom: "1px dashed #999",
                }}
              >
                <div
                  onClick={() => handleUpiSelection(id)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img src={upilogo} width="40px" alt="upi" />
                  <span style={{ paddingTop: "2px", fontSize: "18px" }}>
                    {id}
                  </span>
                </div>
                <input
                  type="radio"
                  name="selectedCard"
                  checked={selectedUpi == id}
                  onChange={() => handleUpiSelection(id)}
                  style={{ width: "20px", height: "20px" }}
                />
              </div>

              {/* Conditionally render the "Pay via Google Pay" button */}
              {/* {selectedUpi === id && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <button
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#34a853", // Google Pay green color
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={pay}
                  >
                    Pay
                  </button>
                </div>
              )} */}
            </div>
          ))}

          <div className="d-grid mt-3">
            <div
              className="addUpiID"
              onClick={() => {
                setShowForm(true);
                setUpiId("");
              }}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <div
                className="plus"
                style={{
                  fontWeight: "bold",
                  border: "1px solid #999",
                  borderRadius: "4px",
                  padding: "2px 6px", // Reduced padding
                  color: "#db5a35",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px", // Optional: Set a specific width
                  height: "24px", // Optional: Set a specific height
                }}
              >
                <p style={{ fontSize: "1rem", marginBottom: "0px" }}>+</p>{" "}
                {/* Reduced font size */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    color: "#db5a35",
                    fontWeight: "bold",
                  }}
                >
                  Add New UPI ID
                </span>
                <span style={{ color: "#999", fontSize: "12px" }}>
                  You need to have a registered UPI ID
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ marginTop: "10px", textAlign: "center" }}>
          <button
            className="btn btn-success"
            style={{
              backgroundColor: "#34a853", // Google Pay green color
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={pay}
          >
            Pay
          </button>
        </div> */}
        <div className="d-grid mt-3">
          <button
            onClick={pay}
            style={{
              backgroundColor: "#34a853", // Google Pay green color
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            className="btn btn-primary d-flex align-items-center justify-content-center gap-2"
            disabled={!selectedUpi}
          >
            {PayLoading ? (
              <>
                <span>Please Wait...</span>

                <CircularLoading
                  height={"1.5rem"}
                  width={"1.5rem"}
                  color={"white"}
                />
              </>
            ) : (
              "Pay "
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SavedUpi;
