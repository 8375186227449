import React, { useState } from "react";
const BuyPaymentMode = ({ paymentUrl, setBuyChipsLoader }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    {
      id: "phonePe",
      label: "phonePe",
      image:
        "https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/PhonePe.png",
      width: "50",
    },
    {
      id: "paytm",
      label: "Paytm",
      image:
        "https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/Paytm.png",
      width: "50",
    },
    {
      id: "googlePay",
      label: "Google Pay",
      image:
        "https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/googlePay.png",
      width: "50",
    },
    {
      id: "genericUpiLink",
      label: "Other",
      image:
        "https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/upi.png",
      width: "50",
    },
  ];
  const getPaymentLink = (selectedOption) => {
    const links = paymentUrl;
    switch (selectedOption) {
      case "phonePe":
        return links.phonePeLink;
      case "paytm":
        return links.paytmLink;
      case "googlePay":
        return links.googlePayLink;
      case "genericUpiLink":
        return links.genericUpiLink;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          Select Payment Mode
        </div>
        <div className="card-body">
          <div className="hstack flex-wrap gap-3 justify-content-center buy-payment-mode p-3">
            {options?.map((item, index) => (
              <div className="form-check-custom" key={index}>
                <label
                  className={
                    "form-check-label border-1 " +
                    (selectedOption === item.id ? "checked" : "")
                  }
                >
                  <a
                    href={
                      getPaymentLink(item.id)
                        ? getPaymentLink(item.id)
                        : `${process.env.REACT_APP_CLIENT_URL}buy`
                    }
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    onClick={() => {
                      setBuyChipsLoader(true);
                      setSelectedOption(item.id);
                    }}
                  >
                    <img src={item.image} alt={item.label} width={item.width} />
                    <p style={{ color: "black" }} className="fw-bold m-0 pt-1">
                      {item.label}
                    </p>
                  </a>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          Pay with UPI QR
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center gap-4">
            <div className="w-50">
              <img className="w-100" src={paymentUrl.QrCode} alt="info" />
            </div>

            <div className="qr-text text-start">
              <p className="text-capitalize text-secondary m-1">
                Scan the QR using any UPI app on your phone.
              </p>
              <div className="hstack gap-2 minBreakpoint-xs">
                <img
                  src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg"
                  alt="gpay logo"
                  width={"40"}
                />
                <img
                  src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg"
                  alt="paytm logo"
                  width={"40"}
                />
                <img
                  src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg"
                  alt="phone logo"
                  width={"40"}
                />
                <img
                  src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg"
                  alt="upi logo"
                  width={"40"}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <p className="d-flex align-items-center justify-content-center py-2 gap-2">
        Powered By{" "}
        <img
          src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/aero.webp"
          alt="Icon"
          width={20}
        />{" "}
        AeroPe
      </p>
    </>
  );
};

export default BuyPaymentMode;
