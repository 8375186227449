import React from "react";
import AppLayout from "../../layout/AppLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { width } from "@mui/system";
import "./styles.css";
import diamond from "../../../../assets/diamond-icon.5a8809fdba3dda99b914.webp";

const BuyDiamondsModel = ({ is_open, data, children, setIsOpen }) => {
  console.log(is_open);

  return (
    <Offcanvas
      style={{ height: "auto", maxWidth: "480px" }}
      show={is_open}
      onHide={() => {
        setIsOpen(false);
      }}
      placement="bottom"
    >
      <div className="offcanvas-header">
        <div className="offcanvas-title h5">Confirm Your Purchase</div>
        <button
          onClick={() => setIsOpen(false)}
          type="button"
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body" style={{ paddingTop: "0px" }}>
        <div
          style={{
            height: "100%",
            gap: " 5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <span
              style={{
                backgroundColor: "rgb(255, 255, 0)",
                borderRadius: "10px",
                padding: "5px",
                fontWeight: "bold",
                fontSize: "1.3rem",
              }}
            >
              <img
                src={diamond}
                alt="diamond icon"
                style={{ width: "20px", height: "20px", margin: "0px 5px" }}
              />{" "}
              {data?.qtv} Diamonds for{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                fill="green"
                className="mx-1"
              >
                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
              </svg>{" "}
              {data?.price}
            </span>
          </div>
          <p style={{ fontSize: "0.9rem", marginTop: "5px" }}>
            The amount will be deducted from your wallet and the diamonds will
            be added to your account.
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="btn btn-success btn-lg"
              style={{ marginBottom: "5px" }}
            >
              Yes, I Confirm
            </button>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="btn btn-danger btn-lg"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default BuyDiamondsModel;
