import PropTypes from "prop-types";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

import Iconify from "../../components/sharedComponents/iconify";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";

// ----------------------------------------------------------------------

export default function UserTableToolbar({
  numSelected,
  filterName,
  onFilterName,
  handleUsersFilter,
  usersFilter,
}) {
  return (
    <Toolbar
      sx={{
        height: 96,
        display: "flex",
        justifyContent: "space-between",
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Box sx={{ width: "200px", marginRight: "1rem" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Filter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={usersFilter}
                label="Filter"
                onChange={handleUsersFilter}
              >
                <MenuItem value="all">All users</MenuItem>
                <MenuItem value="active">Active users</MenuItem>
                <MenuItem value="banned">Banned users</MenuItem>
                <MenuItem value="kycVerified">Kyc verified users</MenuItem>
                <MenuItem value="kycUnverified">Kyc unverified users</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <OutlinedInput
            sx={{ "& .MuiOutlinedInput-input": { padding: "20px 5px" } }}
            value={filterName}
            onChange={onFilterName}
            placeholder="Search user..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        </>
      )}
    </Toolbar>
  );
}

UserTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};
