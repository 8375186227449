import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import CircularLoading from "./CircularLoading";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../../../redux/actions/auth";

const ConfirmCanvas = ({ is_open, setIsOpen, socket }) => {
  const { isLoading } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const logout = () => {
    socket.disconnect();
    dispatch(logoutRequest());
  };

  return (
    <Offcanvas
      style={{ height: "36%" }}
      show={is_open}
      onHide={() => {
        setIsOpen(false);
      }}
      placement="bottom"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Confirm to logout</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className="text-center mb-3"
          style={{
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          <strong>
            <h2 className="m-0">Are you sure you want to logout?</h2>
          </strong>
        </div>

        <div className="d-flex flex-column align-content-stretch">
          <button
            disabled={isLoading}
            onClick={logout}
            className="btn btn-success btn-lg mb-3"
          >
            {isLoading ? (
              <CircularLoading
                height={"1.5rem"}
                width={"1.5rem"}
                color={"white"}
              />
            ) : (
              "Yes, I Confirm"
            )}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="btn btn-danger btn-lg mb-3"
          >
            No
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ConfirmCanvas;
