import React from "react";
import {
  Modal,
  Fade,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
} from "@mui/material";
import { formatDate, formatToTime } from "../utils/helpingFunctions";
import ImageViewer from "./ImageViewver";
import vs from "../../../public/assets/vs.webp";

const ViewGameModal = ({ showModal, setShowModal, Loading, editGame }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
    >
      <Fade in={showModal || Loading}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "90%" : "50%",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              padding: "4px 0",
            }}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            borderBottom={1}
            borderColor={"#e0e0e0"}
          >
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              border={1}
              minWidth={"150px"}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              {editGame?.creator?.username}
            </Typography>

            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              my={0.3}
            >
              <img
                src={vs}
                alt="verses-icon"
                width="20"
                style={{ margin: "0 auto" }}
              />
              <Typography
                fontWeight="600"
                sx={{ marginLeft: "5px", color: "green" }}
              >
                Rs{editGame?.amount}
              </Typography>
            </Box>
            <Typography
              fontWeight="600"
              padding={"0.3rem"}
              border={1}
              minWidth={"150px"}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              {editGame?.player?.username}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent="space-around"
            height={"100%"}
            sx={{
              padding: "5px 0",
            }}
          >
            <Typography
              fontWeight="600"
              padding={"0.3rem 1rem"}
              border={1}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              {editGame?.roomCode}
            </Typography>
            <Typography
              fontWeight="600"
              padding={"0.3rem 1rem"}
              border={1}
              textAlign={"center"}
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              {editGame?.mode ? editGame?.mode : "No RoomCode"}
            </Typography>
            <Box
              padding={"0.3rem 1rem"}
              border={1}
              minWidth={"35%"}
              sx={{
                marginTop: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderColor: "#e0e0e0",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight="600" textAlign={"center"}>
                  Challenge Created At:
                </Typography>
                <Typography fontWeight="600" textAlign={"center"}>
                  {editGame?.createdAt
                    ? formatDate(editGame?.createdAt)
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight="600" textAlign={"center"}>
                  Challenge Started At:
                </Typography>
                <Typography
                  fontWeight="600"
                  textAlign={"center"}
                  color={"red"}
                  sx={{
                    margin: "auto",
                  }}
                >
                  {editGame?.startedAt
                    ? formatToTime(editGame?.startedAt)
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box borderTop={5} borderColor={"#e0e0e0"} mb={1}>
            <Grid container>
              <Grid item xs={12} md={6} borderRight={2} borderColor={"#e0e0e0"}>
                <Box
                  sx={{
                    padding: "1rem 2rem",
                  }}
                >
                  <Typography fontWeight="600">
                    Creator Posted Result At:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.creator.result
                        ? formatToTime(editGame?.results.creator.updatedAt)
                        : "Invalid Date"}
                    </span>
                  </Typography>
                  <Typography fontWeight="600">
                    Creator Result:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.creator.result}
                    </span>
                  </Typography>
                  <Typography fontWeight="600">
                    Reason:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.creator.reason}
                    </span>
                  </Typography>
                  <Typography fontWeight="600">
                    RoomCode Sent:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.roomCodeSetAt
                        ? formatToTime(editGame?.roomCodeSetAt)
                        : ""}
                    </span>
                  </Typography>
                  {/* <Typography
                      variant="h4"
                      fontWeight="600"
                      style={{ color: '#5271FF' }}
                    >
                      IP Website:
                    </Typography> */}
                </Box>
                <Box
                  sx={{
                    padding: "0 0.5rem",
                  }}
                >
                  <Typography
                    fontWeight="600"
                    textAlign={"center"}
                    sx={{
                      borderRadius: "25px",
                      backgroundColor: "green",
                      color: "white",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    Creator Screenshot
                  </Typography>
                  <Box textAlign={"center"} padding={"0.5rem 0"}>
                    {editGame?.winnerScreenShot.creator ? (
                      <ImageViewer
                        imageUrl={editGame?.winnerScreenShot.creator}
                      />
                    ) : (
                      <Typography
                        fontWeight="600"
                        textAlign={"center"}
                        maxWidth={"80%"}
                        border={3}
                        borderColor={"#333"}
                        sx={{
                          margin: "0 auto",
                          marginTop: "1.5rem",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#000",
                          padding: "0.5rem 1rem",
                        }}
                      >
                        No Screenshot
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} borderRight={2} borderColor={"#e0e0e0"}>
                <Box
                  sx={{
                    padding: "1rem 2rem",
                  }}
                >
                  <Typography fontWeight="600">
                    Player Posted Result At:{" "}
                    <span style={{ color: "green" }}>
                      {formatToTime(editGame?.results.player.updatedAt)}
                    </span>
                  </Typography>
                  <Typography fontWeight="600">
                    Player Result:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.player.result}
                    </span>
                  </Typography>
                  <Typography fontWeight="600">
                    Reason:{" "}
                    <span style={{ color: "green" }}>
                      {editGame?.results.player.reason}
                    </span>
                  </Typography>

                  {/* <Typography
                      variant="h4"
                      fontWeight="600"
                      style={{ color: '#5271FF' }}
                    >
                      IP Website:
                    </Typography> */}
                </Box>
                <Box
                  sx={{
                    padding: "0 0.5rem",
                  }}
                >
                  <Typography
                    fontWeight="600"
                    textAlign={"center"}
                    sx={{
                      borderRadius: "25px",
                      backgroundColor: "green",
                      color: "white",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    Player Screenshot
                  </Typography>
                  <Box textAlign={"center"} padding={"0.5rem 0"}>
                    {editGame?.winnerScreenShot.player ? (
                      <ImageViewer
                        imageUrl={editGame?.winnerScreenShot.player}
                      />
                    ) : (
                      <Typography
                        fontWeight="600"
                        textAlign={"center"}
                        maxWidth={"80%"}
                        border={3}
                        borderColor={"#333"}
                        sx={{
                          margin: "0 auto",
                          marginTop: "1.5rem",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#000",
                          padding: "0.5rem 1rem",
                        }}
                      >
                        No Screenshot
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={7} mx={"auto"}></Grid>
              <Grid item xs={12} md={7} mx={"auto"}>
                <Card
                  sx={{
                    backgroundColor: "#FAFAFA",
                    padding: "0.6rem 0.5rem",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    gridColumn="span 2"
                    gridRow="span 1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={"center"}
                    border={1}
                    borderColor={"#e0e0e0"}
                    p={{ xs: "0.4rem", md: "0.4rem 1rem" }}
                    flex="1 1 100%"
                    backgroundColor={"#fff"}
                    borderRadius="0.55rem"
                    height={"100%"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"0.5rem"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography
                            fontWeight="600"
                            padding={"0.3rem"}
                            border={1}
                            minWidth={"150px"}
                            textAlign={"center"}
                            sx={{
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              borderColor: "#e0e0e0",
                            }}
                          >
                            {editGame?.creator?.username}
                          </Typography>
                          <Typography
                            fontWeight="600"
                            textAlign={"center"}
                            color={"green"}
                            minWidth={"54px"}
                            textTransform={"capitalize"}
                            sx={{
                              marginLeft: "1rem",
                            }}
                          >
                            {editGame?.results.creator.result}
                          </Typography>
                        </Box>
                        <Box display={"flex"} my={0.3} ml={"15%"}>
                          <img src={vs} alt="verses-icon" height="20" />
                          <Typography
                            fontWeight="600"
                            sx={{ marginLeft: "5px", color: "green" }}
                          >
                            Rs{editGame?.amount}
                          </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography
                            fontWeight="600"
                            padding={"0.3rem"}
                            minWidth={"150px"}
                            border={1}
                            textAlign={"center"}
                            sx={{
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              borderColor: "#e0e0e0",
                            }}
                          >
                            {editGame?.player?.username}
                          </Typography>
                          <Typography
                            fontWeight="600"
                            textAlign={"center"}
                            color={"green"}
                            minWidth={"54px"}
                            textTransform={"capitalize"}
                            sx={{
                              marginLeft: "1rem",
                            }}
                          >
                            {editGame?.results.player.result}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent="space-around"
                      height={"100%"}
                    >
                      <Typography
                        fontWeight="600"
                        padding={"0.3rem"}
                        border={1}
                        textAlign={"center"}
                        sx={{
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          borderColor: "#e0e0e0",
                        }}
                      >
                        {editGame?.roomCode}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ViewGameModal;
