import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort, BsInfoCircle, BsClipboard } from "react-icons/bs";
import { Link, useNavigate, useParams, useHistory } from "react-router-dom";
import {
  cancelChallengeApi,
  looseChallengeApi,
  winChallengeApi,
} from "../../../apis/challenge";

import { CopyToClipboard } from "react-copy-to-clipboard";
import SwipeableContainer from "./game/Guidedrawer";

import AppLayout from "../layout/AppLayout";
import LostModal from "./game/LostModal";
import WinModal from "./game/WinModal";
import CancellationModal from "./game/CancellationModal";

import {
  convertToBase64,
  encrypt,
  uploadImageToS3,
} from "../functions/functions";
import CountDown from "../components/countDown/countDown";
import { useDispatch } from "react-redux";
import { automaticLogoutRequest } from "../../../redux/actions/auth";

export default function Game({ socket }) {
  const [room, setRoom] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  let challengeInititalState = {
    creatorUserName: "",
    creatorImage: null,
    playerUserName: "",
    playerImage: null,
    playerId: "",
    creatorId: "",
    amount: 0,
    roomCode: "",
    mode: "",
    challengeId: params.id,
  };
  const [challenge, setChallenge] = useState(challengeInititalState);
  const navigate = useNavigate();

  const userId = Cookies.get("userId");
  const timer = localStorage.getItem("end_date");
  const [wonModal, setWonModal] = useState(false);

  const [is_open, setOpen] = useState(false);
  const [lostModal, setLostModal] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);

  const [cancellationReason, setCancellation] = useState("");
  const [canceLLationModal, setCancellationModal] = useState(false);
  const [isIlostClicked, setisIlostClicked] = useState(false);
  const [userIs, setUserIs] = useState(null);
  const [image, setImage] = useState({ image: "", Url: "" });
  const [postResultLoading, setPostResultLoading] = useState(false);
  const [enabled, setEnable] = useState(localStorage.getItem("end_date"));

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit(
          "ludogame",
          JSON.stringify({
            type: "getChallengeByChallengeId",
            payload: {
              challengeId: params.id,
              userId,
            },
          })
        );
      });
      socket.emit(
        "ludogame",
        JSON.stringify({
          type: "getChallengeByChallengeId",
          payload: {
            challengeId: params.id,
            userId,
          },
        })
      );

      const handleLudoGameEvent = (event) => {
        event = JSON.parse(event);
        if (event?.data?.roomCode && event?.data?.player._id == userId) {
          localStorage.setItem("end_date", "over");
          setEnable("over");
        }
        if (event.status === 400) {
          setRoom("");
          return toast.error(event?.error);
        }
        if (event.status === 200) {
          if (
            event.data.state === "cancelled" ||
            event.data.state === "resolved"
          ) {
            if (challenge.mode === "CLASSIC") {
              navigate("/play");
            } else if (challenge.mode === "QUICK") {
              navigate("/quick-game");
            } else if (challenge.mode === "POPULAR") {
              navigate("/popular-game");
            } else {
              navigate("/play");
            }
          }

          setChallenge({
            ...challenge,
            creatorUserName: event.data.creator.username,
            creatorImage: event.data.creator.profileImage,
            playerUserName: event.data.player.username,
            playerImage: event.data.creator.profileImage,
            roomCode: event.data.roomCode,
            mode: event.data.mode,
            amount: event.data.amount,
            creatorId: event.data.creator._id,
            playerId: event.data.player._id,
          });
          setUserIs(event?.data?.creator._id == userId ? "creator" : "player");
        } else {
          navigate("/play");
        }
      };
      console.log("chcekkkkkd");

      socket.on("ludogame", handleLudoGameEvent);
      socket.on("redirect", (event) => {
        const message = JSON.parse(event);
        console.log("chcekkckmessage", message);
        if (message.mode === "CLASSIC") {
          navigate("/play");
        } else if (message.mode === "QUICK") {
          navigate("/quick-game");
        } else if (message.mode === "POPULAR") {
          navigate("/popular-game");
        } else {
          navigate("/play");
        }
      });
      return () => {
        socket.off("connect");
        socket.off("ludogame", handleLudoGameEvent);
        socket.off("redirect");
      };
    }
  }, [socket]);

  const showToast = () => {
    toast.success("Text copied!");
  };
  const gameRedirect = () => {
    const encrypted = encrypt(userId);

    const redirectUrl = `https://game.gotiking.com/index/?status=${encrypted}`;

    // window.location.href = redirectUrl;
    // const encrypted = encrypt(userId);
    const queryParams = { status: encrypted };

    navigate({
      pathname: "/ludo",
      search: new URLSearchParams(queryParams).toString(),
    });
  };
  const handleRedirect = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href =
        "https://itunes.apple.com/in/app/ludo-king/id993090598";
    } else if (/Android/.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ludo.king";
    }
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input consists of up to 8 digits.
    const isValidInput = /^\d{0,8}$/.test(inputValue);

    if (isValidInput) {
      setRoom(inputValue);
    }
  };

  const handleLostModalClose = () => {
    setLostModal(false);
  };

  const handleLooseChallenge = () => {
    looseChallenge(challenge.challengeId);
  };

  const handleWonModalClose = () => {
    setWonModal(false);
    setImage({ image: "", Url: "" });
  };
  const handleWinChallenge = () => {
    setPostResultLoading(true);
    winChallenge({
      id: challenge.challengeId,
      image: image,
    });
  };

  const handleCancellationModalClose = () => {
    setCancellation("");
    setImage({ image: "", Url: "" });

    setCancellationModal(false);
  };
  const handleCancelChallenge = async () => {
    setDisableCancelButton(true);
    let cancellationData = {
      _id: challenge.challengeId,
      cancellationReason,
    };

    if (image && image.image && image.Url) {
      const challengeImage = await uploadImageToS3(
        image.image,
        cancellationData._id
      );
      cancellationData.image = challengeImage;
    }
    cancelChallenge(cancellationData);
  };

  const handleGuide = () => setOpen(true);
  const handleGuide2 = () => setOpen(false);

  const dialogs = document.querySelectorAll("dialog");

  // Add event listeners to each dialog
  dialogs.forEach((dialog) => {
    dialog.addEventListener("click", (event) => {
      // Close any open dialogs
      dialogs.forEach((dialog) => {
        if (dialog !== event.target && dialog.open) {
          dialog.close();
        }
      });
    });
  });

  const winChallenge = async (challengeObject) => {
    if (challenge.roomCode === "") {
      toast.error("you cannot perform this action!");
      return;
    }
    try {
      if (!image.Url && !image.image) {
        return toast.error("Please upload a result screenshot.");
      }
      const challengeImage = await uploadImageToS3(
        image.image,
        challengeObject.id
      );

      challengeObject.image = challengeImage;

      const response = await winChallengeApi(challengeObject);
      setPostResultLoading(false);
      if (response.status === 200) {
        localStorage.removeItem("end_date");
        toast.success("Result Updated Successfully");
      } else if (response.status === 401) {
        dispatch(automaticLogoutRequest());
      } else {
        toast.error("Request Failed");
      }
      if (challenge.mode === "CLASSIC") {
        navigate("/play");
      } else if (challenge.mode === "QUICK") {
        navigate("/quick-game");
      } else if (challenge.mode === "POPULAR") {
        navigate("/popular-game");
      } else {
        navigate("/play");
      }
    } catch (error) {
      toast.error(error);
      toast.error(error.message);
      setPostResultLoading(false);
      throw error;
    }
  };

  const looseChallenge = async (challengeId) => {
    if (challenge.roomCode === "") {
      toast.error("you cannot perform this action!");
      return;
    }
    try {
      setisIlostClicked(true);
      const response = await looseChallengeApi(challengeId);
      setisIlostClicked(false);
      if (response.status === 200) {
        toast.success("Result Updated Successfully");
      } else if (response.status === 401) {
        dispatch(automaticLogoutRequest());
      } else {
        toast.error("Request Failed");
      }
      if (challenge) {
        if (challenge.mode === "CLASSIC") {
          navigate("/play");
        } else if (challenge.mode === "QUICK") {
          navigate("/quick-game");
        } else if (challenge.mode === "POPULAR") {
          navigate("/popular-game");
        } else {
          navigate("/play");
        }
      }
    } catch (error) {
      setisIlostClicked(false);
      throw error;
    }
  };

  const cancelChallenge = async (challengeObject) => {
    const response = await cancelChallengeApi(challengeObject);
    setDisableCancelButton(false);
    if (response.status === 200) {
      localStorage.removeItem("end_date");
      toast.success("Result Updated Successfully");
    } else if (response.status === 401) {
      dispatch(automaticLogoutRequest());
    } else {
      toast.error("Request Failed");
    }

    if (challenge) {
      if (challenge.mode === "CLASSIC") {
        navigate("/play");
      } else if (challenge.mode === "QUICK") {
        navigate("/quick-game");
      } else if (challenge.mode === "POPULAR") {
        navigate("/popular-game");
      } else {
        navigate("/play");
      }
    }
  };

  const handleImageChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB in bytes

      if (selectedFile.size > maxSizeInBytes) {
        return toast.error(
          "Image size exceeds 10 MB limit. Please choose a smaller image."
        );
      }

      setImage({ image: selectedFile, Url: URL.createObjectURL(selectedFile) });
    }
  };
  const handleCancellationReason = async (text) => {
    setCancellation(text);
  };
  const setValidationMsg = (msg) => {
    toast.error(msg);
  };
  const handleSubmit = () => {
    if (room.length < 8) {
      return setValidationMsg("All 8 digits Required!");
    }

    return socket.emit(
      "ludogame",
      JSON.stringify({
        type: "setRoomCode",
        payload: {
          userId,
          ChallengeId: challenge.challengeId,
          roomCode: room,
        },
      })
    );
  };

  return (
    <AppLayout>
      {challenge && (
        <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
          <div>
            <SwipeableContainer
              is_open={is_open}
              handleGuide2={handleGuide2}
            ></SwipeableContainer>
          </div>
          <div>
            <div className="d-flex alig-items-center justify-content-between mt-2 mb-3">
              <button
                type="button"
                className="text-capitalize btn btn-primary"
                onClick={() => navigate(-1)}
              >
                <BsArrowLeftShort className="me-2" />
                <span className="text-capitalize">Back</span>
              </button>

              <div></div>

              <div className="d-grid">
                <button
                  type="button"
                  onClick={handleGuide}
                  className="d-flex align-items-center justify-content-center btn btn-outline-danger"
                >
                  <BsInfoCircle className="me-2" />
                  <span className="text-capitalize">Rules</span>
                </button>
              </div>
            </div>
            <div className="mb-2 shadow card">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column align-items-start vstack gap-2 minBreakpoint-xs">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <img
                        src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${
                          challenge.creatorImage
                            ? challenge.creatorImage
                            : "2.svg"
                        }`}
                        alt="avatar"
                      />
                    </div>
                    <span className=" fw-semibold text-truncate text-end">
                      {challenge.creatorUserName.slice(0, 5)}...
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center vstack gap-2 minBreakpoint-xs">
                    <span>
                      <em>
                        <img
                          src="	https://ludoplayers.com/static/media/vs.c153e22fa9dc9f58742d.webp"
                          alt="verses-icon"
                          width="24"
                        />
                      </em>
                    </span>
                    <span className="text-success fw-bold text-center">
                      ₹ {challenge.amount}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-end vstack gap-2 minBreakpoint-xs">
                    <div
                      className="bg-dark rounded-circle"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <img
                        src={`https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/avtar/${
                          challenge.playerImage
                            ? challenge.playerImage
                            : "2.svg"
                        }`}
                        alt="avatar"
                      />
                    </div>
                    <span className=" fw-semibold text-truncate text-end">
                      {challenge.playerUserName.slice(0, 5)}...
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2 shadow card">
              <div style={{ fontSize: "80%" }} className="card-body">
                Opponent का एक भी टोकन खुलने के बाद यदि आप Game Left करते हो तो
                Opponent को सीधा Win कर दिया जायेगा ! Auto Exit के केस में
                Admins का निर्णय ही अंतिम होगा जिससे आपको मान न होगा ! लेकिन यदि
                आप गेम को जान भुजकर Auto Exit में छोड़ देते है तो आपको Loss कर
                दिया जायेगा
              </div>
            </div>
            <div className="mb-3 shadow card">
              <div className="bg-light text-dark text-capitalize card-header">
                room code
              </div>
              <div className="card-body">
                {userIs === "player" && challenge.roomCode === "" ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      role="status"
                      className="me-2 spinner-border"
                      style={{ marginLeft: "5px" }}
                    ></div>
                    <span className="text-capitalize">
                      waiting for roomCode
                    </span>
                  </div>
                ) : (
                  <h1 className="py-3 fw-bold">{challenge.roomCode}</h1>
                )}
                {userIs === "creator" && challenge.roomCode === "" && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input
                      onChange={handleChange}
                      value={room}
                      className="form-control"
                      min={50}
                      max={10000}
                      placeholder="Paste Room Code"
                      style={{ marginRight: "6px" }}
                    />

                    <button
                      className="btn btn-success text-capitalize btn-sm align-self-stretch "
                      onClick={handleSubmit}
                      disabled={room.length !== 8}
                      style={{ width: "75px" }}
                    >
                      send
                    </button>
                  </div>
                )}
                <div className="d-grid" style={{ marginTop: "8px" }}>
                  <CopyToClipboard text={challenge.roomCode} onCopy={showToast}>
                    <button
                      disabled={!challenge.roomCode}
                      className="btn btn-primary text-capitalize d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <BsClipboard className="me-1" color="white" />
                      copy code
                    </button>
                  </CopyToClipboard>

                  <button
                    style={{ marginTop: "5px" }}
                    className="btn btn-secondary text-capitalize d-flex align-items-center justify-content-center"
                    onClick={handleRedirect}
                  >
                    <img
                      style={{ width: "1.4rem", marginRight: "5px" }}
                      src={
                        "https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/ludoking.jpg"
                      }
                      alt="ludo king"
                    />
                    Open Ludo King
                  </button>
                  {/* <button
                    style={{ marginTop: "5px" }}
                    className="btn btn-secondary text-capitalize d-flex align-items-center justify-content-center"
                    onClick={gameRedirect}
                  >
                    Open Ludo Game
                  </button> */}
                </div>
              </div>
            </div>
            <div className="mb-3 shadow card">
              <div className="bg-light text-dark text-capitalize card-header">
                Game Result
              </div>
              <div className="card-body">
                <p>
                  After completion of your game, select the status of the game
                  and post your screenshot below
                </p>
                <div className="d-flex flex-column align-content-stretch">
                  <button
                    disabled={!challenge.roomCode}
                    className="btn btn-success btn-lg text-uppercase mb-3"
                    onClick={() => {
                      setWonModal(true);
                      setImage({ image: "", Url: "" });
                    }}
                  >
                    i won
                  </button>
                  <button
                    disabled={!challenge.roomCode}
                    className="btn btn-danger btn-lg text-uppercase mb-3"
                    onClick={() => {
                      setLostModal(true);
                    }}
                  >
                    i lost
                  </button>

                  {userIs === "player" ? (
                    <button
                      className="btn btn-outline-dark btn-lg text-uppercase"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={() => {
                        setCancellationModal(!canceLLationModal);
                      }}
                      disabled={!userIs || enabled !== "over"}
                    >
                      cancel{" "}
                      {timer !== "over" && challenge.roomCode === "" && (
                        <CountDown setEnable={setEnable} enabled={enabled} />
                      )}
                    </button>
                  ) : userIs === "creator" ? (
                    <button
                      className="btn btn-outline-dark btn-lg text-uppercase"
                      onClick={() => {
                        setCancellationModal(!canceLLationModal);
                      }}
                    >
                      cancel
                    </button>
                  ) : (
                    <button className="btn btn-outline-dark btn-lg text-uppercase">
                      cancel
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
            <div className="mb-3 shadow card">
              <div className="bg-light text-dark text-capitalize card-header">
                Penalty
              </div>
              <div className="card-body">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>₹100</td>
                      <td>Fraud / Fake Screenshot</td>
                    </tr>
                    <tr>
                      <td>₹50</td>
                      <td>Wrong Update</td>
                    </tr>
                    <tr>
                      <td>₹50</td>
                      <td>No Update</td>
                    </tr>
                    <tr>
                      <td>₹25</td>
                      <td>Abusing</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <LostModal
            handleLooseChallenge={handleLooseChallenge}
            handleClose={handleLostModalClose}
            isIlostClicked={isIlostClicked}
            lostModal={lostModal}
            challenge={challenge}
          />

          <WinModal
            handleClose={handleWonModalClose}
            handleImageChange={handleImageChange}
            handleWinChallenge={handleWinChallenge}
            wonModal={wonModal}
            postResultLoading={postResultLoading}
            image={image}
          />
          <CancellationModal
            handleCancellationModalClose={handleCancellationModalClose}
            handleCancelChallenge={handleCancelChallenge}
            handleCancellationReason={handleCancellationReason}
            canceLLationModal={canceLLationModal}
            cancellationReason={cancellationReason}
            disableCancelButton={disableCancelButton}
            handleImageChange={handleImageChange}
            image={image}
          />
        </div>
      )}
    </AppLayout>
  );
}
