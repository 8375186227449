import React, { useState } from "react";
import AppLayout from "../../../layout/AppLayout";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import "./styles.css";
import { data } from "./data";
import BuyDiamondsModel from "../../atoms/buyDiamondsModel";
const PurchaseDiamonds = () => {
  const navigate = useNavigate();
  const [is_open, setIsOpen] = useState(false);
  console.log(is_open);

  const [itemData, setData] = useState("");
  const handleOpen = (item) => {
    setIsOpen(true);
    setData(item);
  };
  return (
    <AppLayout>
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <Link to="/">
              <button className="btn btn-primary border">
                <BsArrowLeftShort className="me-2" />
                Back
              </button>
            </Link>
          </div>
          <Link
            to="/history"
            className="text-capitalize btn btn-outline-primary"
          >
            Diamond History
          </Link>
        </div>
        {data?.map((item) => (
          <div className="row" key={item?.id}>
            <div className="col">
              <div className="buy-card">
                <div className="buy-desc">
                  <span className="title">
                    <strong className="amount">{item?.qtv}</strong>
                    <small> Diamonds</small>
                  </span>
                  <span>
                    <small>for </small>
                    <strong>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        fill="green"
                        className="mx-1"
                      >
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
                      </svg>
                      {item?.price}
                    </strong>
                  </span>
                </div>
                <div className="buy-image-wrapper">
                  <img
                    className="diamond-image"
                    src={item?.image}
                    alt="Diamonds 1050"
                  />
                  {item?.discount && (
                    <div
                      className="buy-discount"
                      style={{ width: "67px", height: "66px" }}
                    >
                      <svg
                        width="67"
                        height="66"
                        viewBox="0 0 51 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.4624 0L31.3842 9.19214L41.8293 5.95706L40.4571 16.8052L50.5379 21.0409L42.5137 28.469L47.5134 38.1936L36.5919 38.726L34.171 49.3892L25.4624 42.7768L16.7537 49.3892L14.3329 38.726L3.41131 38.1936L8.411 28.469L0.386831 21.0409L10.4676 16.8052L9.09547 5.95706L19.5405 9.19214L25.4624 0Z"
                          fill="url(#paint0_linear_56_743)"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_56_743"
                            x1="25.4624"
                            y1="0"
                            x2="25.4624"
                            y2="50.9247"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#AFA11D"></stop>
                            <stop offset="0.481573" stop-color="#E8D63B"></stop>
                            <stop offset="0.605288" stop-color="#C6B62A"></stop>
                            <stop offset="1" stop-color="#A1AC1C"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -40%)",
                          fontSize: "14px",
                          color: "rgb(88, 69, 19)",
                          fontWeight: "bold",
                          maxWidth: "80%",
                          whiteSpace: "break-spaces",
                          lineHeight: 1.1,
                        }}
                      >
                        {item?.discount} OFF
                      </span>
                    </div>
                  )}
                </div>
                <button onClick={() => handleOpen(item)} className="buy-btn">
                  Buy Now!
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ width: "30%" }}>
        <BuyDiamondsModel
          is_open={is_open}
          setIsOpen={setIsOpen}
          data={itemData}
          title="Confirm Your Purchase"
        ></BuyDiamondsModel>
      </div>
    </AppLayout>
  );
};

export default PurchaseDiamonds;
