import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { CDN_URL } from "../../../config";

import AppLayout from "../layout/AppLayout";
import Closed from "./wallet/Closed";
import { getBankAccounts, getUpiBankAccounts } from "../../../apis/wallet";

import Accordion from "react-bootstrap/Accordion";
import UpiSell from "./wallet/UpiSell";
import SellConfirm from "../components/appbar/components/SellConfirm";
import KycAlert from "../components/appbar/components/KycAlert";
import BankInfo from "./wallet/BankInfo";
import ComingSoonBadge from "../components/atoms/ComingSoonBadge";
import UpiInfo from "./wallet/UpiInfo";
import { automaticLogoutRequest } from "../../../redux/actions/auth";

export default function Sell() {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.wallet);
  const { data: userData } = useSelector((state) => state.user);
  const { data: banks } = useSelector((state) => state.allBanksReducer);
  const { data: Upibanks } = useSelector((state) => state.allUpiBanksReducer);
  const navigate = useNavigate();
  const initialStateForBank = {
    accountNumber: "",
    confirmBankAccount: "",
    IFSCCode: "",
    amount: "",
  };

  const initialState = {
    accountNumber: "",
    confirmBankAccount: "",
    amount: "",
  };
  const [state, setState] = useState(initialState);
  const [bankInfo, setBanInfoState] = useState(initialStateForBank);
  const [is_open, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [addNewBank, setAddNewBank] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const upiValidation = () => {
    if (data.winningCash < state.amount) {
      return toast.error("total winning amount less");
    }
    const regexPattern = /@/;

    if (!bankInfo.accountNumber || bankInfo.accountNumber.trim() === "") {
      toast.error("UPI ID is required");
    } else if (!regexPattern.test(bankInfo.accountNumber)) {
      toast.error("UPI ID is not correct");
    } else if (bankInfo.accountNumber !== bankInfo.confirmBankAccount) {
      toast.error("Please enter the same UPI ID");
    } else if (data?.winningCash < state.amount) {
      toast.error("Insufficient funds in wallet");
    } else if (!userData?.kycVerified) {
      toast.error("Complete Your Kyc");
    } else {
      if (bankInfo.amount < 195) {
        toast.error("Amount should be greater than 195");
      } else if (bankInfo.amount > 100000) {
        toast.error("Amount should be less than 100000");
      } else {
        setIsOpen(true);
      }
    }
  };

  return (
    <AppLayout>
      <SellConfirm
        is_open={is_open}
        setIsOpen={setIsOpen}
        state={state}
        setState={setState}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        bankInfo={bankInfo}
        setBanInfoState={setBanInfoState}
        setAddNewBank={setAddNewBank}
        setActiveIndex={setActiveIndex}
        initialStateForBank={initialStateForBank}
        initialState={initialState}
      />
      {!userData?.kycVerified && <KycAlert variant={"fulfilled"} />}

      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <button
              className="btn btn-primary border"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort className="me-2" />
              Back
            </button>
          </div>
          <button
            type="button"
            className="btn btn-outline-primary d-flex align-items-center justify-content-center"
          >
            <img
              style={{ marginRight: "4px" }}
              src={`${CDN_URL}svgs/info.svg`}
              alt="info"
            />
            <p className="m-0 p-0">Guide</p>
          </button>
        </div>
        <div className="mb-3 shadow card">
          <div className="bg-light text-dark text-capitalize card-header">
            Select Payment Mode
          </div>
          <div className=" bg-secondary py-1">
            <span className="text-white" style={{ fontSize: "0. 8rem" }}>
              Withdrawal Chips: <span>{data?.winningCash}</span>
            </span>
          </div>
          <div className="card-body">
            <div className="d-flex flex-row align-items-center justify-content-between mb-1">
              <span style={{ fontSize: "0.8rem" }}>Minimum: 195</span>
              <span style={{ fontSize: "0.8rem" }}>Maximum: 1,00,000</span>
            </div>
          </div>
        </div>

        <>
          <Accordion>
            {(activeTab === 0 || activeTab === 1) && (
              <Accordion.Item className="pt-4 pb-2 px-2 pb-3 border-bottom-0 text-start position-relative">
                {/* bank payment method button */}
                {activeTab === 0 && !addNewBank && (
                  <button
                    onClick={async () => {
                      setActiveTab(1);
                      setAddNewBank(true);
                      const banks = await getBankAccounts();

                      if (banks?.data?.status === 200) {
                        dispatch({
                          type: "GET_BANKS",
                          payload: banks.data.data,
                        });
                      }
                    }}
                    type="button"
                    className={
                      "d-flex align-items-center justify-content-center border w-100 py-2 fs-6 text-uppercase rounded-3 bg-transparent accordion-custom-button"
                    }
                  >
                    <img
                      src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/bankG.webp"
                      alt="Bank"
                      height={"68"}
                    />
                    <p
                      className="ms-3 mb-0 mt-3 fw-bold d-flex align-items-start fs-4"
                      style={{ color: "#6e6e6c" }}
                    >
                      BANK / IMPS{" "}
                      <span className="fs-7" style={{ fontSize: ".6rem" }}>
                        (instant)
                      </span>
                    </p>
                  </button>
                )}

                {addNewBank && (
                  <BankInfo
                    banks={banks}
                    bankInfo={bankInfo}
                    activeTab={activeTab}
                    setBanInfoState={setBanInfoState}
                    setActiveTab={setActiveTab}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    addNewBank={addNewBank}
                    setAddNewBank={setAddNewBank}
                    setIsOpen={setIsOpen}
                  />
                )}
              </Accordion.Item>
            )}

            <Accordion.Item className="pt-0 pb-4 px-2 text-start">
              {activeTab === 0 && !addNewBank && (
                <>
                  <button
                    onClick={async () => {
                      setActiveTab(2);
                      const upiBanks = await getUpiBankAccounts();

                      if (upiBanks?.status === 200) {
                        dispatch({
                          type: "GET_UPI_BANKS",
                          payload: upiBanks.data.data,
                        });
                      } else if (upiBanks?.status === 401) {
                        dispatch(automaticLogoutRequest());
                      }
                    }}
                    type="button"
                    className={
                      "d-flex align-items-center justify-content-center border w-100 py-2 fs-6 text-uppercase rounded-3 bg-transparent accordion-custom-button position-relative"
                    }
                  >
                    <div>
                      <img
                        src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/upi.webp"
                        alt="upi logo"
                        width={"170"}
                        height={"68"}
                      />
                      {/* <div className="hstack gap-4 minBreakpoint-xs mt-2">
                          <img
                            src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/gPay.webp"
                            alt="gpay logo"
                            width={"45"}
                            height={"15"}
                          />
                          <img
                            src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/paytm.webp"
                            alt="paytm logo"
                            width={"45"}
                          />
                          <img
                          src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/phonepe.webp"
                            alt="phone logo"
                            width={"60"}
                          />
                        </div> */}
                    </div>
                  </button>
                </>
              )}
              {activeTab === 2 && (
                <UpiInfo
                  setState={setState}
                  state={state}
                  upiValidation={upiValidation}
                  banks={Upibanks}
                  bankInfo={bankInfo}
                  activeTab={activeTab}
                  setBanInfoState={setBanInfoState}
                  setActiveTab={setActiveTab}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  addNewBank={addNewBank}
                  setAddNewBank={setAddNewBank}
                  setIsOpen={setIsOpen}
                />
              )}
            </Accordion.Item>
          </Accordion>
        </>
      </div>
    </AppLayout>
  );
}
