import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BuyChipsLoader = ({ status, setBuyChipsLoader, buy }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        window.location.href = "/buy";
      }, 4000);
    }
  }, [status]);

  const handleCancelPayment = () => {
    navigate("/wallet");
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-between position-absolute left-0 w-100"
      style={{
        zIndex: "1000",
        height: "100vh",
        top: "0",
        backgroundColor: buy ? "white" : "#ffffffe6",
        padding: "20px",
      }}
    >
      <div className="d-flex flex-column justify-content-center flex-grow-1">
        {status === "isLoading" ? (
          <div className="custom-loader">
            <svg
              className="circle-loader__svg m-auto"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <circle
                className="circle-loader__base"
                cx="24"
                cy="24"
                r="19"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <circle
                className="circle-loader__fill"
                cx="24"
                cy="24"
                r="19"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
            </svg>
          </div>
        ) : status === "success" ? (
          <div className="swal-icon swal-success swal-icon-show d-flex position-relative justify-content-center">
            <span className="swal-success-line-tip"></span>
            <span className="swal-success-line-long"></span>
            <div className="swal-success-ring"></div>
          </div>
        ) : (
          <div className="swal-icon swal-failed swal-icon-show d-flex position-relative justify-content-center">
            <span className="swal-failed-text">!</span>
          </div>
        )}

        <div className="text text-center">
          <h6 className="m-0" style={{ padding: "27px" }}>
            Open your UPI app to approve the payment request before the timer
            runs out
          </h6>
        </div>
      </div>

      {/* Footer with the note */}
      <footer
        className="text-center"
        style={{
          width: "100%",
          fontSize: "12px",
          color: "#555",
          marginBottom: "100px",
        }}
      >
        <p className="mb-0">
          <strong>Note</strong>: Do not hit the back button or close this screen
          until the transaction is complete.
        </p>
        <div
          onClick={handleCancelPayment}
          style={{
            color: "#fe7a09",
            fontWeight: "bold",
            cursor: "pointer",
            marginTop: "10px",
            fontSize: "16px",
          }}
        >
          Cancel Payment
        </div>
      </footer>
    </div>
  );
};

export default BuyChipsLoader;
