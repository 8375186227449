import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CircularLoading from "../../components/atoms/CircularLoading";
import { kycOtpApi } from "../../../../apis/user";
import { useDispatch } from "react-redux";
import { getUserProfileReq } from "../../../../redux/actions/user";
import { toast } from "react-toastify";

const KycOtp = () => {
  const dispatch = useDispatch();
  const [otpNumber, setOtpNumber] = useState(null); // Initialize with an empty string
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!otpNumber || otpNumber.length < 3) {
      return toast.error("OTP is required");
    }
    setLoading(true);
    const response = await kycOtpApi({ kycOtp: otpNumber });
    if (response.status === 200) {
      dispatch(getUserProfileReq());
      toast.success("KYC Completed!");
      window.location.href = "/profile";
    } else if (response.status === 400) {
      toast.error(response.error);
    }
    setOtpNumber(null);
    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setOtpNumber(e.target.value); // Update the state with the input value
  };

  return (
    <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          <b>KYC</b>
        </div>
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 text-center"
              controlId="formBasicANumber"
            >
              <Form.Label>
                Enter OTP Sent to phone number registered in aadhaar
              </Form.Label>
              <Form.Control
                type="text" // Change the type to "text"
                placeholder="Enter OTP"
                value={otpNumber}
                onChange={handleOtpChange} // Use the controlled input handler
              />
            </Form.Group>

            <div className="d-grid py-4">
              <Button variant="primary" type="submit">
                {loading ? (
                  <CircularLoading
                    height={"1.5rem"}
                    width={"1.5rem"}
                    color={"white"}
                  />
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default KycOtp;
