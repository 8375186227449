import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../redux/contstants";
const LudoNames = ({ imageUrl, imgWidth, id }) => {
  console.log(id);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: DASHBOARD.GET_LUDONAME_REQUEST, id: id });
  }, [id]);
  const { data } = useSelector((s) => s?.LudoName);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img
        src={"https://ludo3.s3.ap-south-1.amazonaws.com/eye.webp"}
        alt="Img"
        style={{ cursor: "pointer" }}
        onClick={handleOpen}
        width={"10%"}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>LudoKing Names</DialogTitle>
        <DialogContent>
          {data && data?.names?.length > 0 ? (
            data?.names?.map((name, index) => <div key={index}>{name}</div>)
          ) : (
            <div>Not Found</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LudoNames;
