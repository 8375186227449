import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Divider,
} from "@mui/material";
const PlayerInfo = ({ data }) => {
  const { user, gamesPlayed, accountDetails } = data;
  function createData(name, calories, fat) {
    return { name, calories, fat };
  }

  const playerInfo = {
    refer: {
      chips: data.accountDetails.referelBalance
        ? data?.accountDetails.referelBalance
        : "N/A",
      total: data.user.totalRefer,
    },
    gamesPlay: { chips: accountDetails.totalWin, total: gamesPlayed },
    chipsWon: { chips: data?.accountDetails.totalWin, total: "N/A" },
    chipsLose: { chips: data?.accountDetails.totalLose, total: gamesPlayed },
    deposits: {
      chips: data.accountDetails.depositCash,
      total: data.accountDetails.depositCash,
    },
    // gamesPlay: { amount: data?.accountDetails.totalWin, total: gamesPlayed },
  };

  const rows = [
    createData("Refer", playerInfo?.refer.chips, playerInfo?.refer.total),
    createData(
      "Games Play",
      playerInfo.gamesPlay.totalWin,
      playerInfo.gamesPlay.total
    ),
    createData(
      "Chips Won",
      playerInfo.gamesPlay.chips,
      playerInfo.gamesPlay.total
    ),
    createData(
      "Chips Lose",
      playerInfo.chipsLose.chips,
      playerInfo.chipsLose.total
    ),
    createData(
      "Deposits",
      playerInfo.deposits.chips,
      playerInfo.deposits.total
    ),
    createData(
      "Withdrawals",
      playerInfo?.gamesPlay?.chips,
      playerInfo?.gamesPlay?.total
    ),
  ];

  return (
    <>
      <Card>
        <CardContent>
          <Typography fontWeight={600} marginBottom="10px">
            All Time Player Info
          </Typography>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Info</TableCell>
                <TableCell>Chips</TableCell>
                <TableCell>Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.calories}</TableCell>
                  <TableCell>{row.fat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default PlayerInfo;
