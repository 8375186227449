import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";
import Scrollbar from "../../../components/sharedComponents/scrollbar";
import {
  useGetTransactionsQuery,
  useSearchHoldGamesQuery,
} from "../../../state/api";
import Iconify from "../../../components/sharedComponents/iconify";
import { Header, SearchBox } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD } from "../../../../redux/contstants";

// ----------------------------------------------------------------------

export default function HoldGames() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading2, setLoading2] = useState(true);

  const [search, setSearch] = useState("");

  const [result, setResult] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState(null);

  // const { data, isLoading, refetch } = useGetTransactionsQuery(page);
  // const {
  //   data: searchData,
  //   isLoading: isSearchLoading,
  //   refetch: searchRefetch,
  // } = useSearchHoldGamesQuery(search);

  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the action to trigger the getUser saga
    dispatch({ type: DASHBOARD.GET_HOLD_GAMES_REQUEST, payload: { page } });
  }, [dispatch, page]);
  const holdGames = useSelector((state) => state?.dashboardSlice);
  console.log(holdGames?.holdGame);

  useEffect(() => {
    setLoading2(holdGames?.isLoading);
    if (holdGames?.holdGame) {
      setResult(holdGames?.holdGame?.challenges);
    } else {
      setResult(holdGames?.holdGame?.challenges);
    }
  }, [holdGames]);
  console.log(result);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setSearch(searchTerm);
        searchRefetch({ search: searchTerm });
      }, 800)
    );
  };
  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: result || [],
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !dataFiltered?.length && !!filterName;

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={10}
        sx={{ height: "50px" }}
      >
        <Header
          refetch={() => setRefetch(!refetch)}
          title="Hold Games"
          isLoading={holdGames?.isLoading}
        />
        <UserTableToolbar
          numSelected={selected?.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Search room code"
        />
      </Stack>

      <Scrollbar>
        <TableContainer sx={{ overflow: "unset", height: "500px" }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={result?.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={[
                { id: "creatorName", label: "Creator Username" },
                { id: "playerName", label: "Player Username" },
                { id: "amount", label: "Amount" },
                { id: "creatorResult", label: "Creator Result" },
                { id: "playerResult", label: "Player Result" },
                { id: "tRoomCode", label: "Transaction Room Code" },
                { id: "tMode", label: "Transaction Mode" },
                { id: "" },
              ]}
            />
            <TableBody>
              {dataFiltered
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <UserTableRow
                    key={row?._id}
                    creatorName={row?.creator?.username}
                    creatorResult={row?.results?.creator?.result}
                    transaction={row}
                    amount={row?.amount}
                    state={row?.state}
                    playerName={row?.player?.username}
                    playerResult={row?.results?.player?.result}
                    tMode={row?.mode}
                    tRoomCode={row?.roomCode}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                  />
                ))}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, result?.length)}
              />

              {notFound && <TableNoData query={filterName} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        page={page}
        component="div"
        count={!result?.length ? 0 : result?.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
