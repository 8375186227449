import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CDN_URL } from "../../../../../config";
import { BsWalletFill } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { getWalletReq } from "../../../../../redux/actions/wallet";
import { getUserProfileReq } from "../../../../../redux/actions/user";
import {
  automaticLogoutRequest,
  logoutSuccess,
} from "../../../../../redux/actions/auth";

import { toast } from "react-toastify";
import Offcanvas from "react-bootstrap/Offcanvas";
import Cookies from "js-cookie";
import { getPermission } from "../../../../../firebase";

function Guide({ socket, wallet }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [f_open, setOpen] = useState(false);
  const { data } = useSelector((state) => state.loginReducer);

  const { data: userData } = useSelector((state) => state.user);

  const userId = Cookies.get("userId");
  useEffect(() => {
    if (!Cookies.get("tokenSent") && Cookies.get("userId")) {
      getPermission(Cookies.get("userId"));
    }

    if (!userId || userData?.isBlocked) {
      dispatch(logoutSuccess());
      navigate("/login");
      return;
    }
    if (userId && socket) {
      socket.on("logout", (message) => {
        socket.disconnect();
        dispatch(automaticLogoutRequest());
        toast.success("Logged out successfully");
      });
    }
  }, [socket, userId]);
  useEffect(() => {
    if (!userData?._id) {
      if (data.isLoggedIn && Cookies.get("token")) {
        let route = window.location.pathname;
        if (route === "/login" || route === "/register") {
          window.location.href = "/";
          return null;
        }
        dispatch(getWalletReq());
      } else {
        let route = window.location.pathname;
        if (route !== "/login" && route !== "/register" && route !== "/") {
          window.location.href = "/login";
          return null;
        }
      }
    }
  }, [data.isLoggedIn]);

  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="partials">
        <Offcanvas
          className={"h-50"}
          show={f_open}
          onHide={handleClose}
          placement="bottom"
        >
          {/* Your drawer content */}
        </Offcanvas>
      </div>
      {data.isLoggedIn ? (
        <Link className="text-decoration-none text-white " to="/wallet">
          <div className="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
            <BsWalletFill className="me-2" color="green" />
            {wallet?.wallet ? (
              <strong>
                {wallet?.wallet?.toFixed(wallet?.wallet % 1 !== 0 ? 2 : 0)}
              </strong>
            ) : (
              <strong>0</strong>
            )}
          </div>
        </Link>
      ) : (
        <button
          onClick={() => setOpen(true)}
          type="button"
          className="btn btn-outline-primary d-flex align-items-center justify-content-center"
        >
          <img
            style={{ marginRight: "4px" }}
            src={`${CDN_URL}svgs/info.svg`}
            alt="info"
          />
          <p className="m-0 p-0">Guide</p>
        </button>
      )}
    </>
  );
}

export { Guide };
