import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import {
  Avatar,
  Badge,
  Menu,
  MenuItem,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";

import ReplayIcon from "@mui/icons-material/Replay";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { styled } from "@mui/material/styles";

import profileImage from "../../../public/assets/profile.jpeg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "inherit",
    },
  }),
}));

export default function AppBarComponent({ open, toggleDrawer }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    navigate(`/admin/dashboard`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      open={open}
      sx={{
        backgroundColor: "#212529",
        color: "#ffff",
        margin: 0,
        padding: 0,
      }}
    >
      <Toolbar sx={{ pr: "24px" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box ml={"auto"} display={"flex"}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon sx={{ fontSize: "30px" }} />
            </Badge>
          </IconButton>
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="#ffff"
            >
              <Avatar src={profileImage} sx={{ width: 36, height: 36 }} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
