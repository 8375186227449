import { toast } from "react-toastify";
// Import the redux-saga/effects
import { put, takeLatest } from "redux-saga/effects";
import { WALLET } from "../contstants";

import {
  converToDepositError,
  converToDepositLoading,
  converToDepositSuccess,
  updateWalletError,
  updateWalletLoading,
  updateWalletSuccess,
  userBuyChipsLoading,
  userSellChipsLoading,
  userSellChipsSuccess,
} from "../actions/wallet";
import { buyWalletApi, getWalletApi, sellWalletApi } from "../../apis/wallet";

// Sign up

function* updateWallet(param) {
  yield put(updateWalletLoading(true));
  const data = yield getWalletApi(param);
  if (data.status == 200) {
    // toast.success("Wallet updated");

    yield put(updateWalletSuccess(data.data));
  } else if (data.status == 400) {
    toast.error(data.error);
    yield put(updateWalletError(data.error));
  } else {
    yield put(updateWalletError(data.error));
    toast.error(data.error);
  }
}
function* converToDeposit(param) {
  yield put(converToDepositLoading(true));
  const data = yield getWalletApi(param);
  if (data.status == 200) {
    // toast.success("Wallet updated");

    yield put(converToDepositSuccess(data.data));
  } else if (data.status == 400) {
    toast.error(data.error);
    yield put(converToDepositError(data.error));
  } else {
    yield put(converToDepositError(data.error));
    toast.error(data.error);
  }
}

function* userBuySaga(param) {
  yield put(userBuyChipsLoading(true));
  const data = yield buyWalletApi(param);

  if (data.status == 200) {
    if (process.env.NODE_ENV === "production") {
      const Url = data.data.data;
      window.location.href = Url.Url;
    } else {
      toast.success("Chips added successfully");
    }
  } else if (data.status == 400) {
    toast.error("request failed");
  }
  yield put(userBuyChipsLoading(false));
}

function* userSellSaga(param) {
  yield put(userSellChipsLoading(true));
  const data = yield sellWalletApi(param);
  if (data.status == 200) {
    toast.success("Withdraw successfull");

    yield put(userSellChipsSuccess(data.data));
  } else if (data.status == 400) {
    toast.error(data.error);
  }
  yield put(userSellChipsLoading(false));
}

export default function* userSaga() {
  yield takeLatest(WALLET.UPDATE_WALLET_REQUEST, updateWallet);
  yield takeLatest(WALLET.USER_BUY_CHIPS_REQUEST, userBuySaga);
  yield takeLatest(WALLET.USER_SELL_CHIPS_REQUEST, userSellSaga);
  yield takeLatest(WALLET.CONVERT_TO_DEPOSIT_REQUEST, converToDeposit);
}
