import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function WinPopup({ winValue, show, setShow, modelClosed }) {
 
  const handleClose = () => {
    setShow(false);
    modelClosed();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Congratulation! You won: ₹{winValue}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WinPopup;
