import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChallengeList from "./play/ChallengeList";
import "../styles/animation.css";
import ViewChallenge from "./play/ViewChallenge";
import Cookies from "js-cookie";
import startGameAudio from "../styles/notification.mp3";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  sortEvents,
  filterEvents,
  isAvailabletoPlayGame,
  createChallenge,
} from "../functions/functions";
import creatorAudio from "../styles/creator.mp3";
import playerAudio from "../styles/player.mp3";
import { useSelector } from "react-redux";
import AppLayout from "../layout/AppLayout";
const Play = ({ mode, socket }) => {
  const { data: userData } = useSelector((state) => state?.user);
  console.log(mode);
  const userId = Cookies.get("userId");
  const username = Cookies.get("fullName");
  const navigate = useNavigate();

  const [challenges, setChallenges] = useState([]);
  const [deleteAble, setDeleteAble] = useState(0);
  const [amount, setAmount] = useState("");

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  const { data } = useSelector((state) => state.wallet);
  const [holdChallenge, setHoldChallenge] = useState({});
  const [holdModal, setHoldModal] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleGetChallenges = useCallback((event) => {
    const events = JSON.parse(event);

    if (events.sort) {
      sortEvents(events, userId);
    }
    if (events.filter) {
      const { allChallenges } = filterEvents(mode, events, userId, viewGame);

      setChallenges(allChallenges);
    }
  });

  const handleStream = useCallback((data) => {
    const SingleChallenge = JSON.parse(data);

    if (
      (SingleChallenge.player?._id === userId ||
        SingleChallenge.creator?._id === userId) &&
      SingleChallenge.mode === mode &&
      (SingleChallenge.state === "open" ||
        SingleChallenge.state === "requested")
    ) {
      if (SingleChallenge.state === "requested") {
        new Audio(startGameAudio).play();
      }
      setDeleteAble(1);
    }

    setChallenges((prevChallenges) => {
      const index = prevChallenges.findIndex(
        (challenge) => challenge._id === SingleChallenge._id
      );
      if (index !== -1) {
        const updatedChallenges = [...prevChallenges];
        updatedChallenges[index] = SingleChallenge;
        return updatedChallenges;
      } else {
        return [...prevChallenges, SingleChallenge];
      }
    });
  }, []);

  const handleDelete = useCallback((deletedChallengeId) => {
    setChallenges((prevChallenges) =>
      prevChallenges.filter((challenge) => challenge._id !== deletedChallengeId)
    );
  }, []);

  const challengeButton = useCallback(
    (challenge, type) => {
      if (!isAvailabletoPlayGame(data.wallet, challenge.amount)) {
        toast.error("Not enough chips");
        return;
      }

      socket.send(
        JSON.stringify({
          type: type,
          payload: {
            challengeId: challenge._id,
            userId,
            amount: challenge.amount,
            username: userData.username,
            profileImage: userData.profileImage,
          },
        })
      );
    },
    [data.wallet, socket, userId]
  );

  useEffect(() => {
    if (socket) {
      console.log("cehckkksocket.", socket);

      socket.send(
        JSON.stringify({
          type: "getChallenges",
          payload: { userId },
        })
      );
      const handleVisibilityChange = () => {
        setIsTabVisible(!document.hidden);
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      socket.on("message", (event) => {
        const events = JSON.parse(event);
        if (events.type === "heartbeat") {
          socket.send(JSON.stringify({ type: "ack" }));
        }
        if (events.challengeRedirect) {
          if (events.user === "creator" && events.mode === "CLASSIC") {
            new Audio(creatorAudio).play();
          }
          if (events.user === "player" && events.mode === "CLASSIC") {
            new Audio(playerAudio).play();
          }
          localStorage.removeItem("end_date");
          navigate(`/game/${events.challengeId}`);
          return;
        }
        if (events.status === 400) {
          toast.error(events.error);
          return;
        }
      });

      socket.on("getChallenges", handleGetChallenges);
      socket.on("stream", handleStream);
      socket.on("deleteChallenge", handleDelete);
      socket.on("error", (events) => {
        toast.error(events.error);
      });
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        socket.off("message");
        socket.off("getChallenges");
        socket.off("stream");
        socket.off("deleteChallenge");
        socket.off("error");

        socket.send(
          JSON.stringify({
            type: "deleteOpenChallengesOfCreator",
            payload: { userId },
          })
        );
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket && !isTabVisible && deleteAble > 0) {
      socket.send(
        JSON.stringify({
          type: "deleteOpenChallengesOfCreator",
          payload: { userId },
        })
      );
    }
  }, [isTabVisible, deleteAble]);

  const viewGame = (challengeId) => {
    navigate(`/game/${challengeId}`);
  };
  const viewHold = useCallback(
    (challenge) => {
      setHoldChallenge(challenge);

      const isCreator = challenge.creator._id === userId;
      const isPlayer = challenge.player?._id === userId;
      const creatorResultEmpty = !challenge.results.creator?.result;
      const playerResultEmpty = !challenge.results.player?.result;

      if (
        (isCreator && creatorResultEmpty) ||
        (isPlayer && playerResultEmpty)
      ) {
        viewGame(challenge._id);
      } else {
        setHoldModal(true);
      }
    },
    [userId, viewGame]
  );

  return (
    <AppLayout>
      <div
        className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0"
        style={{ padding: "1rem", important: "true" }}
      >
        <div className="d-flex flex-column">
          <div className="bg-gray-200 h-100 w-100 p-3 bg-light d-flex align-items-center justify-content-between hstack gap-2 ">
            <div className="input-group flex-1 flex-nowrap">
              <input
                type="number"
                onChange={handleChange}
                value={amount}
                className="form-control"
                min={50}
                max={10000}
                placeholder="Amount"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "auto",
                }}
              />
              <button
                onClick={() => {
                  createChallenge({
                    amount,

                    setAmount,

                    data,
                    mode,
                    socket,
                    userId,
                    username,
                  });
                }}
                className="btn btn-primary w-25"
                style={{
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              >
                Set
              </button>
              <br></br>
            </div>
          </div>
          <ul style={{ listStyleType: "none" }} className="m-0 px-2">
            <div className="separator mt-3 mb-3">
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
              &nbsp;Open Battles (
              {mode.charAt(0).toUpperCase() + mode.slice(1).toLowerCase()}
              )&nbsp;
              <img
                src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/winner-cup-icon-png-19.png"
                alt="WinCupImg"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            <ChallengeList
              mode={mode}
              challenges={challenges}
              userId={userId}
              challengeButton={challengeButton}
              viewGame={viewGame}
              viewHold={viewHold}
            />
          </ul>
        </div>

        <ViewChallenge
          holdModal={holdModal}
          holdChallenge={holdChallenge}
          userId={userId}
          setHoldModal={setHoldModal}
        />
      </div>
    </AppLayout>
  );
};

export default Play;
