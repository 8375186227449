// socketReducer.js

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

export const allBanksReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BANKS":
      return {
        ...state,
        data: action.payload,
        isLoading: true,
        error: null,
      };

    default:
      return state;
  }
};
