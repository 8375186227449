import React from 'react';
import {
  Card,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Stack,
} from '@mui/material';

const AdminChips = ({ data }) => {
  return (
    <>
      <Card>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'stretch'}
          p={2}
          overflow={'auto'}
          height={{ xs: '50vh', lg: '80vh' }}
        >
          <Typography
            variant="h2"
            fontWeight={600}
            marginBottom="10px"
            position={'sticky'}
          >
            Admin Chips ({data && data.length})
          </Typography>
          <Divider />

          <Stack
            direction="column-reverse"
            style={{ margin: '0', padding: '0' }}
          >
            {data ? (
              data?.map((history, index) => (
                <Box key={index}>
                  <Box p={1} borderBottom={1} borderColor={'#0000001f'}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography variant="body1" fontWeight={600}>
                        {history.username}:
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        {history.totalAmount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={'100%'}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default AdminChips;
