import React, { useEffect, useState } from "react";
import AppLayout from "../../layout/AppLayout";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";

import KycGuide from "./KycGuide";
import KycOtp from "./KycOtp";
import KycForm from "./KycForm";

const KYC = () => {
  const navigate = useNavigate();
  const [f_open, setOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  return (
    <AppLayout>
      <KycGuide f_open={f_open} setOpen={setOpen} />

      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto px-3 pt-3 g-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <button
              className="btn btn-primary border"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort className="me-2" />
              Back
            </button>
          </div>
          <button
            onClick={() => {
              setOpen(true);
            }}
            type="button"
            className="d-flex align-items-center btn btn-outline-primary btn-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              fill="currentColor"
              className="me-1"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
            </svg>
            <span className="text-capitalize">guide</span>
          </button>
        </div>
      </div>

      {otpSent && <KycOtp />}

      {!otpSent && <KycForm setOtpSent={setOtpSent} />}
    </AppLayout>
  );
};

export default KYC;
