import "../../styles/style.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Offcanvas from "react-bootstrap/Offcanvas";
import AppLayout from "../../layout/AppLayout";

export default function BeforeLogin() {
  const navigate = useNavigate();
  const [f_open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <AppLayout>
      <div className="partials">
        <Offcanvas
          show={f_open}
          onHide={handleClose}
          placement={"bottom"}
          className={"h-50"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>How To Play Games & Earn?</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="iframe-container">
              <iframe
                src="https://www.youtube.com/embed/2IcRDUUsjBg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
        <div>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/landing.webp"
            />
            <source
              media="(min-width: 769px)"
              srcSet="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/landing.webp"
            />
            <img
              className="mw-100"
              style={{ maxHeight: "448px" }}
              src="https://gotiking-staging-s3.s3.ap-south-1.amazonaws.com/images/landing.webp"
              alt="landing"
            />
          </picture>
        </div>

        <div
          className="text-center text-muted"
          style={{ marginTop: "1rem", paddingBottom: "90px" }}
        >
          <i>
            This Game involves an element of financial risk and may be
            addictive. Please Play responsibly and at your own risk.
          </i>
        </div>
      </div>

      <div
        className="position-fixed d-flex align-items-center justify-content-center hstack gap-3 minBreakpoint-xs mx-auto mx-lg-0"
        style={{
          zIndex: "10",
          bottom: "30px",
          left: "20px",
          right: "20px",
          maxWidth: "440px",
        }}
      >
        <div className="d-grid flex-grow-1">
          <button
            onClick={() => navigate("/login")}
            className="btn btn-dark btn-lg fw-semibold py-3"
          >
            Play Now
          </button>
        </div>
        <Link
          className="bg-light border shadow rounded-circle d-flex align-items-center justify-content-center text-dark"
          to="/support"
          style={{
            height: "60px",
            width: "60px",
            zIndex: 10,
            bottom: "100px",
            right: "30px",
          }}
        >
          <img
            src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/liveChat.webp"
            height="36px"
            alt="support icon"
          />
        </Link>
      </div>
    </AppLayout>
  );
}
