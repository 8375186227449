import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
export const extractFileNameFromUrl = (imageUrl) => {
  try {
    const url = new URL(imageUrl);
    const pathArray = url.pathname.split('/');
    const fileName = pathArray[pathArray.length - 1];
    return fileName;
  } catch (error) {
    console.error('Error extracting file name:', error);
    return null;
  }
};

const ImageViewer = ({ imageUrl, imgWidth }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageName = extractFileNameFromUrl(imageUrl);
  return (
    <div>
      <img
        src={imageUrl}
        alt="Img"
        style={{ cursor: 'pointer' }}
        onClick={handleOpen}
        width={'100px'}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Image Viewer</DialogTitle>
        <DialogTitle>Image Name:{imageName}</DialogTitle>
        <DialogContent>
          <img
            src={imageUrl}
            alt="Img"
            style={{ maxWidth: '100%', height: '70vh', objectFit: 'contain' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageViewer;
