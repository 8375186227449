export const indianNames = [
  "Anubhav",
  "Sameer",
  "Rahul",
  "Sonia",
  "Rajesh",
  "Priya",
  "Amit",
  "Smita",
  "Vikram",
  "Neha",
  "Akash",
  "Manisha",
  "Arun",
  "Pooja",
  "Sandeep",
  "Deepika",
  "Avinash",
  "Renu",
  "Kunal",
  "Sarita",
  "Sanjay",
  "Shilpa",
  "Amitabh",
  "Swati",
  "Vivek",
  "Meena",
  "Suresh",
  "Ranjana",
  "Rohit",
  "Kavita",
  "Alok",
  "Anjali",
  "Ramesh",
  "Rekha",
  "Vishal",
  "Shalini",
  "Amar",
  "Geeta",
  "Vijay",
  "Sangeeta",
  "Ajay",
  "Madhuri",
  "Aarti",
  "Vinod",
  "Anita",
  "Rajendra",
  "Rina",
  "Sudhir",
  "Sushma",
  "Aruna",
  "Rajeev",
  "Jyoti",
  "Prakash",
  "Manju",
  "Sunil",
  "Anuradha",
  "Aparna",
  "Sanjeev",
  "Ritu",
  "Vishnu",
  "Suman",
  "Hari",
  "Juhi",
  "Rahul",
  "Archana",
  "Nitin",
  "Preeti",
  "Ashok",
  "Savita",
  "Kamal",
  "Sushil",
  "Mukesh",
  "Monika",
  "Shashi",
  "Neeraj",
  "Rajni",
  "Asha",
  "Amita",
  "Gaurav",
  "Arti",
  "Pradeep",
  "Deepa",
  "Rajiv",
  "Neeta",
  "Sushant",
  "Kiran",
  "Sujata",
  "Harish",
  "Uma",
  "Rajat",
  "Nisha",
  "Ashish",
  "Sweta",
  "Naveen",
  "Anshu",
  "Anil",
  "Poonam",
  "Satish",
  "Nidhi",
  "Rohini",
  "Rishi",
  "Kajal",
  "Subhash",
  "Kumari",
  "Shubham",
  "Vandana",
  "Sumit",
  "Rakhi",
  "Rajeev",
  "Mamta",
  "Yogesh",
  "Sarika",
  "Suman",
  "Naina",
  "Rajendra",
  "Rashmi",
  "Amitabh",
  "Sheetal",
  "Ravi",
  "Pallavi",
  "Rakesh",
  "Sneha",
  "Arun",
  "Jyotsna",
  "Praveen",
  "Aparajita",
  "Kunal",
  "Kusum",
  "Suresh",
  "Malti",
  "Deepak",
  "Madhu",
  "Vijay",
  "Manisha",
  "Rahul",
  "Pooja",
  "Alok",
  "Sarika",
  "Sanjay",
  "Reena",
  "Amita",
  "Savita",
  "Vishal",
  "Neelam",
  "Ajay",
  "Swati",
  "Amar",
  "Seema",
  "Ramesh",
  "Meenakshi",
  "Vivek",
  "Rajani",
  "Sudhir",
  "Sushma",
  "Aruna",
  "Mona",
  "Rajeev",
  "Nandini",
  "Aparna",
  "Kiran",
  "Sunil",
  "Poonam",
  "Hari",
  "Neetu",
  "Vishnu",
  "Priyanka",
  "Ashok",
  "Sushil",
  "Kamal",
  "Jaya",
  "Mukesh",
  "Rita",
  "Shashi",
  "Rajesh",
  "Rajni",
  "Asha",
  "Amita",
  "Gaurav",
  "Anju",
  "Pradeep",
  "Sarita",
  "Rajiv",
  "Suman",
  "Sushant",
  "Priti",
  "Harish",
  "Anamika",
  "Rajat",
  "Nidhi",
  "Ashish",
  "Sweta",
  "Naveen",
  "Sunita",
  "Anil",
  "Pallavi",
  "Satish",
  "Rekha",
  "Rohini",
  "Rishi",
  "Kajal",
  "Subhash",
  "Kumari",
  "Shubham",
  "Vandana",
  "Sumit",
  "Rakhi",
  "Rajeev",
  "Mamta",
  "Yogesh",
  "Sarika",
  "Suman",
  "Naina",
  "Rajendra",
  "Rashmi",
  "Amitabh",
  "Sheetal",
  "Ravi",
  "Pooja",
  "Rakesh",
  "Sneha",
  "Arun",
  "Jyotsna",
  "Praveen",
  "Aparajita",
  "Kunal",
  "Kusum",
  "Suresh",
  "Malti",
  "Deepak",
  "Madhu",
  "Vijay",
  "Manisha",
  "Rahul",
  "Poonam",
  "Alok",
  "Sarika",
  "Sanjay",
  "Reena",
  "Amita",
  "Savita",
  "Vishal",
  "Neelam",
  "Ajay",
  "Swati",
  "Amar",
  "Seema",
  "Ramesh",
  "Meenakshi",
  "Vivek",
  "Rajani",
  "Sudhir",
  "Sushma",
  "Aruna",
  "Mona",
  "Rajeev",
  "Nandini",
  "Aparna",
  "Kiran",
  "Sunil",
  "Poonam",
  "Hari",
  "Neetu",
  "Vishnu",
  "Priyanka",
  "Ashok",
  "Sushil",
  "Kamal",
  "Jaya",
  "sukhad",
];
