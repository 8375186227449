import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CircularLoading from "../../components/atoms/CircularLoading";
import { registerKycApi } from "../../../../apis/user";
import { toast } from "react-toastify";

const KycForm = ({ setOtpSent }) => {
  const [adharNumber, setAadharNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const handleAadharChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length <= 12) {
      setAadharNumber(inputValue);
    }
  };
  const handleSubmit = async (event) => {
    // Mark the function as async
    event.preventDefault();
    if (!adharNumber) {
      return toast.error("Adhar number is required");
    }
    if (adharNumber) {
      setLoading(true);

      const payload = { adharCardNumber: adharNumber };
      const response = await registerKycApi(payload);
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
      } else if (response.status === 400) {
        toast.error(response.error);
      } else {
        toast.error("request failed");
      }
      setAadharNumber("");
      setLoading(false);
    }
  };

  return (
    <div className="col-12 col-sm-10 col-md-7 col-lg-12 mx-auto p-3 g-0">
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          <b>KYC</b>
        </div>
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 text-start"
              controlId="formBasicANumber"
            >
              <Form.Label>Aadhar Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Aadhar Card Number"
                value={adharNumber}
                onChange={handleAadharChange}
              />
            </Form.Group>
            <p style={{ fontSize: "0.8rem" }}>
              By Continuing, you agree to our{" "}
              <Link to="/legal">Legal Terms</Link> and you are 18 years or
              older.
            </p>
            <div className="d-grid">
              <Button variant="primary" type="submit">
                {loading ? (
                  <CircularLoading
                    height={"1.5rem"}
                    width={"1.5rem"}
                    color={"white"}
                  />
                ) : (
                  "Request For KYC"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default KycForm;
