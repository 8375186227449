import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import LandingPage from "../modules/common/pages/initialPages/LandingPage";
import BeforeLogin from "../modules/common/pages/initialPages/BeforeLogin";
import UserProfile from "../modules/common/pages/UserProfile";
import Wallet from "../modules/common/pages/Wallet";
import Buy from "../modules/common/pages/Buy";
import Sell from "../modules/common/pages/Sell";
import ReferAndEarning from "../modules/common/pages/Referal";
import Game from "../modules/common/pages/Game";
import SpinNWin from "../modules/common/pages/spinwin/spinWin";
import KYC from "../modules/common/pages/kyc/KYC";
import History from "../modules/common/pages/history";
import LegalTerms from "../modules/common/pages/LegalTerms";
import Support from "../modules/common/pages/Support";
import Login from "../modules/auth/pages/Login";
import Register from "../modules/auth/pages/Register";
import OTPVerification from "../modules/auth/pages/VerifyOtp";
import Play from "../modules/common/pages/play";
import io from "socket.io-client";
import Cookies from "js-cookie";
import socketCon from "../modules/socket/socketCon";
import QuickPlay from "../modules/common/pages/quick";
import { themeSettings } from "../theme";
import AdminLogin from "../dashboard/components/login";

import Admin from "../dashboard/scenes/admin";
import Layout from "../dashboard/scenes/layout";
import Deposits from "../dashboard/scenes/Deposits";
import Settings from "../dashboard/scenes/Settings/settings.jsx";
import PendingWithdrawal from "../dashboard/scenes/pendingWithdrawal";
import SuperAdmin from "../dashboard/scenes/superAdmin";
import BankWithdrawals from "../dashboard/scenes/WithdrawalRequest";
import SuccessWithdrawal from "../dashboard/scenes/SuccessWithdrawal";
import WithdrawalRequest from "../dashboard/scenes/WithdrawalRequest";
import UserDetails from "../dashboard/scenes/customers/UserDetails";
import HoldGames from "../dashboard/scenes/HoldGames/view/holdGames";
import TotalGames from "../dashboard/scenes/TotalGames/view/totalGames";
import LudoUsers from "../dashboard/scenes/customers/view/users";
import AdminDashboard from "../dashboard/scenes/dashboard/dashboard";
import PurchaseDiamonds from "../modules/common/components/appbar/components/purchaseDiamonds.js";
import Ludo from "../modules/common/pages/LudoGame.js";
import Multilplayer from "../modules/common/pages/Multiplayer.js";

const userId = Cookies.get("userId");
const Routes = (props) => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const { data: userData } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.loginReducer);

  // Dark/Light mode
  const mode = useSelector((state) => state?.global?.mode);
  // theme setting
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuthenticated = Cookies.get("isAuthenticated");

  // Convert the string value to boolean
  const isAuth = isAuthenticated === "true";

  useEffect(() => {
    if (data.isLoggedIn) {
      const socketConn = io(process.env.REACT_APP_CLIENT_BASEURL_WS, {
        autoConnect: true, // Automatically connect on instantiation
        reconnection: true, // Enable reconnection attempts
        reconnectionDelay: 2000, // Delay between reconnections (2 seconds)
        reconnectionDelayMax: 5000, // Maximum delay between reconnections (5 seconds)
        reconnectionAttempts: 20, // Maximum number of reconnection attempts
        auth: {
          token: `${Cookies.get("token")}`,
        },
      });
      // socketCon("254524523gsfg45243524524fdgsfg")(socketConn);
      setSocket(socketConn);
      socketConn.on("connect", () => {
        socketConn.on("getUserWallet", (message) => {
          const data = JSON.parse(message);
          if (data.data !== null || data.data !== undefined) {
            dispatch({ type: "USER_BUY_CHIPS_SUCCESS", payload: data.data });
          }
        });
        socketConn.on("settings", (message) => {
          const data = JSON.parse(message);
          dispatch({ type: "ON_SETTING", payload: data.data });
        });

        socketConn.send(
          JSON.stringify({
            type: "getChallenges",
            payload: { userId },
          })
        );
        socketConn.emit(
          "getUserWallet",
          JSON.stringify({
            payload: {
              userId,
            },
          })
        );
        socketConn.emit(
          "message",
          JSON.stringify({
            type: "getSettings",
            payload: {
              userId,
            },
          })
        );
      });
      socketConn.on("disconnect", () => {
        console.log("socket connections disconnected");
      });
    } else {
      if (socket) {
        socket.disconnect();
      }
    }
  }, [data.isLoggedIn]);

  return (
    <Switch>
      {data.isLoggedIn ? (
        <>
          <Route path="/" element={<LandingPage socket={socket} />} />
          <Route path="/profile" element={<UserProfile socket={socket} />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/purchase-diamonds" element={<PurchaseDiamonds />} />
          <Route path="/buy" element={<Buy socket={socket} />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/referal" element={<ReferAndEarning />} />
          <Route path="/ludo" element={<Ludo />} />
          <Route
            path="/play"
            element={<Play socket={socket} mode={"CLASSIC"} />}
          />
          <Route
            path="/popular-game"
            element={<Play socket={socket} mode={"POPULAR"} />}
          />
          <Route path="/multiplayer" element={<Multilplayer />} />
          <Route path="/quick" element={<QuickPlay />} />
          <Route
            path="/quick-game"
            element={<Play socket={socket} mode={"QUICK"} />}
          />
          <Route path="/game/:id" element={<Game socket={socket} />} />
          <Route path="/history" element={<History />} />
          <Route path="/legal" element={<LegalTerms />} />
          <Route path="/support" element={<Support />} />
          {!userData?.kycVerified && <Route path="/kyc" element={<KYC />} />}
          <Route path="/spinWin" element={<SpinNWin />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      ) : (
        <>
          <Route index path="/" element={<BeforeLogin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/legal" element={<LegalTerms />} />
          <Route path="/support" element={<Support />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-otp" element={<OTPVerification />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}
      {!isAuth ? (
        <>
          <Route path="*" element={<Navigate to="/admin/login" replace />} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </>
      ) : (
        // Only accessible for authenticated users
        <Route element={<Layout />}>
          <Route
            path="*"
            element={<Navigate to="/admin/dashboard" replace />}
          />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/super_admin_" element={<SuperAdmin />} />
          <Route
            path="/admin/withdrawalrequests"
            element={<WithdrawalRequest />}
          />
          <Route path="/admin/bankwithdrawals" element={<BankWithdrawals />} />
          <Route path="/admin/users" element={<LudoUsers />} />
          <Route path="/admin/holdgames" element={<HoldGames />} />
          <Route path="/admin/totalgames" element={<TotalGames />} />
          <Route path="/admin/deposits" element={<Deposits />} />
          <Route
            path="/admin/successfulwithdrawls"
            element={<SuccessWithdrawal />}
          />
          <Route
            path="/admin/pendingdeposits"
            element={<PendingWithdrawal />}
          />
          <Route path="/admin/details/:id" element={<UserDetails />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/admin" element={<Admin />} />
        </Route>
      )}
    </Switch>
  );
};

export default Routes;
