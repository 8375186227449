import Cookies from "js-cookie";

import { axiosConfig } from "./axiosConfig";
export const getUserProfileApi = async () => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(`/user/getUserProfileData`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};
export const updateUserProfileApi = async (data) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.post(
      `/user/updateUserProfile`,
      data.payload,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const updateUserAvatarApi = async (data) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.post(`/user/avatar`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const registerKycApi = async (data) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.post(`/user/kycRegister`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const kycOtpApi = async (data) => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.post(`/user/kycOtp`, data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getKycDetailsApi = async () => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(`/user/kycDetails`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};
