import Cookies from "js-cookie";

import { axiosConfig } from "./axiosConfig";
export const spinWinApi = async () => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(`/spin/spin-win`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export const spinWinGameApi = async () => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(`/spin/spin-win-game`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export const spinWinApi2 = async () => {
  try {
    const token =
      Cookies.get("token") !== undefined || Cookies.get("token") !== null
        ? Cookies.get("token")
        : "";
    const res = await axiosConfig.get(`/spin/spin-win-2`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};
